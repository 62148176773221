/* eslint-disable no-unused-expressions */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styles from './SelectionsGroup.module.css';
import SelectionItem from '../SelectionItem/SelectionItem';
import SelectionGroupHeader from '../../../shared/SelectionGroupHeader/SelectionGroupHeader';
import ItemListWrapper from '../../../shared/ItemListWrapper/ItemListWrapper';
import { ClientSettingsConsumer } from '../../../context/ClientSettingsContext';

const SelectionsGroup = ({
  handleOptionItemClick,
  menuOptions,
  optionCategory,
  choiceType,
  menuHeaderText,
  blacklist,
  quickMoveIn
}) => {
  const [menuOpen, setMenuOpen] = useState(true);
  const toggleMenu = () => setMenuOpen(!menuOpen);

  const deDuplicateOptions = (opts) => {
    const store = {};
    const output = [];

    opts.forEach((opt) => {
      if (!store[opt.Text]) {
        store[opt.Text] = true;
        output.push(opt);
      }
    });

    return output;
  };

  const populateCards = (menuOpts) => {
    const deDupedMenuOpts = deDuplicateOptions(menuOpts);

    return deDupedMenuOpts.map(option => ((
      option.Text.length > 0
          && ((option.Enabled && choiceType === 'MultiChoice') || choiceType === 'SingleChoice'))
          && (!blacklist.includes(option.ID))
      ? (
        <SelectionItem
          key={`${option.ID}`}
          option={option}
          handleOptionItemClick={handleOptionItemClick}
          optionCategory={optionCategory}
          choiceType={choiceType}
          quickMoveIn={quickMoveIn}
        />
      ) : null));
  };

  return (
    <ClientSettingsConsumer>
      {({ touchscreenEnabled }) => (
        <div
          className={styles.selectionsGroup}
          style={{
            paddingLeft: `${touchscreenEnabled ? '0' : '8%'}`,
          }}
        >
          <SelectionGroupHeader
            menuHeaderText={menuHeaderText}
            choiceType={choiceType}
            touchscreenEnabled={touchscreenEnabled}
            clickHandler={toggleMenu}
            menuOpen={menuOpen}
          />
          <div
            className={touchscreenEnabled ? styles.touchItemContainer : styles.webItemContainer}
            style={{
              border: `${menuOpen && !touchscreenEnabled ? '1px solid black' : 'none'}`
            }}
          >
            <ItemListWrapper touchscreenEnabled={touchscreenEnabled}>
              {menuOpen && populateCards(menuOptions)}
            </ItemListWrapper>
          </div>
        </div>
      )}
    </ClientSettingsConsumer>
  );
};

SelectionsGroup.defaultProps = {
  menuHeaderText: ''
};

SelectionsGroup.propTypes = {
  handleOptionItemClick: PropTypes.func.isRequired,
  menuOptions: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
  ]).isRequired,
  choiceType: PropTypes.string.isRequired,
  optionCategory: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
  ]).isRequired,
  menuHeaderText: PropTypes.string,
};

export default SelectionsGroup;
