/* eslint-disable no-param-reassign */
/* eslint-disable no-restricted-syntax */

/**
 * This utility file contains all helper functions used to manage the parsing
 * and modifying of any data related to Kova API calls or the configuratorData
 * object.
 *
 * Function naming conventions:
 *  - function names start with 'update' if it updates/modifies data
 *  - 'parse' if extracting data from an object
 *  - 'generate' if creating a new object
 */

export default class KovaData {
  /**
   * for applying options from quick moveins and maybe worksheets (TBD)
   * This changes options that aren't shown in the IFP options but may (TBD)
   * be used for total price (unsure at this point )
   */
  static updateHiddenOptions = (selectedOptions, categoryId, optionId) => {
    const selectedOptsCopy = { ...selectedOptions };
    selectedOptsCopy[categoryId] = optionId;
    return selectedOptsCopy;
  }
  // OptSelID: 'GARAGE',
  // // const categoryId = option.OptSelID;
  // OptValID: 'gar_3car||',
  // // const optionI = option.OptValID;
  // optionType: 'MultiChoice'

  /**
   * Click handler used to update selectedOptions object to be passed into
   * 'handleOptionChange' function in api.js
   */
  static updateSelectedOptions = (selectOpts, categoryId, optionId, choiceType) => (
    choiceType === 'SingleChoice'
      ? this.updateSingleChoiceToggle(selectOpts, categoryId, optionId)
      : this.updateMultiChoiceToggle(selectOpts, categoryId, optionId)
  );

  /**
   * If single choice option is included in the selectedOptions object,
   * then the choice is currently selected and the key/value pair must be
   * deleted from the object. If it is not included (selected), then the
   * key/value pair must be added to the selectedOptions object.
   */
  static updateSingleChoiceToggle = (selectedOptions, categoryId, optionId) => {
    const selectedOptsCopy = { ...selectedOptions };
    console.log(selectedOptions, categoryId, optionId);
    if (selectedOptions[optionId]) {
      delete selectedOptsCopy[optionId];
    } else {
      selectedOptsCopy[categoryId] = optionId;
    }
    return selectedOptsCopy;
  }

  // Updates Selected Options with new categoryId value
  static updateMultiChoiceToggle = (selectedOptions, categoryId, optionId) => {
    const selectedOptsCopy = { ...selectedOptions };
    selectedOptsCopy[categoryId] = optionId;

    return selectedOptsCopy;
  }

  static parseValidFloors = floorObj =>
    Object.keys(floorObj).map(floor => floorObj[floor])

  static parseOption = option => option.split('||')[0];

  // Finds the selected option from an array of multiChoiceOptions
  static findSelectedOptionKey = (multiChoiceOptions, key) => {
    const capitalizedKey = `${key.charAt(0).toUpperCase()}${key.substring(1)}`;

    for (const choice of multiChoiceOptions) {
      if (choice.Selected === true) return choice[capitalizedKey];
    }
  }

  static generateOptionNameList = (configData, key = 'name') =>
  // const capitalizedKey = `${key.charAt(0).toUpperCase()}${key.substring(1)}`;

    Object.keys(configData).reduce((accum, currVal) => {
      if (configData[currVal].Type === 'MultiChoice') {
        configData[currVal].Choices.forEach((opt) => {
          accum[opt.ID.split('|')[0]] = opt.Text;
        });
      } else {
        accum[currVal] = configData[currVal].Text;
        return accum;
      }
      return accum;
    }, {})


  static generatePriceList = (configData, key) => {
    const capitalizedKey = `${key.charAt(0).toUpperCase()}${key.substring(1)}`;
    return Object.keys(configData).reduce((accum, currVal) => {
      if (configData[currVal].Type === 'MultiChoice') {
        accum[configData[currVal].ID] = configData[currVal].Choices.map(opt => ({
          optionID: opt.ID,
          [key.toLowerCase()]: KovaData.fetchDataValue(key, opt[capitalizedKey]),
          optionType: 'MultiChoice'
        }));
      } else {
        accum[currVal] = KovaData.fetchDataValue(key, configData[currVal][capitalizedKey]);
        return accum;
      }
      return accum;
    }, {});
  }

  // Returns stringified price received from api (i.e. "$ 4,350.00") as a integer
  static formatPriceAsNum = (priceAsString) => {
    if (priceAsString) {
      const regex = /[^\d.]/g; // removes $, spaces, and comma
      return Number(priceAsString.replace(regex, ''));
    }
    return 0;
  }

  static formatNumberWithComma = num => (
    num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
  )

  static fetchDataValue = (key, optValue) => {
    switch (key) {
      case 'price':
        return KovaData.formatPriceAsNum(optValue);
      default:
        break;
    }
  }

  static createSelectedOptionsObj = (configDataOptions) => {
    return Object.keys(configDataOptions).reduce((accum, currVal) => {
      if (configDataOptions[currVal].Type === 'MultiChoice') {
        const foundOpt = configDataOptions[currVal].Choices.find(opt => opt.Selected);
        accum[currVal] = foundOpt.Text;
      }
      if (configDataOptions[currVal].Type === 'SingleChoice'
      && configDataOptions[currVal].Selected
      ) {
        accum[currVal] = configDataOptions[currVal].Text;
      }
      return accum;
    }, {});
  }

  static findAllSelectedOptions = (configDataOptions) => {
    return Object.keys(configDataOptions).reduce((accum, currVal) => {
      if (configDataOptions[currVal].Type === 'MultiChoice') {
        const foundOpt = configDataOptions[currVal].Choices.find(opt => opt.Selected);
        accum.push(foundOpt ? foundOpt.Text : '');
      }
      if (configDataOptions[currVal].Type === 'SingleChoice'
      && configDataOptions[currVal].Selected
      ) {
        accum.push(configDataOptions[currVal].Text);
      }
      return accum;
    }, []);
  }

  // Finds total value of all user selected options
  // Iterate through Options object and find all selected options
  // --> For each selected option, convert stringed price to a Number
  // --> The add to accumulator
  static findTotalOfSelectedOptions = (selectedOpts, priceList) => {
    console.log('selectedOpts', selectedOpts);
    console.log('priceList', priceList);
    
    return Object.keys(selectedOpts).reduce((accum, currVal) => {
      if (Array.isArray(priceList[currVal])) {
        let selected = priceList[currVal]
          .find(opt => opt.optionID === selectedOpts[currVal]);
          // some quick move in's have options that are not in the pricelist. so they will be ignored for now
          // -William, worksheets branch, Thurs sept 3rd
          if(!selected){
            selected = {price: 0};
          }
          
        accum += selected.price;
      } else if (Number.isInteger(priceList[currVal])) {
        accum += priceList[currVal];
      } else {
        return 0
      }
      return accum;
    }, 0)
  }

  // filter communities to find those with specific model RID
  static findCommunitiesWithModelRID = (arrayCommResponses, modelRID) => (
    arrayCommResponses
      .filter(community => (
        KovaData.checkCommunityForModelRID(community.data, modelRID)))
  )

  // Check individual community for modelRID
  static checkCommunityForModelRID = (modelList, modelRID) => (
    modelList.some(model => model.ModelRID === modelRID)
  )

  static parseCommRidFromUrl = (url) => {
    const urlStr = url.split('?')[0];
    return Number(urlStr.slice(urlStr.length - 3));
  }

  static mapCommRidToCommunityName = (arrayOfRIDs, commList) => (
    arrayOfRIDs.reduce((accum, currRid) => {
      let obj = {};

      const foundComm = commList
        .find(community => community.CommunityRID === currRid);
      obj.rid = currRid;
      obj.name = foundComm.Name;

      accum.push(obj);
      return accum;
    }, [])
  )
}
