import AWS from 'aws-sdk';
import GlobalConfig from '../GlobalConfig';

export default class S3 {
  static connect = () => {
    AWS.config.region = GlobalConfig.get(GlobalConfig.Key.S3_REGION);
    AWS.config.credentials = new AWS.Credentials(
      GlobalConfig.get(GlobalConfig.Key.S3_ID),
      GlobalConfig.get(GlobalConfig.Key.S3_KEY)
    );

    const s3 = new AWS.S3({
      apiVersion: '2006-03-01',
      params: { Bucket: GlobalConfig.get(GlobalConfig.Key.S3_BUCKET) }
    });

    return s3;
  }

  static putObject = (contentType, file, key, s3) =>
    new Promise((resolve, reject) => {
      const params = {
        Key: key,
        Body: file,
        Bucket: GlobalConfig.get(GlobalConfig.Key.S3_BUCKET),
        ContentType: contentType,
      };
      s3.putObject(params, (err, response) => {
        if (err) {
          reject(err);
        } else {
          resolve(response);
        }
      });
    })
}
