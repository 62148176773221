const doorConfig = [
  {
    type: 'DS',
    sequenceID: 'door-option',
    text: {
      main: 'Door',
      detail: ''
    }
  }
];

export default doorConfig;
