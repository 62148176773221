import React from 'react';
import PropTypes from 'prop-types';
import styles from './SideMenuButton.module.css';

const SideMenuButton = ({
  buttonText,
  clickHandler,
}) => (
  <div
    className={styles.sideMenuButton}
    onClick={clickHandler}
    role="button"
    tabIndex="0"
  >
    <div className={styles.innerButton}>
      {buttonText}
    </div>
  </div>
);

SideMenuButton.defaultProps = {
  buttonText: 'Placeholder',
  clickHandler: null
};

SideMenuButton.propTypes = {
  clickHandler: PropTypes.func,
  buttonText: PropTypes.string
};

export default SideMenuButton;
