/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
import React from 'react';
import PropTypes from 'prop-types';
import styles from './ToggleSwitch.module.css';

const ToggleSwitch = ({ active, clickHandler }) => (
  <label
    aria-label="switch"
    className={`${styles.switch} ${active ? styles.active : ''}`}
    role="button"
    tabIndex={0}
    onKeyPress={(event) => {
      if (event.key === 'Enter') {
        clickHandler();
      }
    }}
  >
    <input type="checkbox" checked={active} onChange={clickHandler} />
    <span className={`${styles.slider}`} />
  </label>
);

ToggleSwitch.propTypes = {
  active: PropTypes.bool.isRequired,
  clickHandler: PropTypes.func.isRequired,
};

export default ToggleSwitch;
