import React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import styles from './ExteriorStage.module.css';

import {
  selectElevation,
  selectModelName,
  selectColorSelector,
  selectOptionDefaults
} from '../../../redux/colorSelector/colorSelector.selectors';
import GlobalConfig from '../../../utils/GlobalConfig';

const ExteriorStage = ({
  elevation,
  model,
  optionDefaults
}) => {
  const imgSrcBase = `${GlobalConfig.get(GlobalConfig.Key.COLOR_SELECTOR_URL)}${model}/${elevation}/`;
  const baseImgSrc = `${imgSrcBase}AH_${model.replace(' ', '')}_${elevation.replace('elev', 'Elev')}_0001.jpg`;

  const renderLayers = () => {
    const layers = Object.keys(optionDefaults).map((opt) => {
      if (optionDefaults[opt] && (optionDefaults[opt].frame !== 1)) {
        return (
          <img
            key={`${optionDefaults[opt].fileName}_${optionDefaults[opt].frame}`}
            crossOrigin="anonymous"
            className={styles.exteriorStageImg}
            src={`${imgSrcBase}${optionDefaults[opt].fileName}.png`}
            alt={`${optionDefaults[opt]} frame`}
          />
        );
      }
      return null;
    }).filter(Boolean);
    return layers;
  };

  return (
    <div className={styles.exteriorStage} id="exteriorStageComponent">
      <img
        crossOrigin="anonymous"
        className={styles.exteriorStageImg}
        src={baseImgSrc}
        alt="model home"
      />
      {renderLayers()}
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  elevation: selectElevation,
  colorSelector: selectColorSelector,
  model: selectModelName,
  optionDefaults: selectOptionDefaults
});

ExteriorStage.propTypes = {
  elevation: PropTypes.string.isRequired,
  model: PropTypes.string.isRequired,
  optionDefaults: PropTypes.object.isRequired,
};

export default connect(mapStateToProps)(ExteriorStage);
