import React from 'react';
import PropTypes from 'prop-types';
import SideMenuButton from '../SideMenuButton/SideMenuButton';
import styles from './ButtonPanel.module.css';

const ButtonPanel = ({
  handleClearSelections,
}) => (
  <div className={styles.buttonPanel}>
    <div className={styles.fullButton}>
      <SideMenuButton
        buttonText="clear all"
        clickHandler={handleClearSelections}
      />
    </div>
  </div>
);

ButtonPanel.propTypes = {
  handleClearSelections: PropTypes.func.isRequired,
};

export default ButtonPanel;
