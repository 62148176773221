/* eslint-disable react/prop-types */
import React, { useContext } from 'react';

import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import styles from './FilterDropdown.module.css';

import { ClientSettingsContext } from '../../context/ClientSettingsContext';

import {
  selectCommunityRID,
  selectDirectory,
  selectDropdownCommunityRID
} from '../../redux/community/community.selectors';

import {
  setCommunityDetails,
  setDropdownCommRID
} from '../../redux/community/community.actions';

const FilterDropdown = ({
  communityRID,
  directory,
  dropdownCommunityRID,
  setDropdownCommRID,
  setCommunityDetails
}) => {
  const handleChange = (e) => {
    e.preventDefault();
    const selectedDropdownCommRID = Number(e.target.value);
    setDropdownCommRID(selectedDropdownCommRID);
    setCommunityDetails(selectedDropdownCommRID);
  };

  const clientSettings = useContext(ClientSettingsContext);

  return (
    clientSettings.showCommunityDropdownList
      ? (
        <div className={styles.filterDropdown}>
          {directory && dropdownCommunityRID
            ? (
              <select
                className={styles.filter}
                id="community"
                value={dropdownCommunityRID}
                onChange={handleChange}
                multiple={false}
              >
                {directory.map(community => (
                  <option
                    key={`${community.name}_${community.rid}`}
                    value={String(community.rid)}
                  >
                    {community.name}
                  </option>
                ))}
              </select>
            ) : (
              null
            )}
        </div>
      ) : null
  );
};

const mapStateToProps = createStructuredSelector({
  communityRID: selectCommunityRID,
  directory: selectDirectory,
  dropdownCommunityRID: selectDropdownCommunityRID
});

const mapDispatchToProps = dispatch => ({
  setCommunityDetails: selectedRID => dispatch(
    setCommunityDetails(selectedRID)
  ),
  setDropdownCommRID: configData => dispatch(
    setDropdownCommRID(configData)
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(FilterDropdown);
