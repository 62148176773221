import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as d3 from 'd3';
import styles from './Stage.module.css';
import SvgBuilder from '../../utils/SvgBuilder/SvgBuilder';

import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { selectShowKovaStage, selectResetClicked } from '../../redux/app/app.selectors';
import { selectCommunityRID } from '../../redux/community/community.selectors';
import {
  selectModelRID,
  selectConfigData,
  selectModelRules,
  selectModelOptions,
  selectFloor
} from '../../redux/model/model.selectors';

import { toggleResetView } from '../../redux/app/app.actions';
import GlobalConfig from '../../utils/GlobalConfig';

class Stage extends Component {
  state = {
    arrangedSvgs: []
  };

  componentDidMount() {
    this.handleZoom();
  }

  componentDidUpdate(prevState, prevProps) {
    this.handleZoom();
  }

  handleZoom = () => {
    const { resetClicked, toggleResetView } = this.props;
    const container = d3.select('g');

    function zoomed() {
      container.attr('transform', d3.event.transform);
    }

    const zoom = d3
      .zoom()
      .scaleExtent([0.5, 5])
      .on('zoom', zoomed);

    const svg = d3.select('svg').call(zoom);

    let bbox;
    let vx;
    let vy;
    let vw;
    let vh;
    let defaultView;

    if (container.node() !== null) {
      bbox = container.node().getBBox();
      vx = bbox.x; // container x co-ordinate
      vy = bbox.y; // container y co-ordinate
      vw = bbox.width; // container width
      vh = bbox.height; // container height
      defaultView = `${vx} ${vy} ${vw} ${vh}`;
      svg
        .attr('viewBox', defaultView)
        .attr('preserveAspectRatio', 'xMidYMid meet')
        .call(zoom);
    }

    // function reset() {
    //   svg
    //     .transition()
    //     .duration(750)
    //     .call(zoom.transform, d3.zoomIdentity);
    // }

    if (resetClicked) {
      svg
        .transition()
        .duration(750)
        .call(zoom.transform, d3.zoomIdentity);
        
      toggleResetView();
    }
  };

  render() {
    const {
      showKovaStage,
      modelRID,
      communityRID,
      selectedFloor,
      modelRules,
      modelOptions
    } = this.props;

    let arrangedSvgs;

    if (!showKovaStage) {
      const svgBuilder = new SvgBuilder(
        modelOptions,
        selectedFloor,
        modelRules
      );

      arrangedSvgs = svgBuilder.build();
    }

    const communityParam = GlobalConfig.get(GlobalConfig.Key.CLIENT_NAME) === 'oakwood' ? `` : `${communityRID}/`;

    return !showKovaStage ? (
      <div className={styles.stage}>
        <svg
          id="svg-main"
          width="100%"
          height="100%"
          viewBox="0 0 100 100"
          preserveAspectRatio="xMidYMid meet"
        >
          <g>
            <svg className="svg-layer" width="200" height="200">
              <image
                xlinkHref={`
                  https://s3-${GlobalConfig.get(GlobalConfig.Key.S3_REGION)}.amazonaws.com/${
                    GlobalConfig.get(GlobalConfig.Key.S3_BUCKET)
                  }/${communityParam}${modelRID}/svg/std/floor_${selectedFloor}.svg`}
                width="100"
                height="100"
              />
            </svg>
            {arrangedSvgs.map(svgHref => (
              <svg key={svgHref} className="svg-layer" width="200" height="200">
                <image
                  xlinkHref={`https://s3-${GlobalConfig.get(GlobalConfig.Key.S3_REGION)}.amazonaws.com/${
                    GlobalConfig.get(GlobalConfig.Key.S3_BUCKET)
                  }/${communityParam}${modelRID}/svg/std/floor_${selectedFloor}/${svgHref}-01.svg`}
                  width="100"
                  height="100"
                />
              </svg>
            ))}
          </g>
        </svg>
      </div>
    ) : null;
  }
}

const mapStateToProps = createStructuredSelector({
  showKovaStage: selectShowKovaStage,
  configData: selectConfigData,
  modelRID: selectModelRID,
  communityRID: selectCommunityRID,
  modelRules: selectModelRules,
  modelOptions: selectModelOptions,
  selectedFloor: selectFloor,
  resetClicked: selectResetClicked
});

const mapDispatchToProps = dispatch => ({
  toggleResetView: () => dispatch(toggleResetView())
});

export default connect(mapStateToProps, mapDispatchToProps)(Stage);

Stage.propTypes = {
  resetClicked: PropTypes.bool.isRequired,
  toggleResetView: PropTypes.func.isRequired
};
