import { AppActionTypes } from './app.types';

export const setShowKovaStage = showKovaStage => ({
  type: AppActionTypes.SET_SHOW_KOVA_STAGE,
  payload: showKovaStage
});

export const toggleSideMenu = () => ({
  type: AppActionTypes.TOGGLE_SIDE_MENU
});

export const toggleResetView = () => ({
  type: AppActionTypes.TOGGLE_RESET_VIEW
});

export const toggleTriggerPrint = () => ({
  type: AppActionTypes.TOGGLE_TRIGGER_PRINT
});

export const setOptionMenuToDisplay = optMenuToDisplay => ({
  type: AppActionTypes.SET_OPTION_MENU_TO_DISPLAY,
  payload: optMenuToDisplay
});

export const setDownloadURL = downloadURL => ({
  type: AppActionTypes.SET_DOWNLOAD_URL,
  payload: downloadURL
});
