class GlobalConfig {
  constructor() {
    this.config = {};
    this.Key = {
      CLIENT_NAME: 'CLIENT_NAME',
      S3_BUCKET: 'S3_BUCKET',
      KOVA_API_ROOT: 'KOVA_API_ROOT',
      VSC_API_ROOT: 'VSC_API_ROOT',
      KOVA_PUBLIC_API_KEY: 'KOVA_PUBLIC_API_KEY',
      S3_KOVA_IFP: 'S3_KOVA_IFP',
      COLOR_SELECTOR_URL: 'COLOR_SELECTOR_URL',
      S3_ID: 'S3_ID',
      S3_KEY: 'S3_KEY',
      S3_REGION: 'S3_REGION',
      SET_PROTOCOL: 'SET_PROTOCOL',
      SOCKET_URL: 'SOCKET_URL',
      HUBSPOT_CONNECTION_URL: 'HUBSPOT_CONNECTION_URL',
      HUBSPOT_ANALYTICS_ID: 'HUBSPOT_ANALYTICS_ID',
      GOOGLE_ANALYTICS_ID: 'GOOGLE_ANALYTICS_ID'
    };
  }

  setConfig(config) {
    this.config = config;
  }

  setClientNameOverride(clientname) {
    this.config[this.Key.CLIENT_NAME] = clientname;
  }

  get(key) {
    return this.config[key];
  }
}

const configInstance = new GlobalConfig();

export default configInstance;
