const ModelActionTypes = {
  SET_MODEL_RID: 'SET_MODEL_RID',
  SET_CONFIG_DATA: 'SET_CONFIG_DATA',
  SET_MODEL_RULES: 'SET_MODEL_RULES',
  SET_SELECTED_FLOOR: 'SET_SELECTED_FLOOR',
  SET_MODEL_DETAILS: 'SET_MODEL_DETAILS',
  SET_PRICE_LIST: 'SET_PRICE_LIST'
};

export default ModelActionTypes;
