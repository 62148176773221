import CustomRules from '../CustomRules/CustomRules';

/**
 * configDataOpts: Options object from configuratorData
 * selectedFloor: converted floor index (i.e. -1 = 2 which is first floor)
 * stringFloor: stringified/concatenated floor for use in urls/keys
 * modelRules: floor specific custom rules
 */
export default class SvgBuilder {
  constructor(configDataOpts, selectedFloor, modelRules) {
    this.configDataOpts = configDataOpts;
    this.selectedFloor = Number(selectedFloor);
    this.modelRules = modelRules;
  }

  build() {
    const allSelectedOptions = SvgBuilder.findAllSelectedOptions(
      this.configDataOpts
    );
    // console.log('allSelectedOptions', allSelectedOptions);
    // console.log('this.modelRules', this.modelRules);
    
    const permittedOptions = SvgBuilder.findPermittedOptions(
      this.modelRules.optionsWhitelist[this.selectedFloor],
      allSelectedOptions
    );
    // console.log('permittedOptions', permittedOptions);

    const ruleEnforcedSvgs = SvgBuilder.applyCustomRules(
      this.modelRules,
      permittedOptions,
      this.selectedFloor
    );
    // console.log('ruleEnforcedSvgs', ruleEnforcedSvgs);

    const arrangedSvgs = SvgBuilder.arrangeSvgsForDisplay(
      this.modelRules,
      ruleEnforcedSvgs,
      this.selectedFloor
    );

    // console.log('arrangedSvgs', arrangedSvgs);
    

    return arrangedSvgs;
  }

  static formatSelectedOption(option) {
    return option.replace(/\|\|/g, '').toLowerCase();
  }

  static findAllSelectedOptions(optionsData) {
    const output = [];

    Object.keys(optionsData).forEach((option) => {
      const currentOption = optionsData[option];
      if (currentOption.Type === 'MultiChoice') {
        currentOption.Choices.forEach((singleOpt) => {
          if (singleOpt.Selected) {
            if (
              singleOpt.ID.includes('cabs')
              || singleOpt.ID.includes('Chrome')
            ) {
              const splitOpt = singleOpt.ID.split('|')[0];
              output.push(splitOpt);
            } else {
              output.push(
                this.formatSelectedOption(singleOpt.ID)
              );
            }
          }
        });
      } else if (currentOption.Selected) {
        output.push(this.formatSelectedOption(currentOption.ID));
      }
    });

    return output;
  }

  // Filters all Selected Options and returns only those that will be rendered
  static findPermittedOptions = (
    whitelist,
    allSelectedOptions
  ) =>
    allSelectedOptions.filter(option => whitelist.includes(option));

  // Places options that contain priority at end of array to render on top
  static arrangeSvgsForDisplay = (modelRules, enforcedSvgs, selectedFloor) => {
    // used for manually adjusting svg layers for
    const manualPriorityOne = [
      'bld_type1',
      'bld_type2',
      'bld_type3',
      'bld_type4'
    ];
    const manualPriorityTwo = [
      'elev_a',
      'elev_b',
      'elev_c',
      'elev_d',
      'lnd_domestic'
    ];
    const manualPriorityFour = [
      'win_higlass',
      'bas_finish',
      'bas_any',
      'bas_finish2',
      'bas_finish3',
      'gms_door',
      'gms_tubshw',
      'gms_brndoor'
    ];
    const manualPriorityFive = [
      'act_kitchenette',
      'act_wetbar',
      'str_popout',
      'kit_basecafe',
      'baa_shower',
      'mbr_anglebedwall',
      'mbr_fp1',
      'mbr_fp2',
      'bab_shower',
      'bas_shower',
      'gms_a',
      'win_recipe',
      'drz_builtins',
    ];

    const priorityOne = [];
    const priorityTwo = [];
    const priorityFour = [];
    const priorityFive = [];

    const priorityThree = (modelRules && modelRules.manualOptOnOverride && modelRules.manualOptOnOverride[selectedFloor])
      ? modelRules.manualOptOnOverride[selectedFloor].slice(0)
      : [];

    enforcedSvgs.forEach((svg) => {
      const foundRule = modelRules.customRules.find(
        rule => rule.altHref === svg
      );

      if (foundRule) {
        switch (foundRule.layerPriority) {
          case 1:
            priorityOne.unshift(svg); // Will force svg to bottom most layer
            break;
          case 2:
            priorityTwo.push(svg);
            break;
          case 3:
            priorityThree.push(svg);
            break;
          case 4:
            priorityFour.push(svg);
            break;
          case 5:
            priorityFive.push(svg);
            break;
          default:
            priorityThree.push(svg);
        }
      } else if (manualPriorityOne.includes(svg)) {
        priorityOne.push(svg);
      } else if (manualPriorityTwo.includes(svg)) {
        priorityTwo.push(svg);
      } else if (manualPriorityFour.includes(svg)) {
        priorityFour.push(svg);
      } else if (manualPriorityFive.includes(svg)) {
        priorityFive.push(svg);
      } else {
        !priorityThree.includes(svg) && priorityThree.push(svg);
      }
    });

    const prioritizedSvgs = priorityOne.concat(
      priorityTwo.concat(
        priorityThree.concat(priorityFour.concat(priorityFive))
      )
    );

    return prioritizedSvgs;
  };

  /*
  * TODO: Add comments for this function
  */
  static applyCustomRules = (modelRules, permittedOptions, selectedFloor) => {
    // use slice to break ref to original array
    let duplicatePermittedOpts = permittedOptions.slice(0);
    const referenceList = permittedOptions.slice(0);
    const joinHrefs = [];

    modelRules.customRules.forEach(
      ({
        floor,
        ruleType,
        altHref,
        multiGroup,
        groupToReplace
      }) => {
        if (selectedFloor !== floor) return;

        if (ruleType === 'multi') {
          if (
            CustomRules.checkAllMultiConditionOptions( // check if current rule is satisfied
              multiGroup,
              groupToReplace,
              referenceList
            )
          ) {
            if (groupToReplace === 'multiOr') {
              const orGroup = multiGroup[groupToReplace];
              const optToReplace = CustomRules.findItemInArray(
                orGroup,
                referenceList
              );
              duplicatePermittedOpts = CustomRules.replaceWithAltHref(
                optToReplace,
                altHref,
                duplicatePermittedOpts
              );
            } else if (groupToReplace === 'multiJoin') {
              joinHrefs.push(altHref);
            } else if (groupToReplace === 'multiAnd' || groupToReplace === 'multiAndNot') {
              const firstItem = multiGroup[groupToReplace][0];
              duplicatePermittedOpts = CustomRules.replaceWithAltHref(
                firstItem,
                altHref,
                duplicatePermittedOpts
              );
            } else if (groupToReplace === 'multiNot') {
              duplicatePermittedOpts.push(altHref);
            } else {
              // eslint-disable-next-line no-console
              console.error(
                'Invalid groupToReplace',
                groupToReplace
              );
            }
          }
        }

        if (ruleType === 'multiCategory') {
          // Retrieve array of category options
          const categoryConvertList = modelRules
            .categoriesToConvert;

          // Store array of specific options selected
          const optionsToRemove = CustomRules.checkMultiConditionCategories(
            multiGroup,
            categoryConvertList,
            referenceList
          );

          // Remove options from permittedOptions and replace with altHref
          if (optionsToRemove) {
            duplicatePermittedOpts = CustomRules.replaceWithAltHrefCategory(
              optionsToRemove,
              altHref,
              permittedOptions
            );
          }
        }
      }
    );
    return duplicatePermittedOpts.concat(joinHrefs);
  };
}
