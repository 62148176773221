import React, { useContext } from 'react';
// import PropTypes from 'prop-types';
import styles from './BrandLogo.module.css';
import { ClientSettingsContext } from '../../context/ClientSettingsContext';


const BrandLogo = () => {
  const ClientSettings = useContext(ClientSettingsContext);
  const { theme: { logoColor, primaryColor, logo } } = ClientSettings;

  return (
    <div
      className={styles.brandLogo}
      style={
        { backgroundColor: logoColor || primaryColor }
      }
    >
      <img
        className={styles.logoImg}
        src={`./logo/${logo}`}
        alt="logo"
      />
    </div>
  );
};

export default BrandLogo;
