/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
import React from 'react';
import PropTypes from 'prop-types';
import styles from './ItemListWrapper.module.css';

const ItemListWrapper = ({ touchscreenEnabled, children }) => (
  <div className={
    touchscreenEnabled
      ? styles.touchscreenItemListWrapper
      : styles.itemListWrapper
    }
  >
    {children}
  </div>
);

ItemListWrapper.propTypes = {
  touchscreenEnabled: PropTypes.bool.isRequired,
  children: PropTypes.array.isRequired
};

export default ItemListWrapper;
