import React from 'react';
import PropTypes from 'prop-types';
import styles from './ListItem.module.css';

const ListItem = ({
  text,
  value
}) => (
  <div className={styles.listItem}>
    <div className={styles.detailTitle}>
      {text}
    </div>
    <div className={styles.detailValue}>
      {String(value)}
    </div>
  </div>
);

ListItem.propTypes = {
  text: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

export default ListItem;
