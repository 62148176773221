/* eslint-disable consistent-return */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import PropTypes from 'prop-types';

import styles from './CadFloorplanRenderer.module.css';
import {
  calculateImageName,
  calcZIndex,
  calcLeftOffset,
  calcBottomOffset,
  determineLabelVis,
  createLabelList,
  generateImgSizeLoc,
  matchOptionLabelWithParent,
  findAllOptionLabelOverlaps,
  createOptionLabelLookup,
  removeOverlappedBaseLabels,
  handleOptionLabelOverlaps
} from '../../utils/ArborFloorplanBuilder/ArborFloorplanBuilder';

import { selectShowKovaStage } from '../../redux/app/app.selectors';
import { selectCommunityRID, selectCommunityDetails } from '../../redux/community/community.selectors';
import {
  selectModelRID,
  selectFloor,
  selectedOptions,
  selectShowElevation
} from '../../redux/model/model.selectors';
import GlobalConfig from '../../utils/GlobalConfig';

class CadFloorplanRenderer extends Component {
  state = {
    globalLeftOffset: 0, // Globals
    globalBottomOffset: 0,
    baseLabels: null,
    options: null,
    optionLabels: null,
    parentOptionLookup: null,
    optionLabelOverlaps: null,
    optionLabelLookup: null,
    baseImage: null,
    baseImageWidth: null,
    kovaScaleResult: null,
    kovaScaleSet: false
  };

  componentDidMount() {
    const { selectedFloor, filteredOptImgs } = this.props;

    if (filteredOptImgs) this.compileImageData(filteredOptImgs, selectedFloor);
  }

  shouldComponentUpdate(nextProps) {
    const { showKovaStage } = this.props;
    return showKovaStage;
  }

  componentDidUpdate(prevProps) {
    const { selectedFloor, filteredOptImgs } = this.props;

    if (selectedFloor !== prevProps.selectedFloor) {
      this.compileImageData(filteredOptImgs, selectedFloor);
    }

    if (!prevProps.filteredOptImgs) {
      this.compileImageData(filteredOptImgs, selectedFloor);
    }
  }

  reduceImgSize = (e, scaleFactor) => {
    const { baseImage, kovaScaleSet } = this.state;
    let kovaScaleResult = scaleFactor;
    const isBaseImg = Number(e.target.id) === baseImage.ObjectRID;

    // Dynamically determine correct scale to achieve a height of 700px;
    if (isBaseImg && !kovaScaleSet) {
      const maxHeight = 700;
      kovaScaleResult = Math.round((maxHeight / e.target.height) * 100) / 100;
    }

    // Apply the calculated scale to every image
    e.target.style.height = `${e.target.height * kovaScaleResult}px`;
    e.target.style.visibility = 'visible';

    /**
     * If current image is the base image and kova scale is not set in state
     * then set all 3 propertes in state
     *
     * Otherwise, the scale has already been determined, reset the value of
     * base width for the current floor so the floorplan
     * remains centered on the screen
     * */
    if (isBaseImg && !kovaScaleSet) {
      this.setState({
        baseImageWidth: String(e.target.offsetWidth),
        kovaScaleResult,
        kovaScaleSet: true
      });
      return;
    }

    if (isBaseImg) {
      this.setState({
        baseImageWidth: String(e.target.offsetWidth)
      });
    }
  };

  compileImageData = (filteredOptImgs, selectedFloor) => {
    const imgsByFloor = filteredOptImgs[selectedFloor];
    const baseImage = imgsByFloor.find(img => img.Type === 'Base');
    const baseLabels = imgsByFloor.filter(img => img.Type === 'BaseLabel');
    const options = imgsByFloor.filter(img => img.Type === 'Option');
    const optionLabels = imgsByFloor.filter(img => img.Type === 'OptionLabel');
    const allLabels = baseLabels.concat(optionLabels);
    const allImgs = allLabels.concat(options);
    const locationData = generateImgSizeLoc(allImgs);
    const parentOptionLookup = matchOptionLabelWithParent(
      optionLabels,
      options
    );
    const optionLabelOverlaps = findAllOptionLabelOverlaps(
      optionLabels,
      allLabels,
      locationData
    );
    const optionLabelLookup = createOptionLabelLookup(options, optionLabels);

    this.setState({
      baseImage,
      baseLabels,
      options,
      optionLabels,
      parentOptionLookup,
      optionLabelOverlaps,
      optionLabelLookup,
    });
  };

  generateAllImgs = (selOptsWithCommId) => {
    const {
      communityRID,
      modelRID,
      filteredOptImgs,
      selectedFloor
    } = this.props;

    const {
      globalBottomOffset,
      globalLeftOffset,
      kovaScaleResult
    } = this.state;

    const imgFolder = `https://s3-${GlobalConfig.get(GlobalConfig.Key.S3_REGION)}.amazonaws.com/${
      GlobalConfig.get(GlobalConfig.Key.S3_KOVA_IFP)
    }/OptCfgImgs/${modelRID}/OptCfgImgs/`;

    const images = filteredOptImgs[selectedFloor].filter(
      img => img.Type === 'Option' || img.Type === 'Base'
    );

    return images.map((optImage) => {
      const srcImg = calculateImageName(
        optImage,
        imgFolder,
        selOptsWithCommId
      );

      if (srcImg) {
        return (
          <img
            key={optImage.ObjectRID}
            id={optImage.ObjectRID}
            className={styles.kovaLayer}
            src={`${srcImg}`}
            style={{
              bottom: `${calcBottomOffset(
                optImage,
                globalBottomOffset,
                kovaScaleResult
              )}px`,
              left: `${calcLeftOffset(
                optImage,
                globalLeftOffset,
                kovaScaleResult
              )}px`,
              zIndex: srcImg.toLowerCase().includes('-base')
                ? -2147483646
                : calcZIndex(optImage) * -1,
              position: 'absolute',
              visibility: 'hidden',
              // outline: '2px solid red'
            }}
            onLoad={e => this.reduceImgSize(e, kovaScaleResult)}
            alt=""
          />
        );
      }
      return null;
    }).filter(Boolean);
  };

  generateAllText = (selOptsWithCommId) => {
    const { kovaScaleResult } = this.state;

    const {
      globalBottomOffset,
      globalLeftOffset,
      baseLabels,
      options,
      optionLabels,
      parentOptionLookup,
      optionLabelOverlaps,
      optionLabelLookup
    } = this.state;

    let associatedOptLabelRIDs = [];

    const optionsToDisplay = createLabelList(options, selOptsWithCommId);

    let optionLabelsToDisplay = createLabelList(optionLabels, selOptsWithCommId);

    // Generate array of all option label RIDs to display based on selected options
    optionsToDisplay.forEach((option) => {
      associatedOptLabelRIDs = associatedOptLabelRIDs.concat(
        optionLabelLookup[option.ObjectRID].relatedOptLabels
      );
    });

    const baseOverlappedLabelsRemoved = removeOverlappedBaseLabels(
      baseLabels,
      associatedOptLabelRIDs,
      optionLabelOverlaps
    );

    const resolvedOptLabelRIDs = new Set(
      handleOptionLabelOverlaps(
        associatedOptLabelRIDs,
        optionLabelOverlaps,
        parentOptionLookup
      )
    );

    optionLabelsToDisplay = optionLabelsToDisplay.filter(label =>
      resolvedOptLabelRIDs.has(label.ObjectRID));

    const newAgg = baseOverlappedLabelsRemoved.concat(optionLabelsToDisplay);

    return newAgg.map((optImage, index) => {
      const baseLength = newAgg[index].Description.length;
      let i = 0;
      const asciiCheck = 10;
      const newDesc = newAgg[index].Description;
      // let matchedVert = 0;
      // let matchedHorizontal = 0;
      // const debug = false; // if set to true, floorplan will display filenames

      //  VAlign
      //  T = top - C = center - B = bottom

      //  HAlign
      //  L means left - R = right - C = center

      // if (optImage.VAlign === 'T') {
      //   matchedVert = Number(optImage.Height * kovaScaleResult);
      // } else if (optImage.VAlign === 'C') {
      //   matchedVert = Number((optImage.Height / 2) * kovaScaleResult);
      // } else if (optImage.VAlign === 'M') {
      //   matchedVert = Number((optImage.Height / 2) * kovaScaleResult);
      // } else if (optImage.VAlign === 'B') {
      //   matchedVert = 0;
      // }

      // if (optImage.HAlign === 'L') {
      //   matchedHorizontal = 0;
      // } else if (optImage.HAlign === 'C') {
      //   matchedHorizontal = Number((optImage.Width / 2) * kovaScaleResult);
      // } else if (optImage.HAlign === 'R') {
      //   matchedHorizontal = Number(optImage.Width * kovaScaleResult);
      // }

      const replace = '\n';

      for (i = 0; i < baseLength; i += 1) {
        const asciiAtIndex = newAgg[index].Description.charCodeAt(i);
        if (asciiAtIndex === asciiCheck) {
          newDesc.replace(/&#32/, replace);
        }
      }

      return (
        <p
          key={optImage.ObjectRID}
          className={styles.kovaLayer}
          id={optImage.ObjectRID}
          style={{
            bottom: `${calcBottomOffset(
              optImage,
              globalBottomOffset,
              kovaScaleResult
            )}px`,
            left: `${Number(
              calcLeftOffset(optImage, globalLeftOffset, kovaScaleResult)
            )}px`,
            position: 'absolute',
            display: 'inline-block',
            height: '0px',
            width: `${optImage.Width * kovaScaleResult}px`,
            zIndex:
              optImage.Type !== 'OptionLabel'
                ? calcZIndex(optImage)
                : calcZIndex(optImage) * -1,
            visibility: `${determineLabelVis(optImage, selOptsWithCommId)}`,
            fontSize: `${optImage.KY * kovaScaleResult}px`,
            whiteSpace: 'pre',
            textAlign: 'center',
            // outline: '2px solid blue',
            // transform: `rotate(-${optImage.Angle}deg)`,
            // transformOrigin: 'top left'
          }}
          alt=""
        >
          {newDesc}
        </p>
      );
    });
  };

  generateKovaElevationImg = (selOptsWithCommId) => {
    const {
      modelRID,
      communityRID,
      filteredOptImgs,
      selectedFloor
    } = this.props;

    const images = filteredOptImgs[selectedFloor];
    const imgFolder = `https://s3-${GlobalConfig.get(GlobalConfig.Key.S3_REGION)}.amazonaws.com/${
      GlobalConfig.get(GlobalConfig.Key.S3_KOVA_IFP)
    }/OptCfgImgs/${modelRID}/OptCfgImgs/`;
    let conditionalSrcImg;

    images.forEach((optImage) => {
      const srcImg = calculateImageName(optImage, imgFolder, selOptsWithCommId);

      if (srcImg) conditionalSrcImg = srcImg;
    });

    return (
      <div className={styles.elevationImgContainer}>
        <img
          alt="Selected elevation"
          className={styles.kovaElevationImg}
          src={conditionalSrcImg}
        />
      </div>
    );
  };

  render() {
    const {
      selectedOpts,
      showKovaStage,
      communityDetails,
      showElevation,
      filteredOptImgs
    } = this.props;

    const { options, baseImageWidth } = this.state;

    const selOptsWithCommunityId = selectedOpts.slice();

    if (communityDetails && communityDetails.CommunityID) {
      selOptsWithCommunityId.unshift(communityDetails.CommunityID);
    }

    return showKovaStage && filteredOptImgs ? (
      <div
        style={{ width: `${!showElevation ? `${baseImageWidth}px` : '100%'}` }}
        className={`${!showElevation ? styles.arborKovaStage : styles.kovaElevation}`}
      >
        {selOptsWithCommunityId
          && options
          && !showElevation
          && this.generateAllImgs(selOptsWithCommunityId).filter(Boolean)}
        {selOptsWithCommunityId
          && options
          && !showElevation
          && this.generateAllText(selOptsWithCommunityId)}
        {showElevation
          && this.generateKovaElevationImg(selOptsWithCommunityId)}
      </div>
    ) : null;
  }
}

const mapStateToProps = createStructuredSelector({
  showKovaStage: selectShowKovaStage,
  modelRID: selectModelRID,
  communityRID: selectCommunityRID,
  selectedFloor: selectFloor,
  selectedOpts: selectedOptions,
  communityDetails: selectCommunityDetails,
  showElevation: selectShowElevation,
});

export default connect(mapStateToProps)(CadFloorplanRenderer);

CadFloorplanRenderer.defaultProps = {
  filteredOptImgs: []
};

CadFloorplanRenderer.propTypes = {
  filteredOptImgs: PropTypes.array,
  modelRID: PropTypes.number.isRequired,
  communityRID: PropTypes.number.isRequired,
  selectedFloor: PropTypes.number.isRequired,
  selectedOpts: PropTypes.array.isRequired,
  showKovaStage: PropTypes.bool.isRequired,
  communityDetails: PropTypes.object.isRequired,
  showElevation: PropTypes.bool.isRequired,
};
