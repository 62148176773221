function handleToggleView(availFloors, currentFloor, direction) {
  const numFloors = availFloors.length - 1;
  let output;

  if (direction === 'left') {
    const decrementView = currentFloor - 1;
    output = decrementView < 0 ? numFloors : decrementView;
  }
  if (direction === 'right') {
    const incrementView = currentFloor + 1;
    output = incrementView > numFloors ? 0 : incrementView;
  }
  return output;
}

export default handleToggleView;
