import React, { useContext } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import ListItem from '../ListItem/ListItem';

import { selectModelDetails } from '../../../redux/model/model.selectors';

import { ClientSettingsContext } from '../../../context/ClientSettingsContext';

const HomeSpecs = ({ modelDetails }) => {
  const ClientSettings = useContext(ClientSettingsContext);
  const { kovaKeyMap: { numBeds, numBaths, numSqf } } = ClientSettings;

  const specs = {
    numBeds: (modelDetails && modelDetails[numBeds]) || 'N/A',
    numBaths: (modelDetails && modelDetails[numBaths]) || 'N/A',
    numSqf: (modelDetails && modelDetails[numSqf]) || 'N/A',
  };

  const displayTextDict = {
    numBeds: 'bedrooms',
    numBaths: 'bathrooms',
    numSqf: 'square footage',
  };

  return Object.keys(specs).map(key => (
    <ListItem
      key={`${displayTextDict[key]}_${specs[key]}`}
      text={displayTextDict[key]}
      value={specs[key]}
    />
  ));
};

const mapStateToProps = createStructuredSelector({
  modelDetails: selectModelDetails
});

export default connect(mapStateToProps)(HomeSpecs);
