import io from 'socket.io-client';

export default class Socket {
  constructor(config) {
    this.config = config;
    this.socket = null;
  }

  init = (roomId, url) => {    
    this.socket = io(url);

    this.socket.on('connect', () => {
      this.joinRoom(roomId);
    });
  }

  joinRoom = (roomId) => {
    const event = {
      name: 'join room',
      roomId
    };
    this.socket.emit('join room', event);
  }

  emit = (eventName, event) => {
    console.log('event', event);
    
    this.socket.emit(eventName, event);
  }

  listen = () => {
    this.config.listeners.forEach((event) => {
      const { name, eventHandler } = event;
      this.socket.on(name, data => eventHandler(data));
    });
  }
}
