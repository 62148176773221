/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */

import React, { Component } from 'react';
// import configUtils from './selectorUtils/config';

import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { selectConfigData } from '../../redux/model/model.selectors';

// import {  } from '../../redux/colorSelector/colorSelector.actions';
// import {  } from '../../redux/colorSelector/colorSelector.selectors';

class ColorSelector extends Component {
  state = {
    activePositionLeft: '',
    activePositionRight: '',
    activeSequence: 'selections',
    optionsConfig: null,
    splitStageActive: false,
    model: '',
    elevation: '',
    siding: null,
    foundation: null,
    shutters: null,
    door: null,
    garage: null,
  }

  // componentDidMount() {
  //   const { configData } = this.props;
  //   const convertedElevation = this.convertElevation(configData.SelectedOptions['ELEV-ELEV']);

  //   this.setState({
  //     model: configData.Name,
  //     elevation: convertedElevation
  //   });
  // }

  // componentDidUpdate(prevProps, prevState) {
  //   if (prevProps.configData.SelectedOptions['ELEV-ELEV'] !== this.props.configData.SelectedOptions['ELEV-ELEV']) {
  //     this.setState({ elevation: this.convertElevation(this.props.configData.SelectedOptions['ELEV-ELEV']) });
  //   }

  //   if (prevState.elevation !== this.state.elevation) {
  //     const optionsConfig = configUtils.generateOptionsConfig(this.state.model, this.state.elevation);
  //     this.setState({ optionsConfig: {...optionsConfig} });
  //     this.setState(this.setOptionDefaults(optionsConfig));
  //     this.setState({ activeSequence: 'selections' });
  //   }

  //   if (this.state.model && this.state.elevation && !this.state.optionsConfig) {
  //     const optionsConfig = configUtils.generateOptionsConfig(this.state.model, this.state.elevation);
  //     this.setState({ optionsConfig: {...optionsConfig} });
  //     this.setState(this.setOptionDefaults(optionsConfig));
  //   }
  // }

  convertElevation = (elevation) => {
    const dict = {
      'elev_A||': 'elevA',
      'elev_B||': 'elevB',
      'elev_C||': 'elevC',
      'elev_D||': 'elevD',
      'elev_E||': 'elevE',
      'elev_F||': 'elevF',
      'elev_G||': 'elevG',
      'elev_H||': 'elevH',
      'elev_traditional||': 'elevTrad',
      'elev_craftsman||': 'elevCrafts',
      'elev_tudor||': 'elevTudor',
    };

    return dict[elevation];
  }

  //////////////

  handleSequenceChange = (sequenceID) => {
    if (sequenceID === 'selections' && !this.state.activePositionLeft) {
      this.setState({ activePositionLeft: 'left' });
    }
    this.setState(this.setActiveSequence(sequenceID));
  }

  setActiveSequence = (sequenceID) => {
    return function update(state) {
      const { activeSequence } = state;
      let nextSequence;
      activeSequence !== sequenceID || activeSequence === ''
        ? nextSequence = sequenceID
        : nextSequence = '';

      return {
        activeSequence: nextSequence
      };
    };
  }

  /////////////////


  resetOptionsConfig = () => {
    this.setState({ optionsConfig: null });
  }

  resetOptions = () => {
    this.setState({
      siding: null,
      foundation: null,
      shutters: null,
      door: null,
      garage: null
    });
  }

  handleElevationChange = (elevation) => {
    this.setState({ elevation });
  }

  handleOptionChange = (option, selection) => {
    this.setState({ [`${selection}`]: option });
  }

  handleLoadConfiguration = (savedConfig) => {
    this.setState({ savedConfig: { ...savedConfig } });
  }

  ///  FROM STATE CHANGES  ///////

  handleToggleClick = (positionGroup, nextPosition) => {
    const nextState = {};
    positionGroup === 'left'
      ? nextState.activePositionLeft = nextPosition
      : nextState.activePositionRight = nextPosition;
    this.setState(nextState);
    return nextState;
  }

  setOptionDefaults = (optionsConfig) => {
    return {
      siding: optionsConfig.siding ? optionsConfig.siding[0].options[0] : null,
      foundation: optionsConfig.foundation ? optionsConfig.foundation[0].options[0] : null,
      shutters: optionsConfig.shutters ? optionsConfig.shutters[0].options[0] : null,
      door: optionsConfig.door ? optionsConfig.door[0].options[0] : null,
      garage: optionsConfig.garage ? optionsConfig.garage[0].options[0] : null
    };
  }
  //////////////////////////////

  render() {
    const { children } = this.props;

    return (
      <>
        {React.cloneElement(children, {
          ...this.state,
          handleElevationChange: this.handleElevationChange,
          // handleSequenceChange: this.handleSequenceChange,
          handleOptionChange: this.handleOptionChange,
          resetOptions: this.resetOptions,
          resetOptionsConfig: this.resetOptionsConfig
        })}
      </>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  configData: selectConfigData
});

export default connect(mapStateToProps)(ColorSelector);
