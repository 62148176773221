import { createSelector } from 'reselect';
// import memoize from 'lodash.memoize'

export const selectColorSelector = state => state.colorSelector;

export const selectModelName = createSelector(
  [selectColorSelector],
  colorSelector => colorSelector.model
);

export const selectElevation = createSelector(
  [selectColorSelector],
  colorSelector => colorSelector.elevation
);

export const selectOptionsConfig = createSelector(
  [selectColorSelector],
  colorSelector => colorSelector.optionsConfig
);

export const selectOptionDefaults = createSelector(
  [selectColorSelector],
  colorSelector => colorSelector.optionDefaults
);

// export const selectCollections = createSelector(
//   [selectShop],
//   shop => shop.collections
// );

// export const selectCollection = memoize(collectionUrlParam => (
//   createSelector(
//     [selectCollections],
//     collections => collections.find(collection =>
//       collection.id === COLLECTION_ID_MAP[collectionUrlParam])
// )))
