/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React, { Component } from 'react';

import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import SelectionItem from './SelectionItem/SelectionItem';
import OptionItem from './OptionItem/OptionItem';
import PanelList from './PanelList/PanelList';

import selectionsConfig from '../selectorConfig/selections';

import styles from './MenuPanel.module.css';
import modelIcon from '../../../img/model-icon.svg';
import sidingIcon from '../../../img/siding-icon.svg';
import brickIcon from '../../../img/brick-icon.svg';
import roofingIcon from '../../../img/roofing-icon.svg';
import shuttersIcon from '../../../img/shutters-icon.svg';
import garageIcon from '../../../img/garage-icon.svg';
import frontDoorIcon from '../../../img/front-door-icon.png';
import backArrow from '../../../img/back-arrow.svg';

// REDUX BELOW

import {
  selectElevation,
  selectColorSelector,
  selectOptionsConfig
} from '../../../redux/colorSelector/colorSelector.selectors';

import {
  handleSequenceChange,
  handleOptionChange
} from '../../../redux/colorSelector/colorSelector.actions';

// Interface for referencing icons used in SelectionItem components - matches
// the imgkey property of objects within the selectionsConfig array.
const iconDictionary = {
  modelIcon,
  sidingIcon,
  brickIcon,
  roofingIcon,
  shuttersIcon,
  garageIcon,
  frontDoorIcon
};

class MenuPanel extends Component {
  constructor(props) {
    super(props);

    this.state = {
      panelWidth: undefined,
      panelListHeight: undefined,
      panelListChildCount: undefined,
      sidingType: '',
      foundationType: '',
      shuttersType: 'PS',
      doorType: 'DS',
      garageType: 'GS',
      panelListInc: undefined,
      count: 0
    };
  }

  handleBackClick = () => {
    const { activeSequence } = this.props.colorSelector;

    switch (activeSequence) {
      case 'siding-type':
        this.props.handleSequenceChange('selections');
        break;
      case 'siding-option':
        this.setOptionType('', 'siding');
        this.props.handleSequenceChange('siding-type');
        break;
      case 'foundation-type':
        this.props.handleSequenceChange('selections');
        break;
      case 'foundation-option':
        this.setOptionType('', 'foundation');
        this.props.handleSequenceChange('foundation-type');
        break;
      case 'shutters-option':
        this.props.handleSequenceChange('selections');
        break;
      case 'door-option':
        this.props.handleSequenceChange('selections');
        break;
      case 'garage-door-option':
        this.props.handleSequenceChange('selections');
        break;
      default:
        this.props.handleSequenceChange('selections');
        console.error(`${activeSequence} is not currently handled`);
    }
    this.handleResetCount();
  }

  listPanelItems = () => {
    const { activeSequence } = this.props.colorSelector;

    switch (activeSequence) {
      case 'model':
        break;
      case 'elevation':
        break;
      case 'selections':
        return this.listSelectionItems();
      case 'siding-type':
      case 'siding-option':
        return this.listOptionItems('siding');
      case 'foundation-type':
      case 'foundation-option':
        return this.listOptionItems('foundation');
      case 'shutters-type':
      case 'shutters-option':
        return this.listOptionItems('shutters');
      case 'door-type':
      case 'door-option':
        return this.listOptionItems('door');
      case 'garage-door-option':
        return this.listOptionItems('garage');
      default:
        console.error(`${activeSequence} is not currently handled`);
    }
    return false;
  }

  listSelectionItems = () => selectionsConfig.map((item, i) => {
    const { optionsConfig } = this.props.colorSelector;

    if (!optionsConfig) {
      return null;
    } if (i !== 0 && optionsConfig[item.text]) {
      return (
        <SelectionItem
          key={`${i}${item.panelType}`}
          panelType={item.panelType}
          imgSrc={iconDictionary[item.imgKey]}
          text={item.text}
          sequenceID={item.sequenceID}
          handleSetPanelListHeight={this.handleSetPanelListHeight}
          enabled={item.enabled}
        />
      );
    }
    return null;
  })

  listOptionItems = (selection) => {
    const { optionsConfig, activeSequence } = this.props.colorSelector;

    const optionGroup = optionsConfig[selection]
      && optionsConfig[selection].find(
        group => group.type === this.state[`${selection}Type`]
      );

    return activeSequence.split('-')[1] === 'type' ? (
      optionsConfig[selection].map((item, i) => (
        <OptionItem
          key={`${i}${item.type}`}
          option={item}
          selector="type"
          selection={selection}
          handleClick={this.handleOptionTypeClick}
          handleSetPanelListHeight={this.handleSetPanelListHeight}
        />
      ))
    ) : (
      optionGroup && optionGroup.options.map((item, i) => (
        <OptionItem
          key={`${i}${item.text}`}
          option={item}
          selector="scheme"
          selection={selection}
          handleClick={this.props.handleOptionChange}
          handleSetPanelListHeight={this.handleSetPanelListHeight}
        />
      ))
    );
  }

  handleOptionTypeClick = (optionType, selection) => {
    this.setOptionType(optionType, selection);
    this.props.handleSequenceChange(`${selection}-option`);
    this.handleResetCount();
  }

  setOptionType = (optionType, selection) => {
    const newState = {};
    newState[`${selection}Type`] = optionType;
    this.setState(newState);
  }

  handleResetCount = () => {
    this.setState({ count: 0 });
  }

  handleDeleteClick = () => {
    const deleteEvent = new CustomEvent('deleteEvent');
    window.dispatchEvent(deleteEvent);
  }

  handleSplitClick = () => {
    if (this.props.selectedConfigKeys.length >= 2) {
      this.props.handleToggleSplitStage();
    }
  }

  render() {
    const { activeSequence } = this.props.colorSelector;

    return (
      <div className={styles.menuPanel}>
        {activeSequence !== 'selections'
          && (
            <div
              className={styles.backButton}
              onClick={() => this.handleBackClick()}
              role="button"
              tabIndex="0"
            >
              <img
                className={styles.backIcon}
                src={backArrow}
                alt="home"
              />
              <span>Back</span>
            </div>
          )}
        <PanelList
          count={this.state.count}
          listPanelItems={this.listPanelItems}
          position={this.props.position}
        />
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  elevation: selectElevation,
  colorSelector: selectColorSelector,
  optionsConfig: selectOptionsConfig
});

const mapDispatchToProps = dispatch => ({
  handleSequenceChange: sequenceID => dispatch(handleSequenceChange(sequenceID)),
  handleOptionChange: (option, selection) => dispatch(handleOptionChange(option, selection)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MenuPanel);
