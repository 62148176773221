import React from 'react';
import PropTypes from 'prop-types';
import styles from './LoadingIndicator.module.css';

const LoadingIndicator = ({ message }) => (
  <div className={styles.loadingIndicator}>
    <div />
    <div />
    <div />
    <div />
    {message && (
      <span>{message}</span>
    )}
  </div>
);

LoadingIndicator.defaultProps = {
  message: ''
};

LoadingIndicator.propTypes = {
  message: PropTypes.string
};

export default LoadingIndicator;
