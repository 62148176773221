import { createSelector } from 'reselect';

export const selectModel = state => state.model;

export const selectConfigData = createSelector(
  [selectModel],
  model => model.configData
);

export const selectModelRules = createSelector(
  [selectModel],
  model => model.rules
);

export const selectFloor = createSelector(
  [selectModel],
  model => model.selectedFloor
);

export const selectModelRID = createSelector(
  [selectConfigData],
  configData => configData.ModelRID
);

export const selectModelName = createSelector(
  [selectConfigData],
  configData => configData.MarketingName
);

export const selectModelOptions = createSelector(
  [selectConfigData],
  configData => configData.Options
);

export const selectModelDetails = createSelector(
  [selectModel],
  model => model.modelDetails
);

export const selectShowElevation = createSelector(
  [selectModel],
  model => model.showElevation
);

export const selectAvailFloors = createSelector(
  [selectModel],
  model => model.availFloors
);

export const selectBasePrice = createSelector(
  [selectModel],
  model => model.basePrice
);

export const selectOptionTotal = createSelector(
  [selectModel],
  model => model.optionTotal
);

export const selectedOptions = createSelector(
  [selectModel],
  model => model.selectedOpts
);

export const selectPriceList = createSelector(
  [selectModel],
  model => model.priceList
);

export const selectKeyMap = createSelector(
  [selectModel],
  model => model.keyMap
);
