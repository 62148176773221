const compare = (a, b) => {
  // Use toUpperCase() to ignore character casing
  const commA = a.name.toUpperCase();
  const commB = b.name.toUpperCase();

  let comparison = 0;
  if (commA > commB) {
    comparison = 1;
  } else if (commA < commB) {
    comparison = -1;
  }
  return comparison;
};

const orderCommunityDirectory = (selectedRID, directory) => {
  const directoryDup = directory.slice();
  const commIdx = directoryDup.findIndex(comm => comm.rid === selectedRID);
  const foundComm = directoryDup.splice(commIdx, 1);
  directoryDup.sort(compare);
  directoryDup.unshift(foundComm[0]);

  return directoryDup;
};

export default orderCommunityDirectory;
