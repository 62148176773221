/* eslint-disable no-case-declarations */
import ModelActionTypes from '../model/model.types';
import ColorSelectorActionTypes from './colorSelector.types';

import {
  generateOptionsConfig,
  setOptionDefaults,
  handleSequenceChange,
  setElevationKey,
  editModelNamesWithPeriods
} from './colorSelector.utils';
import GlobalConfig from '../../utils/GlobalConfig';

const CONVERT_ELEVATIONS = {
  'elev_a||': 'elevA',
  'elev_b||': 'elevB',
  'elev_c||': 'elevC',
  'elev_d||': 'elevD',
  'elev_e||': 'elevE',
  'elev_f||': 'elevF',
  'elev_g||': 'elevG',
  'elev_h||': 'elevH',
  'elev_traditional||': 'elevTrad',
  'elev_craftsman||': 'elevCrafts',
  'elev_tudor||': 'elevTudor',
  'elv-a||': 'elevA',
  'elv-b||': 'elevB',
  'elv-c||': 'elevC',
  'elv-d||': 'elevD',
  'elv-e||': 'elevE',
  'elv-f||': 'elevF',
  'elv-g||': 'elevG',
  'elv-h||': 'elevH',
  'elv-traditional||': 'elevTrad',
  'elv-craftsman||': 'elevCrafts',
  'elv-tudor||': 'elevTudor',
};

const INITIAL_STATE = {
  activePositionLeft: '',
  activePositionRight: '',
  activeSequence: 'selections',
  model: '',
  elevation: '',
  optionsConfig: null,
  optionDefaults: null
};

const colorSelectorReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ColorSelectorActionTypes.HANDLE_SEQUENCE_CHANGE:
      const result = handleSequenceChange(action.payload, state);

      return {
        ...state,
        ...result
      };
    case ColorSelectorActionTypes.HANDLE_OPTION_CHANGE:
      const optChangeResult = {
        [action.payload.selection]: action.payload.option
      };
      const optionDefaultsClone = Object.assign({}, state.optionDefaults);
      const updatedOptionSelections = Object.assign(
        optionDefaultsClone, optChangeResult
      );

      return {
        ...state,
        optionDefaults: { ...updatedOptionSelections }
      };
    case ModelActionTypes.SET_CONFIG_DATA: {
      const elevationKey = setElevationKey(action.payload.SelectedOptions);

      if (GlobalConfig.get(GlobalConfig.Key.CLIENT_NAME) !== 'arbor'
          && GlobalConfig.get(GlobalConfig.Key.CLIENT_NAME) !== 'silverthorne'
      ) return state;

      const model = editModelNamesWithPeriods(action.payload.Name)
      const elevation = CONVERT_ELEVATIONS[action.payload.SelectedOptions[elevationKey].toLowerCase()];

      let optionsConfig;
      let optionDefaults;

      // Only needs to be run once upon initial load - otherwise
      // you will receive a multiple dispatch warning
      if (!state.optionsConfig || (state.elevation !== elevation)) {
        optionsConfig = generateOptionsConfig(model, elevation);
        optionDefaults = setOptionDefaults(optionsConfig);

        return {
          ...state,
          model,
          elevation,
          optionsConfig,
          optionDefaults,
        };
      }
      return {
        ...state,
        model,
        elevation
      };
    }
    default:
      return state;
  }
};

export default colorSelectorReducer;
