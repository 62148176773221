import { AppActionTypes } from './app.types';

const INITIAL_STATE = {
  showKovaStage: null,
  showSideMenu: true,
  resetClicked: true,
  triggerPrint: false,
  downloadURL: null,
};

const appReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case AppActionTypes.SET_SHOW_KOVA_STAGE:
      return {
        ...state,
        showKovaStage: action.payload
      };
    case AppActionTypes.TOGGLE_SIDE_MENU:
      return {
        ...state,
        showSideMenu: !state.showSideMenu
      };
    case AppActionTypes.TOGGLE_RESET_VIEW:
      return {
        ...state,
        resetClicked: !state.resetClicked
      };
    case AppActionTypes.TOGGLE_TRIGGER_PRINT:
      return {
        ...state,
        triggerPrint: !state.triggerPrint
      };
    case AppActionTypes.SET_DOWNLOAD_URL:
      return {
        ...state,
        downloadURL: action.payload
      };
    default:
      return state;
  }
};

export default appReducer;
