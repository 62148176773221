import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import Button from '../Button/Button';

import matterportLinks from '../../../config/matterportLinks';

import { selectModelDetails } from '../../../redux/model/model.selectors';

import {
  toggleTriggerPrint
} from '../../../redux/app/app.actions';

const MenuButtons = ({
  modelDetails,
  handleFloorToggle,
  toggleForm,
  // toggleTriggerPrint
}) => {
  const handlePrintClick = (e) => {
    e.preventDefault();
    handleFloorToggle({});
    // comment out toggleForm if just testing pdf print
    toggleForm();
    // uncomment toggleTriggerPrint to test printing without sending to Hubspot
    // toggleTriggerPrint();
  };

  const matterPortTour = (e) => {
    e.preventDefault();
    const url = matterportLinks[modelDetails.ModelRID];

    window.open(url);
  };

  const matterportUrl = (modelDetails && matterportLinks[modelDetails.ModelRID]) || null;

  const buttonDict = {
    download: {
      text: 'download my floor plan',
      clickHandler: handlePrintClick,
      hasUrl: false
    },
    matterport: {
      text: 'virtual tour',
      clickHandler: matterPortTour,
      hasUrl: matterportUrl
    }
  };

  return Object.keys(buttonDict).map(key => (
    <Button
      key={`${buttonDict[key].text}`}
      text={buttonDict[key].text}
      clickHandler={buttonDict[key].clickHandler}
      hasUrl={buttonDict[key].hasUrl}
    />
  ));
};

const mapDispatchToProps = dispatch => ({
  toggleTriggerPrint: () => dispatch(toggleTriggerPrint()),
});

const mapStateToProps = createStructuredSelector({
  modelDetails: selectModelDetails
});

export default connect(mapStateToProps, mapDispatchToProps)(MenuButtons);
