import ModelActionTypes from './model.types';
import Api from '../../utils/Api/Api';

export const setModelRID = model => ({
  type: 'SET_MODEL_RID',
  payload: model
});

export const setConfigData = data => ({
  type: ModelActionTypes.SET_CONFIG_DATA,
  payload: data
});


export const setModelRules = rules => ({
  type: ModelActionTypes.SET_MODEL_RULES,
  payload: rules
});

export const setSelectedFloor = floor => ({
  type: ModelActionTypes.SET_SELECTED_FLOOR,
  payload: floor
});

export const setModelDetails = (communityRID, modelRID) => dispatch =>
  Api.fetchCommunityModels(communityRID).then(data =>
    dispatch({
      type: ModelActionTypes.SET_MODEL_DETAILS,
      payload: data.find(model => model.ModelRID === modelRID)
    }));

export const setPriceList = (communityRID, modelRID) => dispatch =>
  Api.fetchStandardModelOpts(communityRID, modelRID, -1).then(data =>
    dispatch({
      type: ModelActionTypes.SET_PRICE_LIST,
      payload: data
    }));
