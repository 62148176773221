/* eslint-disable react/prop-types */

import React from 'react';

const RightSideMenu = ({
  children
}) => (
  <>
    {children}
  </>
);

export default RightSideMenu;
