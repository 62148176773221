import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import styles from './MenuTab.module.css';


const MenuTab = ({
  clientFloorOnAppLoad,
  type,
  text,
  handleFloorToggle
}) => {
  const isElevation = useSelector(state => state.model.showElevation);
  const model = useSelector(state => state.model);
  let floorOnAppLoad;

  if (model.rules) {
    ({ floorOnAppLoad } = model.rules);
  }
  const defaultFloor = floorOnAppLoad || clientFloorOnAppLoad;
  const isSelected = (type === 'interior' && !isElevation) || (type === 'exterior' && isElevation);
  const floorToLoad = type === 'interior' ? defaultFloor : 0;

  return (
    <div
      className={isSelected
        ? styles.selectedTab
        : styles.menuTab
      }
      onClick={() => handleFloorToggle({ floorToLoad })}
      role="button"
      tabIndex="0"
    >
      {text}
    </div>
  );
};

MenuTab.defaultProps = {
  clientFloorOnAppLoad: null
};

MenuTab.propTypes = {
  clientFloorOnAppLoad: PropTypes.number,
  type: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  handleFloorToggle: PropTypes.func.isRequired,
};

export default MenuTab;
