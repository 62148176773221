import React from 'react';

import { connect } from 'react-redux';
import { selectModelName } from '../../redux/colorSelector/colorSelector.selectors';
import { createStructuredSelector } from 'reselect';

import styles from './ModelName.module.css';

const ModelName = ({
  modelName
}) => (
  <div className={styles.modelName}>
    {modelName}
  </div>
)

const mapStateToProps = createStructuredSelector({
  modelName: selectModelName
})

export default connect(mapStateToProps)(ModelName);
