import React from 'react';
import PropTypes from 'prop-types';
import ToggleSwitch from '../ToggleSwitch/ToggleSwitch';
import whiteArrow from '../../img/whiteArrow.svg';
import styles from './TouchscreenToggleBar.module.css';

const TouchscreenToggleBar = ({
  menuOpen,
  clickHandler,
  tabText
}) => (
  <div
    className={menuOpen ? styles.menuTab : styles.closedMenuTab}
    onClick={clickHandler}
    role="button"
    tabIndex="0"
  >
    <img
      className={menuOpen ? styles.openArrow : styles.closedArrow}
      src={whiteArrow}
      alt="menu arrow"
    />
    <div className={styles.tabText}>
      {tabText}
    </div>
    <div className={styles.headingWrapper}>
      <div className={styles.headingDivider}>||</div>
      <ToggleSwitch
        active={menuOpen}
        clickHandler={clickHandler}
      />
    </div>
  </div>
);

TouchscreenToggleBar.propTypes = {
  menuOpen: PropTypes.bool.isRequired,
  clickHandler: PropTypes.func.isRequired,
  tabText: PropTypes.string.isRequired
};

export default TouchscreenToggleBar;
