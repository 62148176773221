import CommunityActionTypes from './community.types';
import Api from '../../utils/Api/Api';

export const setCommunityRID = community => ({
  type: CommunityActionTypes.SET_COMMUNITY_RID,
  payload: community
});

export const setCommunityDirectory = directory => ({
  type: CommunityActionTypes.SET_COMMUNITY_DIRECTORY,
  payload: directory
});

export const setDropdownCommRID = commRID => ({
  type: CommunityActionTypes.SET_DROPDOWN_COMM_RID,
  payload: commRID
});

export const setCommunityModelList = communityRID =>
  dispatch =>
    Api.fetchCommunityModels(communityRID).then(data =>
      dispatch({
        type: CommunityActionTypes.SET_COMMUNITY_MODEL_LIST,
        payload: data
      }));

export const setCommunityDetails = (communityRID = 278) =>
  dispatch =>
    Api.fetchCommunityDetails(communityRID).then(data =>
      dispatch({
        type: CommunityActionTypes.SET_COMMUNITY_DETAILS,
        payload: data
      }));
