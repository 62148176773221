/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import KovaData from '../../../utils/KovaData/KovaData';
import { ClientSettingsContext } from '../../../context/ClientSettingsContext';
import styles from './SelectionItem.module.css';

const SelectionItem = ({
  option,
  handleOptionItemClick,
  optionCategory,
  choiceType,
  quickMoveIn
}) => {
  const ClientSettings = useContext(ClientSettingsContext);
  const {
    kovaKeyMap: { optionText },
    showPriceOnMenu
  } = ClientSettings;

  // optionCategory for MultiChoice - option.ID for Single Choice
  const parsedOptCategory = optionCategory || option.ID;
  const selected = option.Selected;
  const clickType = 'optionChange';

  const disableOption = () => !option.Enabled || quickMoveIn;
  const intOptionPrice = KovaData.formatPriceAsNum(option.Price);
  const stringPrice = KovaData.formatNumberWithComma(intOptionPrice);

  return (
    <div
      className={`${styles.selectionItem} ${disableOption() ? styles.disabled : null}`}
      onClick={event => handleOptionItemClick(
        event,
        {
          clickType,
          option: option.ID,
          categoryId: parsedOptCategory,
          choiceType
        }
      )}
      role="button"
      tabIndex={0}
    >
      {showPriceOnMenu
        ? (
          <div className={styles.optionPrice}>
            {`$${stringPrice}`}
          </div>
        )
        : null}
      <div className={styles.optionName}>
        {option[optionText]}
      </div>
      <div className={styles.buttonContainer}>
        <label className={styles.switch}>
          <input
            className={styles.switchInput}
            type="checkbox"
            name={option.ID}
            checked={selected}
            readOnly
          />
          <span className={styles.slider} />
        </label>
      </div>
    </div>
  );
};

SelectionItem.propTypes = {
  option: PropTypes.object.isRequired,
  optionCategory: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
  ]).isRequired,
  choiceType: PropTypes.string.isRequired,
  handleOptionItemClick: PropTypes.func.isRequired,
  quickMoveIn: PropTypes.bool.isRequired,
};

export default SelectionItem;
