import { createSelector } from 'reselect';

const selectApp = state => state.app;

export const selectShowKovaStage = createSelector(
  [selectApp],
  app => app.showKovaStage
)

export const selectResetClicked = createSelector(
  [selectApp],
  app => app.resetClicked
)

export const selectTriggerPrint = createSelector(
  [selectApp],
  app => app.triggerPrint
);

export const selectDownloadURL = createSelector(
  [selectApp],
  app => app.downloadURL
);
