const shuttersConfig = [
  {
    type: 'PS',
    sequenceID: 'shutters-option',
    text: {
      main: 'Shutters',
      detail: ''
    }
  }
];

export default shuttersConfig;
