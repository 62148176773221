// This file provides the configuration data for selection options. It's used
// within the MenuPanel component.

const selectionsConfig = [
  {
    imgKey: 'modelIcon',
    sequenceID: 'model',
    text: 'model',
    enabled: true
  },
  {
    imgKey: 'sidingIcon',
    sequenceID: 'siding-type',
    text: 'siding',
    enabled: true
  },
  {
    imgKey: 'brickIcon',
    sequenceID: 'foundation-type',
    text: 'foundation',
    enabled: true
  },
  {
    imgKey: 'roofingIcon',
    sequenceID: 'trim-type',
    text: 'trim',
    enabled: false
  },
  {
    imgKey: 'shuttersIcon',
    sequenceID: 'shutters-option',
    text: 'shutters',
    enabled: true
  },
  {
    imgKey: 'frontDoorIcon',
    sequenceID: 'door-option',
    text: 'door',
    enabled: true
  },
  {
    imgKey: 'garageIcon',
    sequenceID: 'garage-door-option',
    text: 'garage',
    enabled: true
  }
];

export default selectionsConfig;
