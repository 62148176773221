/**
 * NOTE: If a model uses multiCategory ruleType then you must manually
 * add the individual options into optionsWhitelist for the necesessary floors.
 */

const matterportLinks = {
  864: 'https://my.matterport.com/show/?m=gVy7cimesV2',
  867: 'https://my.matterport.com/show/?m=L8e1kr4H4eb',
  868: 'https://my.matterport.com/show/?m=gi3EhUwj2xG',
  869: 'https://my.matterport.com/show/?m=awJ8v5ZgaR4',
  870: null,
  872: 'https://my.matterport.com/show/?m=S9CXFJGCeCr',
  873: 'https://my.matterport.com/show/?m=Ufjy1cp1Ni3',
  874: 'https://my.matterport.com/show/?m=2ZXpWT3NSRs',
  875: 'https://my.matterport.com/show/?m=SRhFNo6Kkas',
  877: 'https://my.matterport.com/show/?m=ZR8XuWDXE5x',
  880: 'https://my.matterport.com/show/?m=2NPw4F97xmi',
  947: 'https://my.matterport.com/show/?m=BZuUg22ws7W',
  948: 'https://my.matterport.com/show/?m=2WXuL8NTmSe',
  950: 'https://my.matterport.com/show/?m=uj1qdXHMniH',
  956: 'https://my.matterport.com/show/?m=S4V1n8U4CMH',
  966: 'https://my.matterport.com/show/?m=uTQMASawa9d',
  967: 'https://my.matterport.com/show/?m=oo9aotyv2sR',
  7448: 'https://my.matterport.com/show/?m=wNrkmSuGdfa',
  940: null,

};

export default matterportLinks;
