import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import styles from './ElevationOption.module.css';

import {
  selectModelRID,
  selectConfigData,
  selectModelOptions,
  selectKeyMap
} from '../../redux/model/model.selectors';

import { ClientSettingsContext } from '../../context/ClientSettingsContext';
import GlobalConfig from '../../utils/GlobalConfig';
import colorSelector from "../../components/ColorSelector/ColorSelector";
import {calculateImageName} from "../../utils/FloorplanBuilder/FloorplanBuilder";
import {selectCommunityRID} from "../../redux/community/community.selectors";

const ElevationOption = ({
  elev,
  modelRID,
  communityRID,
  handleOptionItemClick,
  keyMap,
  filteredOptImgs
}) => {
  const ClientSettings = useContext(ClientSettingsContext);
  const { defaultCommunityRID, colorSelectorEnabled } = ClientSettings;
  const elevId = elev.ID.split('|')[0].toLowerCase();
  let src = "";
  if(colorSelectorEnabled){
      src = `https://s3-${GlobalConfig.get(GlobalConfig.Key.S3_REGION)}.amazonaws.com/${GlobalConfig.get(GlobalConfig.Key.S3_BUCKET)}/${defaultCommunityRID}/${modelRID}/img/${elevId}.jpg`
  }
  else if(filteredOptImgs && filteredOptImgs.length > 0){

      let optCfgImgPath = `https://s3-${GlobalConfig.get(GlobalConfig.Key.S3_REGION)}.amazonaws.com/${GlobalConfig.get(GlobalConfig.Key.S3_KOVA_IFP)}/OptCfgImgs/${modelRID}/OptCfgImgs/`
      const images = filteredOptImgs[0];

      let mockSelectedOpts = [elevId];
      images.forEach((optImage) => {
          const srcImg = calculateImageName(optImage, optCfgImgPath, mockSelectedOpts);
          if (srcImg) src = srcImg;
      });

  }
  return (
    <div className={styles.elevationOption} key={elev.displayText} aria-hidden>
      <div className={`${styles.overlayContainer} ${elev.Selected ? styles.boxSelected : ''}`}>
        <div className={styles.elevImage}>
          <img
            onClick={event => handleOptionItemClick(
              event,
              {
                clickType: 'optionChange',
                option: elev.ID,
                categoryId: keyMap.elevation,
                choiceType: 'MultiChoice'
              }
            )}
            src={src}
            alt="elevation"
            aria-hidden
          />
        </div>
      </div>
      <div className={styles.textDisplay}>
        { elev.Text }
      </div>
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  configData: selectConfigData,
  modelRID: selectModelRID,
  communityRID: selectCommunityRID,
  modelOptions: selectModelOptions,
  keyMap: selectKeyMap
});

ElevationOption.propTypes = {
  modelRID: PropTypes.number.isRequired,
  communityRID: PropTypes.number.isRequired,
  elev: PropTypes.object.isRequired,
  keyMap: PropTypes.object.isRequired,
  handleOptionItemClick: PropTypes.func.isRequired,
};

export default connect(mapStateToProps)(ElevationOption);
