/* eslint-disable no-case-declarations */
import ModelActionTypes from './model.types';

import {
  formatPriceAsNum,
  generatePriceList,
  findAllSelectedOptions,
  findTotalOfSelectedOptions,
  parseValidFloors,
  generateKeyMap
} from './model.utils';

const INITIAL_STATE = {
  modelRID: null,
  configData: null,
  rules: null,
  selectedFloor: null,
  modelDetails: null,
  availFloors: null,
  basePrice: 0,
  priceList: null,
  selectedOpts: null,
  optionTotal: null,
  showElevation: false,
  keyMap: null
};

const modelReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ModelActionTypes.SET_MODEL_RID:
      return {
        ...state,
        modelRID: action.payload
      };
    case ModelActionTypes.SET_CONFIG_DATA:
      if (!action.payload) return state;

      const availFloors = parseValidFloors(action.payload.Floors);
      const keyMap = generateKeyMap(action.payload);

      let data = {
        availFloors,
        keyMap,
        configData: action.payload,
        selectedOpts: findAllSelectedOptions(action.payload.Options),
        optionTotal: state.priceList
          ? findTotalOfSelectedOptions(
            action.payload.SelectedOptions, state.priceList
          )
          : 0
      };

      if (!state.availFloors) {
        const showElevation = availFloors[state.selectedFloor].Description === 'Elevation' || false;
        data = Object.assign(data, { showElevation });
      }

      return {
        ...state,
        ...data
      };

    case ModelActionTypes.SET_PRICE_LIST:
      const priceList = generatePriceList(action.payload.Options, 'price');
      return {
        ...state,
        priceList
      };
    case ModelActionTypes.SET_MODEL_RULES:
      return {
        ...state,
        rules: action.payload
      };
    case ModelActionTypes.SET_SELECTED_FLOOR:
      const showElevation = (action.payload === 0 || action.payload) && state.availFloors
        ? state.availFloors[action.payload].Description === 'Elevation'
        : false;
      return {
        ...state,
        selectedFloor: action.payload,
        showElevation
      };
    case ModelActionTypes.SET_MODEL_DETAILS:
      return {
        ...state,
        modelDetails: action.payload,
        basePrice: action.payload ? formatPriceAsNum(action.payload.BasePrice) : 0
      };
    default:
      return state;
  }
};

export default modelReducer;
