import React from 'react';
import './OptionItem.css';
import sidingIcon from '../../../../img/siding-icon.svg';
import foundationIcon from '../../../../img/brick-icon.svg';
import GlobalConfig from '../../../../utils/GlobalConfig';

const OptionItem = ({
  option,
  selector,
  selection,
  handleClick,
  handleSetPanelListHeight
}) => {
  const imgSrcBase = GlobalConfig.get(GlobalConfig.Key.COLOR_SELECTOR_URL);
  const imgSrcTail = `Swatches/${option.type}/${option.type}_${option.frame}.png`;
  const imgSrc = `${imgSrcBase}${imgSrcTail}`;

  return selector === 'type' ? (
    <div
      className='OptionItem'
      onClick={() => handleClick(option.type, selection)}
      role="button"
      tabIndex="0"
    >
      <img
        src={selection === 'foundation' ? foundationIcon : sidingIcon}
        alt={selection}
        onLoad={handleSetPanelListHeight}
      />
      <span>
        <div>{option.text.main}</div>
        <div>{option.text.detail}</div>
      </span>
    </div>
  ) : (
    <div
      className='OptionItem border'
      onClick={() => handleClick(option, selection)}
      role="button"
      tabIndex="0"
    >
      <img
        src={imgSrc}
        alt={selection}
        onLoad={handleSetPanelListHeight}
      />
      <span>{option.text}</span>
    </div>
  );
}

export default OptionItem;
