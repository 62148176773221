import React from 'react';
import PropTypes from 'prop-types';
import styles from './ViewToggleButton.module.css';

const ViewToggleButton = ({
  handleFloorToggle,
  direction,
  arrowImg,
  touchscreenEnabled
}) => (
  <div
    className={
      direction === 'left'
        ? styles.viewToggleButton
        : styles.viewToggleButtonRight
    }
    onClick={() => handleFloorToggle({ direction })}
    role="button"
    tabIndex={0}
    style={{
      backgroundImage: `url(./images/${arrowImg})`,
      height: `${touchscreenEnabled ? '50px' : '25px'}`,
      width: `${touchscreenEnabled ? '50px' : '25px'}`
    }}
  />
);

ViewToggleButton.propTypes = {
  handleFloorToggle: PropTypes.func.isRequired,
  direction: PropTypes.string.isRequired,
  arrowImg: PropTypes.string.isRequired,
  touchscreenEnabled: PropTypes.bool.isRequired,
};

export default ViewToggleButton;
