import React from 'react';
import { ClientSettingsConsumer } from '../../context/ClientSettingsContext';
import styles from './Footer.module.css';

const Footer = () => {
  const disclaimer = "Prices, plans, and terms are effective on the date of publication and subject to change without notice. Square footage shown is only an estimate and actual square footage will differ. Depictions of homes or other features are artist conceptions. Hardscape, landscape, and other items shown may be decorator suggestions that are not included in the purchase price and availability may vary. Plans to build out this neighborhood as proposed are subject to change without notice. 3D floor plan rendering is for illustrative purposes only and represents a concept of a standard model of this home and may not represent the home that would be available for purchase at the purchase price shown. The rendering may represent the base model of this home without any options or upgrades, or may depict options, upgrades or features that are available at an additional cost. Furniture placement and flooring is artist's conception and is not intended to show specific detailing. Floor plans are the property of Arbor Homes.";

  return (
    <ClientSettingsConsumer>
      {({ theme }) => (
        <div className={styles.footer}>
          <div className={styles.disclaimerText}>{disclaimer}</div>
          <div
            style={
              { backgroundColor: theme.logoColor || theme.primaryColor }
            }
            className={styles.brandLogo}
          >
            <img
              className={styles.footerImage}
              src={`./logo/${theme.logo}`}
              alt="logo"
            />
          </div>
        </div>
      )}
    </ClientSettingsConsumer>
  );
};

export default Footer;
