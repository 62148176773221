import { createContext } from 'react';

export const theme = {
  touchscreen: {
  },
  web: {
  }
};

const ThemeContext = createContext({});

export const ThemeProvider = ThemeContext.Provider;
export const ThemeConsumer = ThemeContext.Consumer;
