import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import './SelectionItem.css';

const SelectionItem = ({
  imgSrc,
  text,
  sequenceID,
  enabled,
  handleSetPanelListHeight
}) => {
  const dispatch = useDispatch();
  const handleSequenceChange = useCallback(
    sequenceID => dispatch({
      type: 'HANDLE_SEQUENCE_CHANGE',
      payload: sequenceID
    }),
    [dispatch]
  );

  return (
    <div
      className={`SelectionItem box-item ${enabled ? 'enabled' : ''}`}
      onClick={() => handleSequenceChange(sequenceID)}
      role="button"
      tabIndex="0"
    >
      <img src={imgSrc} alt={sequenceID} onLoad={handleSetPanelListHeight} />
      <span>{text === 'foundation' ? 'brick' : text}</span>
    </div>
  );
};

SelectionItem.propTypes = {
  imgSrc: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  sequenceID: PropTypes.string.isRequired,
  enabled: PropTypes.bool.isRequired,
  handleSetPanelListHeight: PropTypes.func.isRequired,
};


export default SelectionItem;
