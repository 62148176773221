import { createSelector } from 'reselect';

const selectCommunity = state => state.community;

export const selectCommunityRID = createSelector(
  [selectCommunity],
  community => community.communityRID
)

export const selectDirectory = createSelector(
  [selectCommunity],
  community => community.directory
)

export const selectCommunityDetails = createSelector(
  [selectCommunity],
  community => community.details
)

export const selectCommunityModelList = createSelector(
  [selectCommunity],
  community => community.modelList
)

export const selectDropdownCommunityRID = createSelector(
  [selectCommunity],
  community => community.dropdownCommunityRID
)
