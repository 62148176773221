/* eslint-disable array-callback-return */
// TODO CHECK LINTER RULES:
/* eslint-disable consistent-return */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
class CustomRules {
  // Checks to see if ALL hrefs from Custom Rules are in reference list
  static onlyAnd(hrefsFromCustomRule, originalPermittedOpts) {
    return hrefsFromCustomRule.every(opt =>
      originalPermittedOpts.includes(opt));
  }

  // Checks to see if NO hrefs from Custom Rules are in reference list
  static onlyAndNot(hrefsFromCustomRule, originalPermittedOpts) {
    return hrefsFromCustomRule.every(opt =>
      !originalPermittedOpts.includes(opt));
  }

  // Checks to see if NO hrefs from Custom Rules are in reference list
  static onlyNot(hrefsFromCustomRule, originalPermittedOpts) {
    return hrefsFromCustomRule.every(opt =>
      !originalPermittedOpts.includes(opt));
  }

  // Checks to see if any hrefs are found in the original list
  static onlyOr(hrefsFromCustomRule, originalPermittedOpts) {
    return hrefsFromCustomRule.some(opt =>
      this.isItemInArray(opt, originalPermittedOpts));
  }

  // Checks to see if first href is in reference list but second option is not
  static andAndNot(hrefsFromCustomRule, originalPermittedOpts) {
    return originalPermittedOpts.includes(hrefsFromCustomRule[0])
      && this.onlyAndNot(hrefsFromCustomRule.slice(1), originalPermittedOpts);
  }

  // Checks to see if all hrefs are found in the original list
  static onlyJoin(hrefsFromCustomRule, originalPermittedOpts) {
    return hrefsFromCustomRule.every(opt =>
      this.isItemInArray(opt, originalPermittedOpts));
  }

  static isItemInArray(item, arrayToSearch) {
    return arrayToSearch.includes(item);
  }

  // Return specific option name that is found in an array
  static findItemInArray(hrefsFromCustomRule, originalPermittedOpts) {
    return hrefsFromCustomRule.find(opt =>
      this.isItemInArray(opt, originalPermittedOpts));
  }

  // EDITED TWO FUNCTIONS BELOW!
  static replaceWithAltHref(optToReplace, altHref, permittedOpts) {
    let permittedOptsCopy = permittedOpts.slice();

    permittedOptsCopy = permittedOptsCopy.filter(opt => opt !== optToReplace);

    permittedOptsCopy.push(altHref);

    return permittedOptsCopy;
  }

  static replaceWithAltHrefCategory(optToReplace, altHref, permittedOpts) {
    let permittedOptsCopy = permittedOpts.slice();
    permittedOptsCopy = permittedOptsCopy.filter(opt =>
      !optToReplace.includes(opt));
    permittedOptsCopy.push(altHref);
    return permittedOptsCopy;
  }

  // Filter multiGroups with empty arrays
  static filterMultiGroup(multiGroups) {
    // TODO LINTER: eslint-disable-next-line array-callback-return
    return Object.keys(multiGroups).reduce((accum, currVal) => {
      if (multiGroups[currVal].length > 0) {
        // eslint-disable-next-line no-param-reassign
        accum[currVal] = multiGroups[currVal];
        return accum;
      }
    }, {});
  }

  // Checks to see if each multiGroup satisfies its condition
  // i.e. all 'and' options or at least one 'or' option are in reference list
  static checkAllMultiConditionOptions(multiGroups, groupToReplace, refList) {
    // Filter out multiGroups with empty arrays
    const filteredGroups = this.filterMultiGroup(multiGroups);

    for (const group in filteredGroups) {
      if (group === 'multiOr') {
        if (!this.onlyOr(filteredGroups[group], refList)) return false;
      }
      if (group === 'multiJoin') {
        if (!this.onlyJoin(filteredGroups[group], refList)) return false;
      }
      if (group === 'multiAnd') {
        if (!this.onlyAnd(filteredGroups[group], refList)) return false;
      }
      if (group === 'multiNot') {
        if (!this.onlyNot(filteredGroups[group], refList)) return false;
      }
      if (group === 'multiAndNot' && groupToReplace === 'multiAndNot') {
        if (!this.andAndNot(filteredGroups[group], refList)) return false;
      } else if (group === 'multiAndNot' && groupToReplace !== 'multiAndNot') {
        if (!this.onlyAndNot(filteredGroups[group], refList)) return false;
      }
    }
    return true;
  }

  // **************** CATEGORY CONDITIONAL METHODS ********************* //

  // Return options that fall within category defined in fakeConfigService
  static findOptionsInCategory(categoryName, categoryList) {
    return categoryList[categoryName];
  }

  // Returns specific category option in the referenceList (selected by users)
  static findMatchingOptions(optionsArray, originalPermittedOpts) {
    return optionsArray.find(opt =>
      this.isItemInArray(opt, originalPermittedOpts));
  }

  // Checks to see if NO hrefs from Custom Rules are in reference list
  static categoryOnlyAndNot(arrayOfCategoryOpts, originalPermittedOpts) {
    return this.onlyOr(arrayOfCategoryOpts[0], originalPermittedOpts)
      && this.onlyAndNot(arrayOfCategoryOpts[1], originalPermittedOpts);
  }

  // TODO: Once confirmed working - Need to DRY up code
  static checkMultiConditionCategories(multiGroups, categoryList, refList) {
    for (const group in multiGroups) {
      const optionGroups = [];

      // For 'multiAnd' multiGroup
      if (group === 'multiAnd') {
      /**
      * For each category in array - find and store the individual item
      * arrays in optionGroups
      */
        multiGroups[group].forEach((categoryName) => {
          const opts = this.findOptionsInCategory(categoryName, categoryList);
          optionGroups.push(opts);
        });

        /**
         * Iterate through optionGroups sub-arrays to see if at least one option
         * from category is in reference list
         */
        const conditionMet = optionGroups
          .every(groupToCheck => this.onlyOr(groupToCheck, refList));
        if (conditionMet) {
          const output = [];

          // For each optionGroups sub-array push the selected opt into output
          optionGroups.forEach((searchGroup) => {
            const foundOption = this.findMatchingOptions(searchGroup, refList);
            output.push(foundOption);
          });

          return output;
        }
      }


      if (group === 'multiAndNot') {
        // For each category: find/store the individual item arr in optionGroups
        multiGroups[group].forEach((categoryName) => {
          const opts = this.findOptionsInCategory(categoryName, categoryList);
          optionGroups.push(opts);
        });

        /**
        * Iterate through optionGroups sub-arr & check if any option
        * from category is in reference list
        */
        const conditionMet = this.categoryOnlyAndNot(optionGroups, refList);
        if (conditionMet) {
          const output = [];

          // For each optionGroups sub-arr push the selected opt into output
          optionGroups.forEach((searchGroup) => {
            const foundOption = this.findMatchingOptions(searchGroup, refList);
            output.push(foundOption);
          });

          return output;
        }
      }
    }
  }
}

export default CustomRules;
