import React, { useState } from 'react';
import PropTypes from 'prop-types';
import SectionHeader from '../SectionHeader/SectionHeader';
import styles from './HomeDetails.module.css';

import SaveForm from '../SaveForm/SaveForm';
import FilterDropdown from '../FilterDropdown/FilterDropdown';
import HomeSpecs from './HomeSpecs/HomeSpecs';
import MenuButtons from './MenuButtons/MenuButtons';
import {ClientSettingsConsumer} from "../../context/ClientSettingsContext";

const HomeDetails = ({
  handleFloorToggle,
}) => {
  const [formOpen, setFormOpen] = useState(false);
  const toggleForm = () => setFormOpen(!formOpen);

  return (
    <div className={styles.homeDetails}>
      {formOpen && (
        <div className={styles.saveFormContainer}>
            <ClientSettingsConsumer>
                {({ cityDropdownList, showCityDropdownList }) => (
                    <SaveForm toggleForm={toggleForm} cityDropdownList={cityDropdownList} showCityDropdownList={showCityDropdownList} />
                )}
            </ClientSettingsConsumer>
        </div>
      )}
      <div className={styles.sectionHeaderContainer}>
        <SectionHeader title="Home Details" />
      </div>
      <div className={styles.detailContainer}>
        <HomeSpecs />
      </div>
      <div className={styles.dropdownContainer}>
        <FilterDropdown />
      </div>
      <div className={styles.buttonContainer}>
        <MenuButtons
          toggleForm={toggleForm}
          handleFloorToggle={handleFloorToggle}
        />
      </div>
    </div>
  );
};

HomeDetails.propTypes = {
  handleFloorToggle: PropTypes.func.isRequired
};

export default HomeDetails;
