import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ClientSettingsConsumer } from '../../context/ClientSettingsContext';
import styles from './ProgressBar.module.css';

class ProgressBar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      percentComplete: 5,
      count: 0
    };

    this.interval = null;
  }

  componentDidMount() {
    this.simulateProgress();
  }

  componentWillUnmount() {
    this.stopInterval();
  }

  stopInterval = () => {
    clearInterval(this.interval);
  }

  updatePercent = () => {
    let { percentComplete, count } = this.state;

    const randomPercentIncrease = Math.floor(
      Math.random() * (12 - 8 + 1) + 8
    );

    if ((percentComplete + randomPercentIncrease) < 95) {
      percentComplete += randomPercentIncrease;
      count += 1;
    } else {
      percentComplete = 97;
      this.stopInterval();
    }

    this.setState({
      percentComplete,
      count
    });
  };

  simulateProgress = () => {
    this.interval = setInterval(() => {
      this.updatePercent();
    }, 900);
  };

  render() {
    const { percentComplete } = this.state;
    return (
      <ClientSettingsConsumer>
        {({ theme }) => (
          <div className={styles.progressBar}>
            <div className={styles.text}>
              {
                `${percentComplete < 75
                  ? 'Building'
                  : 'Finishing'
                } Your Personalized Brochure`
              }
            </div>
            <div className={styles.progressBarBackground}>
              <div
                className={styles.progressBarFill}
                style={{
                  width: `${percentComplete}%`,
                  backgroundColor: theme.progressBarFillColor
                }}
              />
              <div
                className={styles.percentDisplay}
                style={{
                  color: `${percentComplete > 50 ? 'white' : 'black'}`
                }}
              >
                {`${percentComplete}%`}
              </div>
            </div>
            <div className={styles.popUpNotice}>
              <div>
                {'PDF will open in new window.'}
              </div>
              <div>
                {'Please check your browser settings to ensure pop ups are enabled for this site.'}
              </div>
            </div>
          </div>
        )}
      </ClientSettingsConsumer>
    );
  }
}


ProgressBar.defaultProps = {
  buttonText: 'Placeholder',
  clickHandler: null
};

ProgressBar.propTypes = {
  clickHandler: PropTypes.func,
  buttonText: PropTypes.string,
};

export default ProgressBar;
