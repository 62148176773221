import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ClientSettingsConsumer } from '../../context/ClientSettingsContext';
import styles from './DownloadDialog.module.css';
import Button from "../HomeDetails/Button/Button";

class DownloadDialog extends Component {

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  render() {
    const { downloadURL, clickHandler } = this.props;

    return (
      <ClientSettingsConsumer>
        {({ theme }) => (
          <div className={styles.downloadBackground}>
            <div className={styles.downloadBox}>
              <div className={styles.text}>
                Your Personalized Brochure is ready for download:
              </div>
              <div className={styles.buttonRow}>
                <button
                  type="button"
                  className={styles.downloadButton}
                  onClick={() => window.open(downloadURL, '_blank')}
                >
                  Download
                </button>
                <button
                  type="button"
                  className={styles.cancelButton}
                  onClick={clickHandler}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        )}
      </ClientSettingsConsumer>
    );
  }
}

DownloadDialog.propTypes = {
  downloadURL: PropTypes.string.isRequired,
  clickHandler: PropTypes.func.isRequired,
};

export default DownloadDialog;
