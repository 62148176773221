// This file provides the configuration data for siding types.

const sidingConfig = [
  {
    type: 'SV',
    sequenceID: 'siding-option',
    text: {
      main: 'Vinyl',
      detail: '( Base )'
    }
  },
  {
    type: 'UV',
    sequenceID: 'siding-option',
    text: {
      main: 'Vinyl',
      detail: '( Upgrade )'
    }
  },
  {
    type: 'FC1',
    sequenceID: 'siding-option',
    text: {
      main: 'Fibercement',
      detail: '( Matching )'
    }
  },
  {
    type: 'FC2',
    sequenceID: 'siding-option',
    text: {
      main: 'Fibercement',
      detail: '( 2 Tone )'
    }
  }
];

export default sidingConfig;
