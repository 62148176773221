import ColorSelectorActionTypes from './colorSelector.types';

export const handleSequenceChange = sequenceID => ({
  type: ColorSelectorActionTypes.HANDLE_SEQUENCE_CHANGE,
  payload: sequenceID
});

export const handleBackClick = community => ({
  type: ColorSelectorActionTypes.HANDLE_BACK_CLICK,
  payload: community
});

export const handleOptionChange = (option, selection) => ({
  type: ColorSelectorActionTypes.HANDLE_OPTION_CHANGE,
  payload: { option, selection }
});
