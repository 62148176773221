import CommunityActionTypes from './community.types';
import orderCommunityDirectory from './community.utils';

const INITIAL_STATE = {
  communityRID: null,
  directory: null,
  modelList: null,
  details: null,
  dropdownCommunityRID: null, // Move to individual reducer
};

const communityReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CommunityActionTypes.SET_COMMUNITY_RID:
      return {
        ...state,
        communityRID: action.payload
      };
    case CommunityActionTypes.SET_COMMUNITY_DIRECTORY:
      return {
        ...state,
        directory: orderCommunityDirectory(
          state.dropdownCommunityRID, action.payload
        )
      };
    case CommunityActionTypes.SET_COMMUNITY_MODEL_LIST:
      return {
        ...state,
        modelList: action.payload
      };
    case CommunityActionTypes.SET_COMMUNITY_DETAILS:
      return {
        ...state,
        details: action.payload
      };
    case CommunityActionTypes.SET_DROPDOWN_COMM_RID:
      return {
        ...state,
        directory: orderCommunityDirectory(
          action.payload, state.directory
        ),
        dropdownCommunityRID: action.payload
      };
    default:
      return state;
  }
};

export default communityReducer;
