// This file provides the configuration data for garage types.

const garageConfig = [
  {
    type: 'GS',
    sequenceID: 'garage-option',
    text: {
      main: 'Garage',
      detail: ''
    }
  }
];

export default garageConfig;
