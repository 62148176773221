import GlobalConfig from "./GlobalConfig";

export function setHubSpotAnalytics() {
  if (GlobalConfig.get(GlobalConfig.Key.HUBSPOT_ANALYTICS_ID)) {
    // HubSpot analytics
    const scriptElement = document.createElement('script');
    scriptElement.setAttribute('id', 'hs-script-loader');
    scriptElement.setAttribute('async', 'true');
    scriptElement.setAttribute('defer', 'true');
    scriptElement.setAttribute('src', `https://js.hs-scripts.com/${GlobalConfig.get(GlobalConfig.Key.HUBSPOT_ANALYTICS_ID)}.js`);
    document.head.appendChild(scriptElement);
  }
}

export function identifyHubSpotAnalytics(email: string) {
  if (email) {
    let wnd = (window as any);
    var _hsq = wnd._hsq = wnd._hsq || [];
    _hsq.push(["identify", {
      email: email
    }]);
  }
}

export function updateHubSpotAnalytics(path: string) {
  let wnd = (window as any);
  var _hsq = wnd._hsq = wnd._hsq || [];
  _hsq.push(['setPath', path]);
  _hsq.push(['trackPageView']);

  //updateHubSpotBehavioralEvents(`pageview_${path.replace('/', '_')}`, null);
}

export function updateHubSpotBehavioralEvents(eventId: string, properties: any) {
  let wnd = (window as any);
  var _hsq = wnd._hsq = wnd._hsq || [];
  _hsq.push(['trackCustomBehavioralEvent', {
    name: eventId,
    properties: properties
  }]);
}