/**
 * IMPORTANT: If a model uses multiCategory ruleType then you must manually
 * add the individual options into optionsWhitelist for the necesessary floors.
 */

const modelRuleConfig = {
  arbor: {
    // Cooper
    7448: {
      floorOnAppLoad: 2,
      selectionsBlacklist: {
        0: [],
        1: [],
        2: [
          'garg_2extgargfront',
          'eleclstcorn||',
          'stru-2ftsidegtrm'
        ],
        3: [
          'bath-mstrtileshower|chrome frame|obscure glass',
          'bath-mstrtileshower|chrome frame|clear glass',
          'bath-mstrdelmastbath|chrome frame|obscure glass',
          'bath-mstrdelmastbath|chrome frame|clear glass',
          'bath-mstrshower|chrome frame|obscure glass',
          'bath-mstrshower|chrome frame|clear glass'
        ]
      },
      elevationBlacklist: ['elev_b||'],
      optionsWhitelist: {
        0: [],
        1: [
          'fdtn_buf8',
          'fdtn_buf9',
          'fdtn_bup8',
          'fdtn_bup9',
          'fdtn-bsbd',
          'fdtn-bsmtfinishedfullbath',
          'fdtn_bsmtfin_full',
          'fdtn_bsmtfin_part'
        ],
        2: [
          'cabs-kitc-layout-100-3642',
          'stru-2ftsidegargopp',
          'dvgscabcorn',
          'dvgslstcorn',
          'eleccabcorn',
          'eleclstcorn',
          'stru-2ftrear',
          'elev_b',
          'elev_craftsman',
          'elev_traditional',
          'elev_tudor',
          'stru-2ftsidegtrm',
          'stru-sunroom',
          'stru_flex_den',
          'woodlstcorn',
          'gar_2extgargside',
          'gar_4extgargside',
          'gar_6extgargside',
          'garg_2carside',
          'stru_bedconv',
          'trim-kitctable',
          'str-mudroom',
          'garg_2extgargfront',
          'stru_1stflbed',
          'plmb-watersoftrough'
        ],
        3: [
          'bath-mstrdelmastbath',
          'stru-2ftrear',
          'bath-mstrgardentub',
          'bath-mstrshower',
          'bath-mstrtileshower',
          'elev_b',
          'stru-2ftsidegargopp',
          'elev_craftsman',
          'elev_traditional',
          'elev_tudor',
        ]
      },
      customRules: [
        {
          altHref: 'bath-mstrgardentub',
          floor: 3,
          // ruleType: 'multi',
          // groupToReplace: 'multiAnd',
          //  multiGroup: { multiAnd: ['fdtn_bsmtfin_part'] },
          layerPriority: 5
        },
        {
          altHref: 'fdtn-bsmtfinishedfullbath',
          floor: 1,
          // ruleType: 'multi',
          // groupToReplace: 'multiAnd',
          //  multiGroup: { multiAnd: ['fdtn_bsmtfin_part'] },
          layerPriority: 5
        },
        {
          altHref: 'fdtn-bsbd',
          floor: 1,
          // ruleType: 'multi',
          // groupToReplace: 'multiAnd',
          //  multiGroup: { multiAnd: ['fdtn_bsmtfin_part'] },
          layerPriority: 5
        },
        {
          altHref: 'fdtn_bsmtfin_part',
          floor: 1,
          // ruleType: 'multi',
          // groupToReplace: 'multiAnd',
          //  multiGroup: { multiAnd: ['fdtn_bsmtfin_part'] },
          layerPriority: 2
        },
        {
          altHref: 'fdtn_bsmtfin_full',
          floor: 1,
          //  ruleType: 'multi',
          //   groupToReplace: 'multiAnd',
          //    multiGroup: { multiAnd: ['fdtn_bsmtfin_full'] },
          layerPriority: 2
        },
        {
          altHref: 'fdtn_buf8 or fdtn_buf9',
          floor: 1,
          ruleType: 'multi',
          groupToReplace: 'multiOr',
          multiGroup: { multiOr: ['fdtn_buf8', 'fdtn_buf9'] },
          layerPriority: 1
        },
        {
          altHref: 'fdtn_bup8 or fdtn_bup9',
          floor: 1,
          ruleType: 'multi',
          groupToReplace: 'multiOr',
          multiGroup: { multiOr: ['fdtn_bup8', 'fdtn_bup9'] },
          layerPriority: 1
        },
        {
          altHref: 'cabs-kitc-layout-100-3642 and not stru-2ftsidegargopp',
          floor: 2,
          ruleType: 'multi',
          groupToReplace: 'multiAndNot',
          multiGroup: {
            multiAndNot: ['cabs-kitc-layout-100-3642', 'stru-2ftsidegargopp']
          },
          layerPriority: 3
        },
        {
          altHref: 'dvgscabcorn or eleccabcorn and not stru-2ftrear',
          floor: 2,
          ruleType: 'multi',
          groupToReplace: 'multiOr',
          multiGroup: {
            multiOr: ['dvgscabcorn', 'eleccabcorn'],
            multiAndNot: ['stru-2ftrear']
          },
          layerPriority: 3
        },
        {
          altHref: 'dvgscabcorn or eleccabcorn and stru-2ftrear',
          floor: 2,
          ruleType: 'multi',
          groupToReplace: 'multiOr',
          multiGroup: {
            multiOr: ['dvgscabcorn', 'eleccabcorn'],
            multiAnd: ['stru-2ftrear']
          },
          layerPriority: 3
        },
        {
          altHref: 'dvgslstcorn or eleclstcorn and not stru-2ftrear',
          floor: 2,
          ruleType: 'multi',
          groupToReplace: 'multiOr',
          multiGroup: {
            multiOr: ['dvgslstcorn', 'eleclstcorn'],
            multiAndNot: ['stru-2ftrear']
          },
          layerPriority: 3
        },
        {
          altHref: 'dvgslstcorn or eleclstcorn and stru-2ftrear',
          floor: 2,
          ruleType: 'multi',
          groupToReplace: 'multiOr',
          multiGroup: {
            multiOr: ['dvgslstcorn', 'eleclstcorn'],
            multiAnd: ['stru-2ftrear']
          },
          layerPriority: 3
        },
        {
          altHref: 'elev_b and not stru-2ftsidegargopp',
          floor: 2,
          ruleType: 'multi',
          groupToReplace: 'multiAndNot',
          multiGroup: { multiAndNot: ['elev_b', 'stru-2ftsidegargopp'] },
          layerPriority: 3
        },
        {
          altHref: 'elev_b and stru-2ftsidegargopp',
          floor: 2,
          ruleType: 'multi',
          groupToReplace: 'multiAnd',
          multiGroup: { multiAnd: ['elev_b', 'stru-2ftsidegargopp'] },
          layerPriority: 3
        },
        {
          altHref: 'elev_craftsman and not stru-2ftsidegargopp',
          floor: 2,
          ruleType: 'multi',
          groupToReplace: 'multiAndNot',
          multiGroup: {
            multiAndNot: ['elev_craftsman', 'stru-2ftsidegargopp']
          },
          layerPriority: 3
        },
        {
          altHref: 'elev_craftsman and stru-2ftsidegargopp',
          floor: 2,
          ruleType: 'multi',
          groupToReplace: 'multiAnd',
          multiGroup: { multiAnd: ['elev_craftsman', 'stru-2ftsidegargopp'] },
          layerPriority: 3
        },
        {
          altHref: 'stru_1stflbed and plmb-watersoftrough',
          floor: 2,
          ruleType: 'multi',
          groupToReplace: 'multiAnd',
          multiGroup: { multiAnd: ['stru_1stflbed', 'plmb-watersoftrough'] },
          layerPriority: 3
        },
        {
          altHref: 'elev_traditional and not stru-2ftsidegargopp',
          floor: 2,
          ruleType: 'multi',
          groupToReplace: 'multiAndNot',
          multiGroup: {
            multiAndNot: ['elev_traditional', 'stru-2ftsidegargopp']
          },
          layerPriority: 3
        },
        {
          altHref: 'elev_traditional and stru-2ftsidegargopp',
          floor: 2,
          ruleType: 'multi',
          groupToReplace: 'multiAnd',
          multiGroup: {
            multiAnd: ['elev_traditional', 'stru-2ftsidegargopp']
          },
          layerPriority: 3
        },
        {
          altHref: 'elev_tudor and not stru-2ftsidegargopp',
          floor: 2,
          ruleType: 'multi',
          groupToReplace: 'multiAndNot',
          multiGroup: { multiAndNot: ['elev_tudor', 'stru-2ftsidegargopp'] },
          layerPriority: 3
        },
        {
          altHref: 'elev_tudor and stru-2ftsidegargopp',
          floor: 2,
          ruleType: 'multi',
          groupToReplace: 'multiAnd',
          multiGroup: { multiAnd: ['elev_tudor', 'stru-2ftsidegargopp'] },
          layerPriority: 3
        },
        {
          altHref: 'stru-2ftsidegargopp join stru-2ftrear',
          floor: 2,
          ruleType: 'multi',
          groupToReplace: 'multiJoin',
          multiGroup: { multiJoin: ['stru-2ftsidegargopp', 'stru-2ftrear'] },
          layerPriority: 3
        },
        {
          altHref: 'stru-2ftsidegtrm and not stru-2ftrear',
          floor: 2,
          ruleType: 'multi',
          groupToReplace: 'multiAndNot',
          multiGroup: { multiAndNot: ['stru-2ftsidegtrm', 'stru-2ftrear'] },
          layerPriority: 3
        },
        {
          altHref: 'stru-2ftsidegtrm and stru-2ftrear',
          floor: 2,
          ruleType: 'multi',
          groupToReplace: 'multiAnd',
          multiGroup: { multiAnd: ['stru-2ftsidegrtrm', 'stru-2ftrear'] },
          layerPriority: 3
        },
        {
          altHref: 'stru-sunroom and not stru-2ftrear',
          floor: 2,
          ruleType: 'multi',
          groupToReplace: 'multiAndNot',
          multiGroup: { multiAndNot: ['stru-sunroom', 'stru-2ftrear'] },
          layerPriority: 3
        },
        {
          altHref:
            'stru-sunroom and stru-2ftrear and not stru-2ftsidegargopp',
          floor: 2,
          ruleType: 'multi',
          groupToReplace: 'multiAnd',
          multiGroup: {
            multiAnd: ['stru-sunroom', 'stru-2ftrear'],
            multiAndNot: ['stru-2ftsidegargopp']
          },
          layerPriority: 3
        },
        {
          altHref: 'stru-sunroom and stru-2ftrear and stru-2ftsidegargopp',
          floor: 2,
          ruleType: 'multi',
          groupToReplace: 'multiAnd',
          multiGroup: {
            multiAnd: ['stru-sunroom', 'stru-2ftrear', 'stru-2ftsidegargopp']
          },
          layerPriority: 3
        },
        {
          altHref: 'stru_flex_den and not stru-2ftsidegargopp',
          floor: 2,
          ruleType: 'multi',
          groupToReplace: 'multiAndNot',
          multiGroup: {
            multiAndNot: ['stru_flex_den', 'stru-2ftsidegargopp']
          },
          layerPriority: 3
        },
        {
          altHref: 'stru_flex_den and stru-2ftsidegargopp',
          floor: 2,
          ruleType: 'multi',
          groupToReplace: 'multiAnd',
          multiGroup: { multiAnd: ['stru_flex_den', 'stru-2ftsidegargopp'] },
          layerPriority: 3
        },
        {
          altHref: 'woodlstcorn and not stru-2ftrear',
          floor: 2,
          ruleType: 'multi',
          groupToReplace: 'multiAndNot',
          multiGroup: { multiAndNot: ['woodlstcorn', 'stru-2ftrear'] },
          layerPriority: 3
        },
        {
          altHref: 'woodlstcorn and stru-2ftrear',
          floor: 2,
          ruleType: 'multi',
          groupToReplace: 'multiAnd',
          multiGroup: { multiAnd: ['woodlstcorn', 'stru-2ftrear'] },
          layerPriority: 3
        },
        {
          altHref: 'bath-mstrdelmastbath and not stru-2ftrear',
          floor: 3,
          ruleType: 'multi',
          groupToReplace: 'multiAndNot',
          multiGroup: {
            multiAndNot: ['bath-mstrdelmastbath', 'stru-2ftrear']
          },
          layerPriority: 3
        },
        {
          altHref: 'bath-mstrdelmastbath and stru-2ftrear',
          floor: 3,
          ruleType: 'multi',
          groupToReplace: 'multiAnd',
          multiGroup: { multiAnd: ['bath-mstrdelmastbath', 'stru-2ftrear'] },
          layerPriority: 3
        },
        {
          altHref: 'bath-mstrgardentub and stru-2ftrear',
          floor: 3,
          ruleType: 'multi',
          groupToReplace: 'multiAnd',
          multiGroup: { multiAnd: ['bath-mstrgardentub', 'stru-2ftrear'] },
          layerPriority: 3
        },
        {
          altHref: 'bath-mstrgardentub and not stru-2ftrear',
          floor: 3,
          ruleType: 'multi',
          groupToReplace: 'multiAndNot',
          multiGroup: { multiAndNot: ['bath-mstrgardentub', 'stru-2ftrear'] },
          layerPriority: 3
        },
        {
          altHref: 'bath-mstrshower and not stru-2ftrear',
          floor: 3,
          ruleType: 'multi',
          groupToReplace: 'multiAndNot',
          multiGroup: { multiAndNot: ['bath-mstrshower', 'stru-2ftrear'] },
          layerPriority: 3
        },
        {
          altHref: 'bath-mstrshower and stru-2ftrear',
          floor: 3,
          ruleType: 'multi',
          groupToReplace: 'multiAnd',
          multiGroup: { multiAnd: ['bath-mstrshower', 'stru-2ftrear'] },
          layerPriority: 3
        },
        {
          altHref: 'bath-mstrtileshower and not stru-2ftrear',
          floor: 3,
          ruleType: 'multi',
          groupToReplace: 'multiAndNot',
          multiGroup: {
            multiAndNot: ['bath-mstrtileshower', 'stru-2ftrear']
          },
          layerPriority: 3
        },
        {
          altHref: 'bath-mstrtileshower and stru-2ftrear',
          floor: 3,
          ruleType: 'multi',
          groupToReplace: 'multiAnd',
          multiGroup: { multiAnd: ['bath-mstrtileshower', 'stru-2ftrear'] },
          layerPriority: 3
        },
        {
          altHref: 'elev_b and not stru-2ftsidegargopp',
          floor: 3,
          ruleType: 'multi',
          groupToReplace: 'multiAndNot',
          multiGroup: { multiAndNot: ['elev_b', 'stru-2ftsidegargopp'] },
          layerPriority: 3
        },
        {
          altHref: 'elev_b and stru-2ftsidegargopp',
          floor: 3,
          ruleType: 'multi',
          groupToReplace: 'multiAnd',
          multiGroup: { multiAnd: ['elev_b', 'stru-2ftsidegargopp'] },
          layerPriority: 3
        },
        {
          altHref: 'elev_craftsman and not stru-2ftsidegargopp',
          floor: 3,
          ruleType: 'multi',
          groupToReplace: 'multiAndNot',
          multiGroup: {
            multiAndNot: ['elev_craftsman', 'stru-2ftsidegargopp']
          },
          layerPriority: 3
        },
        {
          altHref: 'elev_craftsman and stru-2ftsidegargopp',
          floor: 3,
          ruleType: 'multi',
          groupToReplace: 'multiAnd',
          multiGroup: { multiAnd: ['elev_craftsman', 'stru-2ftsidegargopp'] },
          layerPriority: 3
        },
        {
          altHref: 'elev_traditional and not stru-2ftsidegargopp',
          floor: 3,
          ruleType: 'multi',
          groupToReplace: 'multiAndNot',
          multiGroup: {
            multiAndNot: ['elev_traditional', 'stru-2ftsidegargopp']
          },
          layerPriority: 3
        },
        {
          altHref: 'elev_traditional and stru-2ftsidegargopp',
          floor: 3,
          ruleType: 'multi',
          groupToReplace: 'multiAnd',
          multiGroup: {
            multiAnd: ['elev_traditional', 'stru-2ftsidegargopp']
          },
          layerPriority: 3
        },
        {
          altHref: 'elev_tudor and not stru-2ftsidegargopp',
          floor: 3,
          ruleType: 'multi',
          groupToReplace: 'multiAndNot',
          multiGroup: { multiAndNot: ['elev_tudor', 'stru-2ftsidegargopp'] },
          layerPriority: 3
        },
        {
          altHref: 'elev_tudor and stru-2ftsidegargopp',
          floor: 3,
          ruleType: 'multi',
          groupToReplace: 'multiAnd',
          multiGroup: { multiAnd: ['elev_tudor', 'stru-2ftsidegargopp'] },
          layerPriority: 3
        },
        {
          altHref: 'stru-2ftsidegargopp join stru-2ftrear',
          floor: 3,
          ruleType: 'multi',
          groupToReplace: 'multiJoin',
          multiGroup: { multiJoin: ['stru-2ftsidegargopp', 'stru-2ftrear'] },
          layerPriority: 3
        }
      ]
    },
    // Cottonwood
    864: {
      floorOnAppLoad: 2,
      selectionsBlacklist: {
        0: [],
        1: ['fdtn_slab'],
        2: [
          'stru_game',
          'eleclstcorn||',
          'stru_m2bd',
          'bath-mstrshower|chrome frame|obscure glass',
          'bath-mstrshower|chrome frame|clear glass',
          'bath-mstrdelmastbath|Chrome Frame|Obscure Glass',
          'bath-mstrdelmastbath|chrome frame|clear glass',
          'bath-mstrdelmastbath|chrome frame|obscure glass',
          'bath-mstrtileshower|chrome frame|obscure glass',
          'bath-mstrtileshower|chrome frame|clear glass',
          'garg_2extgargfront'
        ],
        3: ['garg_2extgargfront']
      },
      elevationBlacklist: ['elev_b||'],
      optionsWhitelist: {
        0: [],
        1: [
          'fdtn_buf8',
          'fdtn_buf9',
          'fdtn_bsmtfin_part',
          'fdtn_bup8',
          'fdtn_bup9',
          'fdtn-bsbd',
          'fdtn-bsmtfinishedfullbath',
          'fdtn_bsmtfin_full'
        ],
        2: [
          'bath-mstrdelmastbath',
          'stru-2ftsidegargside',
          'bath-mstrgardentub',
          'bath-mstrshower',
          'bath-mstrtileshower',
          'dvgslstcorn',
          'dvgscabcorn',
          'eleccabcorn',
          'eleclstcorn',
          'stru-2ftrear',
          'garg_2extgargfront',
          'gar_4extgargside',
          'gar_2extgargside',
          'gar_6extgargside',
          'gar_2extgargfront',
          'stru-2ftsidegaragside',
          'stru-sunroom',
          'woodlstcorn',
          'stru-convertdentobed',
          'stru_game',
        ],
        3: [
          'stru_m2bd',
          'stru-2ftsidegargside',
          'stru_game',
          'stru_gsbdgsbt'
        ]
      },
      customRules: [
        {
          altHref: 'fdtn_bsmtfin_full',
          floor: 1,
          layerPriority: 5
        },
        {
          altHref: 'fdtn-bsbd',
          floor: 1,
          layerPriority: 5
        },
        {
          altHref: 'fdtn-bsmtfinishedfullbath',
          floor: 1,
          layerPriority: 5
        },
        {
          altHref: 'fdtn_buf8 or fdtn_buf9 and fdtn_bsmtfin_part',
          floor: 1,
          ruleType: 'multi',
          groupToReplace: 'multiOr',
          multiGroup: {
            multiOr: ['fdtn_buf8', 'fdtn_buf9'],
            multiAnd: ['fdtn_bsmtfin_part']
          },
          layerPriority: 4
        },
        {
          altHref: 'fdtn_buf8 or fdtn_buf9',
          floor: 1,
          ruleType: 'multi',
          groupToReplace: 'multiOr',
          multiGroup: { multiOr: ['fdtn_buf8', 'fdtn_buf9'] },
          layerPriority: 3
        },
        {
          altHref: 'fdtn_bup8 or fdtn_bup9 and fdtn_bsmtfin_part',
          floor: 1,
          ruleType: 'multi',
          groupToReplace: 'multiOr',
          multiGroup: {
            multiOr: ['fdtn_bup8', 'fdtn_bup9'],
            multiAnd: ['fdtn_bsmtfin_part']
          },
          layerPriority: 4
        },
        {
          altHref: 'fdtn_bup8 or fdtn_bup9',
          floor: 1,
          ruleType: 'multi',
          groupToReplace: 'multiOr',
          multiGroup: { multiOr: ['fdtn_bup8', 'fdtn_bup9'] },
          layerPriority: 3
        },
        {
          altHref: 'bath-mstrdelmastbath and not stru-2ftsidegargside',
          floor: 2,
          ruleType: 'multi',
          groupToReplace: 'multiAndNot',
          multiGroup: {
            multiAndNot: ['bath-mstrdelmastbath', 'stru-2ftsidegargside']
          },
          layerPriority: 3
        },
        {
          altHref: 'bath-mstrdelmastbath and stru-2ftsidegargside',
          floor: 2,
          ruleType: 'multi',
          groupToReplace: 'multiAnd',
          multiGroup: {
            multiAnd: ['bath-mstrdelmastbath', 'stru-2ftsidegargside']
          },
          layerPriority: 3
        },
        {
          altHref: 'bath-mstrgardentub and not stru-2ftsidegargside',
          floor: 2,
          ruleType: 'multi',
          groupToReplace: 'multiAndNot',
          multiGroup: {
            multiAndNot: ['bath-mstrgardentub', 'stru-2ftsidegargside']
          },
          layerPriority: 3
        },
        {
          altHref: 'bath-mstrgardentub and stru-2ftsidegargside',
          floor: 2,
          ruleType: 'multi',
          groupToReplace: 'multiAnd',
          multiGroup: {
            multiAnd: ['bath-mstrgardentub', 'stru-2ftsidegargside']
          },
          layerPriority: 3
        },
        {
          altHref: 'bath-mstrshower and not stru-2ftsidegargside',
          floor: 2,
          ruleType: 'multi',
          groupToReplace: 'multiAndNot',
          multiGroup: {
            multiAndNot: ['bath-mstrshower', 'stru-2ftsidegargside']
          },
          layerPriority: 3
        },
        {
          altHref: 'bath-mstrshower and stru-2ftsidegargside',
          floor: 2,
          ruleType: 'multi',
          groupToReplace: 'multiAnd',
          multiGroup: {
            multiAnd: ['bath-mstrshower', 'stru-2ftsidegargside']
          },
          layerPriority: 3
        },
        {
          altHref: 'bath-mstrtileshower and not stru-2ftsidegargside',
          floor: 2,
          ruleType: 'multi',
          groupToReplace: 'multiAndNot',
          multiGroup: {
            multiAndNot: ['bath-mstrtileshower', 'stru-2ftsidegargside']
          },
          layerPriority: 3
        },
        {
          altHref: 'bath-mstrtileshower and stru-2ftsidegargside',
          floor: 2,
          ruleType: 'multi',
          groupToReplace: 'multiAnd',
          multiGroup: {
            multiAnd: ['bath-mstrtileshower', 'stru-2ftsidegargside']
          },
          layerPriority: 3
        },
        {
          altHref:
            'dvgslstcorn or dvgscabcorn or eleccabcorn or eleclstcorn and not stru-2ftrear',
          floor: 2,
          ruleType: 'multi',
          groupToReplace: 'multiOr',
          multiGroup: {
            multiOr: [
              'dvgslstcorn',
              'dvgscabcorn',
              'eleccabcorn',
              'eleclstcorn'
            ],
            multiAndNot: ['stru-2ftrear']
          },
          layerPriority: 4
        },
        {
          altHref:
            'dvgslstcorn or dvgscabcorn or eleccabcorn or eleclstcorn and stru-2ftrear',
          floor: 2,
          ruleType: 'multi',
          groupToReplace: 'multiOr',
          multiGroup: {
            multiOr: [
              'dvgslstcorn',
              'dvgscabcorn',
              'eleccabcorn',
              'eleclstcorn'
            ],
            multiAnd: ['stru-2ftrear']
          },
          layerPriority: 4
        },
        {
          altHref: 'garg_2extgargfront and not stru-2ftsidegargside',
          floor: 2,
          ruleType: 'multi',
          groupToReplace: 'multiAndNot',
          multiGroup: {
            multiAndNot: ['garg_2extgargfront', 'stru-2ftsidegargside']
          },
          layerPriority: 3
        },
        {
          altHref: 'garg_2extgargfront and stru-2ftsidegargside',
          floor: 2,
          ruleType: 'multi',
          groupToReplace: 'multiAnd',
          multiGroup: {
            multiAnd: ['garg_2extgargfront', 'stru-2ftsidegargside']
          },
          layerPriority: 3
        },
        {
          altHref: 'gar_2extgargside and not stru-2ftsidegargside',
          floor: 2,
          ruleType: 'multi',
          groupToReplace: 'multiAndNot',
          multiGroup: {
            multiAndNot: ['gar_2extgargside', 'stru-2ftsidegargside']
          },
          layerPriority: 3
        },
        {
          altHref: 'gar_2extgargside and stru-2ftsidegargside',
          floor: 2,
          ruleType: 'multi',
          groupToReplace: 'multiAnd',
          multiGroup: {
            multiAnd: ['gar_2extgargside', 'stru-2ftsidegargside']
          },
          layerPriority: 3
        },
        {
          altHref: 'gar_4extgargside and not stru-2ftsidegargside',
          floor: 2,
          ruleType: 'multi',
          groupToReplace: 'multiAndNot',
          multiGroup: {
            multiAndNot: ['gar_4extgargside', 'stru-2ftsidegargside']
          },
          layerPriority: 3
        },
        {
          altHref: 'gar_4extgargside and stru-2ftsidegargside',
          floor: 2,
          ruleType: 'multi',
          groupToReplace: 'multiAnd',
          multiGroup: {
            multiAnd: ['gar_4extgargside', 'stru-2ftsidegargside']
          },
          layerPriority: 3
        },
        {
          altHref: 'gar_6extgargside and not stru-2ftsidegargside',
          floor: 2,
          ruleType: 'multi',
          groupToReplace: 'multiAndNot',
          multiGroup: {
            multiAndNot: ['gar_6extgargside', 'stru-2ftsidegargside']
          },
          layerPriority: 3
        },
        {
          altHref: 'gar_6extgargside and stru-2ftsidegargside',
          floor: 2,
          ruleType: 'multi',
          groupToReplace: 'multiAnd',
          multiGroup: {
            multiAnd: ['gar_6extgargside', 'stru-2ftsidegargside']
          },
          layerPriority: 3
        },
        {
          altHref: 'stru-2ftsidegargside join stru-2ftrear',
          floor: 2,
          ruleType: 'multi',
          groupToReplace: 'multiJoin',
          multiGroup: { multiJoin: ['stru-2ftsidegargside', 'stru-2ftrear'] },
          layerPriority: 3
        },
        {
          altHref: 'stru-sunroom and not stru-2ftrear',
          floor: 2,
          ruleType: 'multi',
          groupToReplace: 'multiAndNot',
          multiGroup: { multiAndNot: ['stru-sunroom', 'stru-2ftrear'] },
          layerPriority: 3
        },
        {
          altHref: 'stru-sunroom and stru-2ftrear',
          floor: 2,
          ruleType: 'multi',
          groupToReplace: 'multiAnd',
          multiGroup: { multiAnd: ['stru-sunroom', 'stru-2ftrear'] },
          layerPriority: 3
        },
        {
          altHref: 'woodlstcorn and not stru-2ftrear',
          floor: 2,
          ruleType: 'multi',
          groupToReplace: 'multiAndNot',
          multiGroup: { multiAndNot: ['woodlstcorn', 'stru-2ftrear'] },
          layerPriority: 4
        },
        {
          altHref: 'woodlstcorn and stru-2ftrear',
          floor: 2,
          ruleType: 'multi',
          groupToReplace: 'multiAnd',
          multiGroup: { multiAnd: ['woodlstcorn', 'stru-2ftrear'] },
          layerPriority: 4
        },
        {
          altHref: 'stru_m2bd and not stru-2ftsidegargside',
          floor: 3,
          ruleType: 'multi',
          groupToReplace: 'multiAndNot',
          multiGroup: { multiAndNot: ['stru_m2bd', 'stru-2ftsidegargside'] },
          layerPriority: 3
        },
        {
          altHref: 'stru_m2bd and stru-2ftsidegargside',
          floor: 3,
          ruleType: 'multi',
          groupToReplace: 'multiAnd',
          multiGroup: { multiAnd: ['stru_m2bd', 'stru-2ftsidegargside'] },
          layerPriority: 3
        }
      ]
    },
    // Ashton
    867: {
      floorOnAppLoad: 1,
      selectionsBlacklist: {
        0: [],
        1: [
          'cabs-island-level',
          'fdtn-foundation',
          'bath-mstrshower|chrome frame|- not selected -',
          'bath-mstrshower|chrome frame|clear glass',
          'bath-mstrshower|chrome frame|obscure glass',
          'bath-mstrdelmastbath|chrome frame|clear glass',
          'bath-mstrdelmastbath|chrome frame|obscure glass',
          'bath-mstrtileshower|chrome frame|clear glass',
          'bath-mstrtileshower|chrome frame|obscure glass'
        ]
      },
      elevationBlacklist: [
        // 'elev_traditional||',
        // 'elev_craftsman||',
        // 'elev_tudor||'
      ],
      optionsWhitelist: {
        0: [],
        1: [
          'bath-mstrgardentub',
          'stru-2ftsidegargside',
          'bath-mstrtileshower',
          'elev_b',
          'elev_c',
          'gar_2extgargside',
          'gar_4extgargside',
          'gar_6extgargside',
          'stru-2ftsidegargopp',
          'garg_2extgargfront',
          'stru-2ftrear',
          'stru-4ftrearktnk',
          'stru-sunroom',
          'dvgslstnoncorn',
          'woodlstnoncorn',
          'plmb-watersoftrough',
          'bath-mstrshower',

        ]
      },
      customRules: [
        {
          altHref: 'bath-mstrgardentub and not stru-2ftsidegargside',
          floor: 1,
          ruleType: 'multi',
          groupToReplace: 'multiAndNot',
          multiGroup: {
            multiAndNot: ['bath-mstrgardentub', 'stru-2ftsidegargside']
          },
          layerPriority: 2
        },
        {
          altHref: 'bath-mstrgardentub and stru-2ftsidegargside',
          floor: 1,
          ruleType: 'multi',
          groupToReplace: 'multiAnd',
          multiGroup: {
            multiAnd: ['bath-mstrgardentub', 'stru-2ftsidegargside']
          },
          layerPriority: 4
        },
        {
          altHref: 'bath-mstrshower and not stru-2ftsidegargside',
          floor: 1,
          ruleType: 'multi',
          groupToReplace: 'multiAndNot',
          multiGroup: {
            multiAndNot: ['bath-mstrshower', 'stru-2ftsidegargside']
          },
          layerPriority: 2
        },
        {
          altHref: 'bath-mstrshower and stru-2ftsidegargside',
          floor: 1,
          ruleType: 'multi',
          groupToReplace: 'multiAnd',
          multiGroup: {
            multiAnd: ['bath-mstrshower', 'stru-2ftsidegargside']
          },
          layerPriority: 4
        },
        {
          altHref: 'bath-mstrtileshower and not stru-2ftsidegargside',
          floor: 1,
          ruleType: 'multi',
          groupToReplace: 'multiAndNot',
          multiGroup: {
            multiAndNot: ['bath-mstrtileshower', 'stru-2ftsidegargside']
          },
          layerPriority: 2
        },
        {
          altHref: 'bath-mstrtileshower and stru-2ftsidegargside',
          floor: 1,
          ruleType: 'multi',
          groupToReplace: 'multiAnd',
          multiGroup: {
            multiAnd: ['bath-mstrtileshower', 'stru-2ftsidegargside']
          },
          layerPriority: 4
        },
        {
          altHref: 'elev_b and not stru-2ftsidegargopp',
          floor: 1,
          ruleType: 'multi',
          groupToReplace: 'multiAndNot',
          multiGroup: { multiAndNot: ['elev_b', 'stru-2ftsidegargopp'] },
          layerPriority: 2
        },
        {
          altHref: 'elev_b and stru-2ftsidegargopp',
          floor: 1,
          ruleType: 'multi',
          groupToReplace: 'multiAnd',
          multiGroup: { multiAnd: ['elev_b', 'stru-2ftsidegargopp'] },
          layerPriority: 3
        },
        {
          altHref: 'elev_c and not stru-2ftsidegargopp',
          floor: 1,
          ruleType: 'multi',
          groupToReplace: 'multiAndNot',
          multiGroup: { multiAndNot: ['elev_c', 'stru-2ftsidegargopp'] },
          layerPriority: 2
        },
        {
          altHref: 'elev_c and stru-2ftsidegargopp',
          floor: 1,
          ruleType: 'multi',
          groupToReplace: 'multiAnd',
          multiGroup: { multiAnd: ['elev_c', 'stru-2ftsidegargopp'] },
          layerPriority: 3
        },
        {
          altHref: 'gar_2extgargside and not stru-2ftsidegargside',
          floor: 1,
          ruleType: 'multi',
          groupToReplace: 'multiAndNot',
          multiGroup: {
            multiAndNot: ['gar_2extgargside', 'stru-2ftsidegargside']
          },
          layerPriority: 2
        },
        {
          altHref: 'gar_2extgargside and stru-2ftsidegargside',
          floor: 1,
          ruleType: 'multi',
          groupToReplace: 'multiAnd',
          multiGroup: {
            multiAnd: ['gar_2extgargside', 'stru-2ftsidegargside']
          },
          layerPriority: 4
        },
        {
          altHref: 'gar_4extgargside and not stru-2ftsidegargside',
          floor: 1,
          ruleType: 'multi',
          groupToReplace: 'multiAndNot',
          multiGroup: {
            multiAndNot: ['gar_4extgargside', 'stru-2ftsidegargside']
          },
          layerPriority: 2
        },
        {
          altHref: 'gar_4extgargside and stru-2ftsidegargside',
          floor: 1,
          ruleType: 'multi',
          groupToReplace: 'multiAnd',
          multiGroup: {
            multiAnd: ['gar_4extgargside', 'stru-2ftsidegargside']
          },
          layerPriority: 4
        },
        {
          altHref: 'gar_6extgargside and not stru-2ftsidegargside',
          floor: 1,
          ruleType: 'multi',
          groupToReplace: 'multiAndNot',
          multiGroup: {
            multiAndNot: ['gar_6extgargside', 'stru-2ftsidegargside']
          },
          layerPriority: 2
        },
        {
          altHref: 'gar_6extgargside and stru-2ftsidegargside',
          floor: 1,
          ruleType: 'multi',
          groupToReplace: 'multiAnd',
          multiGroup: {
            multiAnd: ['gar_6extgargside', 'stru-2ftsidegargside']
          },
          layerPriority: 4
        },
        {
          altHref: 'stru-2ftsidegargopp join stru-2ftrear',
          floor: 1,
          ruleType: 'multi',
          groupToReplace: 'multiJoin',
          multiGroup: { multiJoin: ['stru-2ftsidegargopp', 'stru-2ftrear'] },
          layerPriority: 3
        },
        {
          altHref: 'stru-2ftsidegargside join stru-2ftrear',
          floor: 1,
          ruleType: 'multi',
          groupToReplace: 'multiJoin',
          multiGroup: { multiJoin: ['stru-2ftsidegargside', 'stru-2ftrear'] },
          layerPriority: 3
        },
        {
          altHref: 'stru-4ftrearktnk and not stru-2ftrear',
          floor: 1,
          ruleType: 'multi',
          groupToReplace: 'multiAndNot',
          multiGroup: { multiAndNot: ['stru-4ftrearktnk', 'stru-2ftrear'] },
          layerPriority: 4
        },
        {
          altHref: 'stru-4ftrearktnk and stru-2ftrear',
          floor: 1,
          ruleType: 'multi',
          groupToReplace: 'multiAnd',
          multiGroup: { multiAnd: ['stru-4ftrearktnk', 'stru-2ftrear'] },
          layerPriority: 4
        },
        {
          altHref: 'stru-sunroom and not stru-2ftrear',
          floor: 1,
          ruleType: 'multi',
          groupToReplace: 'multiAndNot',
          multiGroup: { multiAndNot: ['stru-sunroom', 'stru-2ftrear'] },
          layerPriority: 4
        },
        {
          altHref: 'stru-sunroom and stru-2ftrear',
          floor: 1,
          ruleType: 'multi',
          groupToReplace: 'multiAnd',
          multiGroup: { multiAnd: ['stru-sunroom', 'stru-2ftrear'] },
          layerPriority: 4
        }
      ]
    },
    // Bradford
    868: {
      floorOnAppLoad: 2,
      optionsWhitelist: {
        0: [],
        1: [
          'fdtn_buf8',
          'fdtn_buf9',
          'fdtn_bup8',
          'fdtn_bup9',
          'fdtn-bsmtfinishedfullbath',
          'fdtn_bsmtfin_full',
          'fdtn_bsmtfin_part'
        ],
        2: [
          'bath-dblbowlmsbt',
          'stru-2ftsidegargside',
          'bath-mstrgardentub',
          'bath-mstrtileshower',
          'bath-mstrshower',
          'bath-mstrdelmastbath',
          'dvgslstcorn',
          'woodlstcorn',
          'eleclstcorn',
          'dvgscabcorn',
          'eleccabcorn',
          'stru-2ftsidegargopp',
          'garg_2extgargfront',
          'stru-2ftrear',
          'elev_b',
          'elev_tudor',
          'fdtn_buf8',
          'fdtn_buf9',
          'fdtn_bup8',
          'fdtn_bup9',
          'stru-bnrm',
          'gar_2extgargside',
          'gar_4extgargside',
          'gar_6extgargside',
          'stru-2ftsidegargside',
          'stru-sunroom',
          'cabs-kitc-island-level-000'
        ],
        3: ['stru-bnrm']
      },
      garageBlacklist: ['garg_2extgargfront', 'garg'],
      selectionsBlacklist: {
        0: [],
        1: ['garg-extgargside'],
        2: [
          'cabs-kitc-island-level-000|merillat - colony birch square|mirage',
          'cabs-kitc-island-level-000|merillat - colony birch square|clove',
          'bath-mstrshower|chrome frame|- not selected -',
          'bath-mstrshower|chrome frame|obscure glass',
          'bath-mstrshower|chrome frame|clear glass',
          'bath-mstrtileshower|chrome frame|obscure glass',
          'bath-mstrtileshower|chrome frame|clear glass',
          'bath-mstrdelmastbath|chrome frame|obscure glass',
          'bath-mstrdelmastbath|chrome frame|clear glass'
        ],
        3: ['garg_2extgargfront']
      },
      categoriesToConvert: {
        'fdtn-foundation': [
          'fdtn_buf8',
          'fdtn_buf9',
          'fdtn_bup8',
          'fdtn_bup9'
        ],
        'stru-bnrm': ['stru-bnrm']
      },
      elevationBlacklist: [],
      customRules: [
        // {
        //   altHref: 'cabs-kitc-island-level-000',
        //   floor: 2,
        //   layerPriority: 3
        // },
        // {
        //   altHref: 'bath-mstrtileshower and not stru-2ftsidegargside',
        //   floor: 2,
        //   layerPriority: 3
        // },
        // {
        //   altHref: 'bath-mstrgardentub and stru-2ftsidegargside',
        //   floor: 2,
        //   layerPriority: 3
        // },
        {
          altHref: 'fdtn_buf8 or fdtn_buf9',
          floor: 1,
          ruleType: 'multi',
          groupToReplace: 'multiOr',
          multiGroup: { multiOr: ['fdtn_buf8', 'fdtn_buf9'] },
          layerPriority: 3
        },
        {
          altHref: 'fdtn_bup8 or fdtn_bup9',
          floor: 1,
          ruleType: 'multi',
          groupToReplace: 'multiOr',
          multiGroup: { multiOr: ['fdtn_bup8', 'fdtn_bup9'] },
          layerPriority: 3
        },
        {
          altHref: 'bath-dblbowlmsbt and not stru-2ftsidegargside',
          floor: 2,
          ruleType: 'multi',
          groupToReplace: 'multiAndNot',
          multiGroup: {
            multiAndNot: ['bath-dblbowlmsbt', 'stru-2ftsidegargside']
          },
          layerPriority: 5
        },
        {
          altHref: 'bath-dblbowlmsbt and stru-2ftsidegargside',
          floor: 2,
          ruleType: 'multi',
          groupToReplace: 'multiAnd',
          multiGroup: {
            multiAnd: ['bath-dblbowlmsbt', 'stru-2ftsidegargside']
          },
          layerPriority: 5
        },
        {
          altHref: 'bath-mstrshower and not stru-2ftsidegargside',
          floor: 2,
          ruleType: 'multi',
          groupToReplace: 'multiAndNot',
          multiGroup: {
            multiAndNot: ['bath-mstrshower', 'stru-2ftsidegargside']
          },
          layerPriority: 5
        },
        {
          altHref: 'bath-mstrshower and stru-2ftsidegargside',
          floor: 2,
          ruleType: 'multi',
          groupToReplace: 'multiAnd',
          multiGroup: {
            multiAnd: ['bath-mstrshower', 'stru-2ftsidegargside']
          },
          layerPriority: 5
        },
        {
          altHref: 'bath-mstrgardentub and not stru-2ftsidegargside',
          floor: 2,
          ruleType: 'multi',
          groupToReplace: 'multiAndNot',
          multiGroup: {
            multiAndNot: ['bath-mstrgardentub', 'stru-2ftsidegargside']
          },
          layerPriority: 5
        },
        {
          altHref: 'bath-mstrgardentub and stru-2ftsidegargside',
          floor: 2,
          ruleType: 'multi',
          groupToReplace: 'multiAnd',
          multiGroup: {
            multiAnd: ['bath-mstrgardentub', 'stru-2ftsidegargside']
          },
          layerPriority: 5
        },
        {
          altHref: 'bath-mstrtileshower and not stru-2ftsidegargside',
          floor: 2,
          ruleType: 'multi',
          groupToReplace: 'multiAndNot',
          multiGroup: {
            multiAndNot: ['bath-mstrtileshower', 'stru-2ftsidegargside']
          },
          layerPriority: 5
        },
        {
          altHref: 'bath-mstrtileshower and stru-2ftsidegargside',
          floor: 2,
          ruleType: 'multi',
          groupToReplace: 'multiAnd',
          multiGroup: {
            multiAnd: ['bath-mstrtileshower', 'stru-2ftsidegargside']
          },
          layerPriority: 5
        },
        {
          altHref:
            'dvgslstcorn or woodlstcorn or eleclstcorn or dvgscabcorn or eleccabcorn and not stru-2ftsidegargopp and not stru-2ftrear',
          floor: 2,
          ruleType: 'multi',
          groupToReplace: 'multiOr',
          multiGroup: {
            multiOr: [
              'dvgslstcorn',
              'woodlstcorn',
              'eleclstcorn',
              'dvgscabcorn',
              'eleccabcorn'
            ],
            multiAndNot: ['stru-2ftsidegargopp', 'stru-2ftrear']
          },
          layerPriority: 5
        },
        {
          altHref:
            'dvgslstcorn or woodlstcorn or eleclstcorn or dvgscabcorn or eleccabcorn and not stru-2ftsidegargopp and stru-2ftrear',
          floor: 2,
          ruleType: 'multi',
          groupToReplace: 'multiOr',
          multiGroup: {
            multiOr: [
              'dvgslstcorn',
              'woodlstcorn',
              'eleclstcorn',
              'dvgscabcorn',
              'eleccabcorn'
            ],
            multiAnd: ['stru-2ftrear'],
            multiAndNot: ['stru-2ftsidegargopp']
          },
          layerPriority: 5
        },
        {
          altHref:
            'dvgslstcorn or woodlstcorn or eleclstcorn or dvgscabcorn or eleccabcorn and stru-2ftsidegargopp and not stru-2ftrear',
          floor: 2,
          ruleType: 'multi',
          groupToReplace: 'multiOr',
          multiGroup: {
            multiOr: [
              'dvgslstcorn',
              'woodlstcorn',
              'eleclstcorn',
              'dvgscabcorn',
              'eleccabcorn'
            ],
            multiAnd: ['stru-2ftsidegargopp'],
            multiAndNot: ['stru-2ftrear']
          },
          layerPriority: 5
        },
        {
          altHref:
            'dvgslstcorn or woodlstcorn or eleclstcorn or dvgscabcorn or eleccabcorn and stru-2ftsidegargopp and stru-2ftrear',
          floor: 2,
          ruleType: 'multi',
          groupToReplace: 'multiOr',
          multiGroup: {
            multiOr: [
              'dvgslstcorn',
              'woodlstcorn',
              'eleclstcorn',
              'dvgscabcorn',
              'eleccabcorn'
            ],
            multiAnd: ['stru-2ftsidegargopp', 'stru-2ftrear']
          },
          layerPriority: 5
        },
        {
          altHref: 'elev_b and not stru-2ftsidegargopp',
          floor: 2,
          ruleType: 'multi',
          groupToReplace: 'multiAndNot',
          multiGroup: { multiAndNot: ['elev_b', 'stru-2ftsidegargopp'] },
          layerPriority: 3
        },
        {
          altHref: 'elev_b and stru-2ftsidegargopp',
          floor: 2,
          ruleType: 'multi',
          groupToReplace: 'multiAnd',
          multiGroup: { multiAnd: ['elev_b', 'stru-2ftsidegargopp'] },
          layerPriority: 3
        },
        {
          altHref: 'elev_tudor and not stru-2ftsidegargopp',
          floor: 2,
          ruleType: 'multi',
          groupToReplace: 'multiAndNot',
          multiGroup: { multiAndNot: ['elev_tudor', 'stru-2ftsidegargopp'] },
          layerPriority: 3
        },
        {
          altHref: 'elev_tudor and stru-2ftsidegargopp',
          floor: 2,
          ruleType: 'multi',
          groupToReplace: 'multiAnd',
          multiGroup: { multiAnd: ['elev_tudor', 'stru-2ftsidegargopp'] },
          layerPriority: 3
        },
        {
          altHref:
            'fdtn_buf8 or fdtn_buf9 or fdtn_bup8 or fdtn_bup9 and not stru-bnrm',
          floor: 2,
          ruleType: 'multi',
          groupToReplace: 'multiOr',
          multiGroup: {
            multiOr: ['fdtn_buf8', 'fdtn_buf9', 'fdtn_bup8', 'fdtn_bup9'],
            multiAndNot: ['stru-bnrm']
          },
          layerPriority: 3
        },
        {
          altHref:
            'fdtn_buf8 or fdtn_buf9 or fdtn_bup8 or fdtn_bup9 and stru-bnrm',
          floor: 2,
          ruleType: 'multi',
          groupToReplace: 'multiOr',
          multiGroup: {
            multiOr: ['fdtn_buf8', 'fdtn_buf9', 'fdtn_bup8', 'fdtn_bup9'],
            multiAnd: ['stru-bnrm']
          },
          layerPriority: 3
        },
        {
          altHref: 'gar_2extgargside and not stru-2ftsidegargside',
          floor: 2,
          ruleType: 'multi',
          groupToReplace: 'multiAndNot',
          multiGroup: {
            multiAndNot: ['gar_2extgargside', 'stru-2ftsidegargside']
          },
          layerPriority: 3
        },
        {
          altHref: 'gar_2extgargside and stru-2ftsidegargside',
          floor: 2,
          ruleType: 'multi',
          groupToReplace: 'multiAnd',
          multiGroup: {
            multiAnd: ['gar_2extgargside', 'stru-2ftsidegargside']
          },
          layerPriority: 4
        },
        {
          altHref: 'gar_4extgargside and not stru-2ftsidegargside',
          floor: 2,
          ruleType: 'multi',
          groupToReplace: 'multiAndNot',
          multiGroup: {
            multiAndNot: ['gar_4extgargside', 'stru-2ftsidegargside']
          },
          layerPriority: 3
        },
        {
          altHref: 'gar_4extgargside and stru-2ftsidegargside',
          floor: 2,
          ruleType: 'multi',
          groupToReplace: 'multiAnd',
          multiGroup: {
            multiAnd: ['gar_4extgargside', 'stru-2ftsidegargside']
          },
          layerPriority: 4
        },
        {
          altHref: 'gar_6extgargside and not stru-2ftsidegargside',
          floor: 2,
          ruleType: 'multi',
          groupToReplace: 'multiAndNot',
          multiGroup: {
            multiAndNot: ['gar_6extgargside', 'stru-2ftsidegargside']
          },
          layerPriority: 3
        },
        {
          altHref: 'gar_6extgargside and stru-2ftsidegargside',
          floor: 2,
          ruleType: 'multi',
          groupToReplace: 'multiAnd',
          multiGroup: {
            multiAnd: ['gar_6extgargside', 'stru-2ftsidegargside']
          },
          layerPriority: 4
        },
        {
          altHref: 'stru-2ftsidegargopp join stru-2ftrear',
          floor: 2,
          ruleType: 'multi',
          groupToReplace: 'multiJoin',
          multiGroup: { multiJoin: ['stru-2ftsidegargopp', 'stru-2ftrear'] },
          layerPriority: 4
        },
        {
          altHref: 'stru-2ftsidegargside join stru-2ftrear',
          floor: 2,
          ruleType: 'multi',
          groupToReplace: 'multiJoin',
          multiGroup: { multiJoin: ['stru-2ftsidegargside', 'stru-2ftrear'] },
          layerPriority: 4
        },
        {
          altHref: 'stru-bnrm and not stru-2ftsidegargopp',
          floor: 2,
          ruleType: 'multi',
          groupToReplace: 'multiAndNot',
          multiGroup: { multiAndNot: ['stru-bnrm', 'stru-2ftsidegargopp'] },
          layerPriority: 3
        },
        {
          altHref: 'stru-bnrm and stru-2ftsidegargopp',
          floor: 2,
          ruleType: 'multi',
          groupToReplace: 'multiAnd',
          multiGroup: { multiAnd: ['stru-bnrm', 'stru-2ftsidegargopp'] },
          layerPriority: 5
        },
        {
          altHref: 'stru-sunroom and not stru-2ftrear',
          floor: 2,
          ruleType: 'multi',
          groupToReplace: 'multiAndNot',
          multiGroup: { multiAndNot: ['stru-sunroom', 'stru-2ftrear'] },
          layerPriority: 3
        },
        {
          altHref: 'stru-sunroom and stru-2ftrear',
          floor: 2,
          ruleType: 'multi',
          groupToReplace: 'multiAnd',
          multiGroup: { multiAnd: ['stru-sunroom', 'stru-2ftrear'] },
          layerPriority: 3
        }
      ]
    },
    // Chestnut
    869: {
      floorOnAppLoad: 2,
      garageBlacklist: [],
      selectionsBlacklist: {
        0: [],
        1: [],
        2: [
          'cabs-island-level',
          'msbt-msbtlayout',
          'stru-sunroom',
          'eleccabcorn||',
          'eleclstcorn||'
        ],
        3: []
      },
      elevationBlacklist: [],
      optionsWhitelist: {
        0: [],
        1: [
          'fdtn_buf8',
          'fdtn_buf9',
          'fdtn_bup8',
          'fdtn_bup9',
          'fdtn-bsbd',
          'fdtn-bsmtfinishedfullbath',
          'fdtn_bsmtfin_full',
          'fdtn_bsmtfin_part'
        ],
        2: [
          'bath-mstrdelmastbath',
          'stru-2ftsidegargside',
          'bath-mstrgardentub',
          'bath-mstrshower',
          'bath-mstrtileshower',
          'cabs-kitc-layout-100-3642',
          'stru-2ftsidegargopp',
          'dvgslstcorn',
          'stru-2ftrear',
          'dvgscabcorn',
          'elev_craftsman',
          'elev_tudor',
          'fdtn_buf8',
          'fdtn_buf9',
          'fdtn_bup8',
          'fdtn_bup9',
          'gar_2extgargside',
          'gar_4extgargside',
          'gar_6extgargside',
          'stru-4ftrearktnk',
          'woodlstcorn',
          'elev_b',
          'stru-bnrm',
          'plmb-watersoftrough',
          'str-bon-full'
        ],
        3: [
          'stru_bnrm_layout_bnrm',
          'stru_bnrm_layout_bnrmbnbt1bed'
        ]
      },
      customRules: [
        {
          altHref: 'fdtn-bsbd',
          floor: 1,
          layerPriority: 5
        },
        {
          altHref: 'fdtn-bsmtfinishedfullbath',
          floor: 1,
          layerPriority: 5
        },
        {
          altHref: 'fdtn_bsmtfin_full',
          floor: 1,
          layerPriority: 5
        },
        {
          altHref: 'fdtn_bsmtfin_part',
          floor: 1,
          layerPriority: 5
        },
        {
          altHref: 'fdtn_buf8 or fdtn_buf9',
          floor: 1,
          ruleType: 'multi',
          groupToReplace: 'multiOr',
          multiGroup: { multiOr: ['fdtn_buf8', 'fdtn_buf9'] },
          layerPriority: 2
        },
        {
          altHref: 'fdtn_bup8 or fdtn_bup9',
          floor: 1,
          ruleType: 'multi',
          groupToReplace: 'multiOr',
          multiGroup: { multiOr: ['fdtn_bup8', 'fdtn_bup9'] },
          layerPriority: 1
        },
        {
          altHref: 'bath-mstrdelmastbath and not stru-2ftsidegargside',
          floor: 2,
          ruleType: 'multi',
          groupToReplace: 'multiAndNot',
          multiGroup: {
            multiAndNot: ['bath-mstrdelmastbath', 'stru-2ftsidegargside']
          },
          layerPriority: 2
        },
        {
          altHref: 'bath-mstrdelmastbath and stru-2ftsidegargside',
          floor: 2,
          ruleType: 'multi',
          groupToReplace: 'multiAnd',
          multiGroup: {
            multiAnd: ['bath-mstrdelmastbath', 'stru-2ftsidegargside']
          },
          layerPriority: 4
        },
        {
          altHref: 'bath-mstrgardentub and not stru-2ftsidegargside',
          floor: 2,
          ruleType: 'multi',
          groupToReplace: 'multiAndNot',
          multiGroup: {
            multiAndNot: ['bath-mstrgardentub', 'stru-2ftsidegargside']
          },
          layerPriority: 2
        },
        {
          altHref: 'bath-mstrgardentub and stru-2ftsidegargside',
          floor: 2,
          ruleType: 'multi',
          groupToReplace: 'multiAnd',
          multiGroup: {
            multiAnd: ['bath-mstrgardentub', 'stru-2ftsidegargside']
          },
          layerPriority: 4
        },
        {
          altHref: 'bath-mstrshower and not stru-2ftsidegargside',
          floor: 2,
          ruleType: 'multi',
          groupToReplace: 'multiAndNot',
          multiGroup: {
            multiAndNot: ['bath-mstrshower', 'stru-2ftsidegargside']
          },
          layerPriority: 2
        },
        {
          altHref: 'bath-mstrshower and stru-2ftsidegargside',
          floor: 2,
          ruleType: 'multi',
          groupToReplace: 'multiAnd',
          multiGroup: {
            multiAnd: ['bath-mstrshower', 'stru-2ftsidegargside']
          },
          layerPriority: 4
        },
        {
          altHref: 'bath-mstrtileshower and not stru-2ftsidegargside',
          floor: 2,
          ruleType: 'multi',
          groupToReplace: 'multiAndNot',
          multiGroup: {
            multiAndNot: ['bath-mstrtileshower', 'stru-2ftsidegargside']
          },
          layerPriority: 2
        },
        {
          altHref: 'bath-mstrtileshower and stru-2ftsidegargside',
          floor: 2,
          ruleType: 'multi',
          groupToReplace: 'multiAnd',
          multiGroup: {
            multiAnd: ['bath-mstrtileshower', 'stru-2ftsidegargside']
          },
          layerPriority: 4
        },
        {
          altHref: 'cabs-kitc-layout-100-3642 and not stru-2ftsidegargopp',
          floor: 2,
          ruleType: 'multi',
          groupToReplace: 'multiAndNot',
          multiGroup: {
            multiAndNot: ['cabs-kitc-layout-100-3642', 'stru-2ftsidegargopp']
          },
          layerPriority: 2
        },
        {
          altHref: 'cabs-kitc-layout-100-3642 and stru-2ftsidegargopp',
          floor: 2,
          ruleType: 'multi',
          groupToReplace: 'multiAnd',
          multiGroup: {
            multiAnd: ['cabs-kitc-layout-100-3642', 'stru-2ftsidegargopp']
          },
          layerPriority: 2
        },
        {
          altHref: 'dvgslstcorn and not stru-2ftrear',
          floor: 2,
          ruleType: 'multi',
          groupToReplace: 'multiAndNot',
          multiGroup: { multiAndNot: ['dvgslstcorn', 'stru-2ftrear'] },
          layerPriority: 5
        },
        {
          altHref: 'dvgslstcorn or dvgscabcorn and not stru-2ftrear',
          floor: 2,
          ruleType: 'multi',
          groupToReplace: 'multiOr',
          multiGroup: {
            multiOr: ['dvgslstcorn', 'dvgscabcorn'],
            multiAndNot: ['stru-2ftrear']
          },
          layerPriority: 5
        },
        {
          altHref: 'dvgslstcorn or dvgscabcorn and stru-2ftrear',
          floor: 2,
          ruleType: 'multi',
          groupToReplace: 'multiOr',
          multiGroup: {
            multiOr: ['dvgslstcorn', 'dvgscabcorn'],
            multiAnd: ['stru-2ftrear']
          },
          layerPriority: 5
        },
        {
          altHref: 'elev_craftsman and not stru-2ftsidegargopp',
          floor: 2,
          ruleType: 'multi',
          groupToReplace: 'multiAndNot',
          multiGroup: {
            multiAndNot: ['elev_craftsman', 'stru-2ftsidegargopp']
          },
          layerPriority: 2
        },
        {
          altHref: 'elev_craftsman and stru-2ftsidegargopp',
          floor: 2,
          ruleType: 'multi',
          groupToReplace: 'multiAnd',
          multiGroup: { multiAnd: ['elev_craftsman', 'stru-2ftsidegargopp'] },
          layerPriority: 2
        },
        {
          altHref: 'elev_tudor and not stru-2ftsidegargopp',
          floor: 2,
          ruleType: 'multi',
          groupToReplace: 'multiAndNot',
          multiGroup: { multiAndNot: ['elev_tudor', 'stru-2ftsidegargopp'] },
          layerPriority: 2
        },
        {
          altHref: 'elev_tudor and stru-2ftsidegargopp',
          floor: 2,
          ruleType: 'multi',
          groupToReplace: 'multiAnd',
          multiGroup: { multiAnd: ['elev_tudor', 'stru-2ftsidegargopp'] },
          layerPriority: 2
        },
        {
          altHref:
            'fdtn_buf8 or fdtn_buf9 or fdtn_bup8 or fdtn_bup9 and not stru-2ftsidegargside',
          floor: 2,
          ruleType: 'multi',
          groupToReplace: 'multiOr',
          multiGroup: {
            multiOr: ['fdtn_buf8', 'fdtn_buf9', 'fdtn_bup8', 'fdtn_bup9'],
            multiAndNot: ['stru-2ftsidegargside']
          },
          layerPriority: 5
        },
        {
          altHref:
            'fdtn_buf8 or fdtn_buf9 or fdtn_bup8 or fdtn_bup9 and stru-2ftsidegargside',
          floor: 2,
          ruleType: 'multi',
          groupToReplace: 'multiOr',
          multiGroup: {
            multiOr: ['fdtn_buf8', 'fdtn_buf9', 'fdtn_bup8', 'fdtn_bup9'],
            multiAnd: ['stru-2ftsidegargside']
          },
          layerPriority: 5
        },
        {
          altHref: 'gar_2extgargside and not stru-2ftsidegargside',
          floor: 2,
          ruleType: 'multi',
          groupToReplace: 'multiAndNot',
          multiGroup: {
            multiAndNot: ['gar_2extgargside', 'stru-2ftsidegargside']
          },
          layerPriority: 2
        },
        {
          altHref: 'gar_2extgargside and stru-2ftsidegargside',
          floor: 2,
          ruleType: 'multi',
          groupToReplace: 'multiAnd',
          multiGroup: {
            multiAnd: ['gar_2extgargside', 'stru-2ftsidegargside']
          },
          layerPriority: 5
        },
        {
          altHref: 'gar_4extgargside and not stru-2ftsidegargside',
          floor: 2,
          ruleType: 'multi',
          groupToReplace: 'multiAndNot',
          multiGroup: {
            multiAndNot: ['gar_4extgargside', 'stru-2ftsidegargside']
          },
          layerPriority: 2
        },
        {
          altHref: 'gar_4extgargside and stru-2ftsidegargside',
          floor: 2,
          ruleType: 'multi',
          groupToReplace: 'multiAnd',
          multiGroup: {
            multiAnd: ['gar_4extgargside', 'stru-2ftsidegargside']
          },
          layerPriority: 5
        },
        {
          altHref: 'gar_6extgargside and not stru-2ftsidegargside',
          floor: 2,
          ruleType: 'multi',
          groupToReplace: 'multiAndNot',
          multiGroup: {
            multiAndNot: ['gar_6extgargside', 'stru-2ftsidegargside']
          },
          layerPriority: 5
        },
        {
          altHref: 'gar_6extgargside and stru-2ftsidegargside',
          floor: 2,
          ruleType: 'multi',
          groupToReplace: 'multiAnd',
          multiGroup: {
            multiAnd: ['gar_6extgargside', 'stru-2ftsidegargside']
          },
          layerPriority: 5
        },
        {
          altHref: 'stru-2ftsidegargopp join stru-2ftrear',
          floor: 2,
          ruleType: 'multi',
          groupToReplace: 'multiJoin',
          multiGroup: { multiJoin: ['stru-2ftsidegargopp', 'stru-2ftrear'] },
          layerPriority: 4
        },
        {
          altHref: 'stru-2ftsidegargside join stru-2ftrear',
          floor: 2,
          ruleType: 'multi',
          groupToReplace: 'multiJoin',
          multiGroup: { multiJoin: ['stru-2ftsidegargside', 'stru-2ftrear'] },
          layerPriority: 4
        },
        {
          altHref:
            'stru-4ftrearktnk and not stru-2ftsidegargopp and not stru-2ftrear',
          floor: 2,
          ruleType: 'multi',
          groupToReplace: 'multiAndNot',
          multiGroup: {
            multiAndNot: [
              'stru-4ftrearktnk',
              'stru-2ftsidegargopp',
              'stru-2ftrear'
            ]
          },
          layerPriority: 5
        },
        {
          altHref:
            'stru-4ftrearktnk and not stru-2ftsidegargopp and stru-2ftrear',
          floor: 2,
          ruleType: 'multi',
          groupToReplace: 'multiAndNot',
          multiGroup: {
            multiAnd: ['stru-2ftrear'],
            multiAndNot: ['stru-4ftrearktnk', 'stru-2ftsidegargopp']
          },
          layerPriority: 4
        },
        {
          altHref:
            'stru-4ftrearktnk and stru-2ftsidegargopp and not stru-2ftrear',
          floor: 2,
          ruleType: 'multi',
          groupToReplace: 'multiAnd',
          multiGroup: {
            multiAnd: ['stru-4ftrearktnk', 'stru-2ftsidegargopp'],
            multiAndNot: ['stru-2ftrear']
          },
          layerPriority: 5
        },
        {
          altHref:
            'stru-4ftrearktnk and stru-2ftsidegargopp and stru-2ftrear',
          floor: 2,
          ruleType: 'multi',
          groupToReplace: 'multiAnd',
          multiGroup: {
            multiAnd: [
              'stru-4ftrearktnk',
              'stru-2ftsidegargopp',
              'stru-2ftrear'
            ]
          },
          layerPriority: 5
        },
        {
          altHref: 'woodlstcorn and not stru-2ftrear',
          floor: 2,
          ruleType: 'multi',
          groupToReplace: 'multiAndNot',
          multiGroup: { multiAndNot: ['woodlstcorn', 'stru-2ftrear'] },
          layerPriority: 5
        },
        {
          altHref: 'woodlstcorn and stru-2ftrear',
          floor: 2,
          ruleType: 'multi',
          groupToReplace: 'multiAnd',
          multiGroup: { multiAnd: ['woodlstcorn', 'stru-2ftrear'] },
          layerPriority: 5
        }
      ]
    },
    // Walnut
    870: {
      floorOnAppLoad: 1,
      optionsWhitelist: {
        0: [],
        1: [
          'bath-mstrgardentub',
          'garg_2extgargfront',
          'stru-2ftsidegargopp',
          'stru-2ftrear',
          'bath-mstrshower',
          'dvgscabcorn',
          'dvgslstcorn',
          'gar_2extgargside',
          'stru-2ftsidegargside',
          'gar_4extgargside',
          'gar_6extgargside',
          'stru-4ftrearktnk',
          'stru-sunroom',
          'woodlstcorn',
          'elev_b',
          'elev_c',
          'plmb-watersoftrough'
        ]
      },
      garageBlacklist: [],
      selectionsBlacklist: {
        0: [],
        1: [
          'cabs-island-level',
          'msbt-msbtlayout',
          'fdtn-foundation',
          'eleclstcorn||',
          'eleccabcorn||'
        ]
      },
      elevationBlacklist: [
        'elev_tudor||',
        'elev_craftsman||',
        'elev_traditional||'
      ],
      customRules: [
        {
          altHref:
            'bath-mstrgardentub and not stru-2ftsidegargopp and not stru-2ftrear',
          floor: 1,
          ruleType: 'multi',
          groupToReplace: 'multiAndNot',
          multiGroup: {
            multiAndNot: [
              'bath-mstrgardentub',
              'stru-2ftsidegargopp',
              'stru-2ftrear'
            ]
          },
          layerPriority: 3
        },
        {
          altHref:
            'bath-mstrgardentub and not stru-2ftsidegargopp and stru-2ftrear',
          floor: 1,
          ruleType: 'multi',
          groupToReplace: 'multiAndNot',
          multiGroup: {
            multiAnd: ['stru-2ftrear'],
            multiAndNot: ['bath-mstrgardentub', 'stru-2ftsidegargopp']
          },
          layerPriority: 4
        },
        {
          altHref:
            'bath-mstrgardentub and stru-2ftsidegargopp and not stru-2ftrear',
          floor: 1,
          ruleType: 'multi',
          groupToReplace: 'multiAnd',
          multiGroup: {
            multiAnd: ['bath-mstrgardentub', 'stru-2ftsidegargopp'],
            multiAndNot: ['stru-2ftrear']
          },
          layerPriority: 3
        },
        {
          altHref:
            'bath-mstrgardentub and stru-2ftsidegargopp and stru-2ftrear',
          floor: 1,
          ruleType: 'multi',
          groupToReplace: 'multiAnd',
          multiGroup: {
            multiAnd: [
              'bath-mstrgardentub',
              'stru-2ftsidegargopp',
              'stru-2ftrear'
            ]
          },
          layerPriority: 4
        },
        {
          altHref:
            'bath-mstrshower and not stru-2ftsidegargopp and not stru-2ftrear',
          floor: 1,
          ruleType: 'multi',
          groupToReplace: 'multiAndNot',
          multiGroup: {
            multiAndNot: [
              'bath-mstrshower',
              'stru-2ftsidegargopp',
              'stru-2ftrear'
            ]
          },
          layerPriority: 3
        },
        {
          altHref:
            'bath-mstrshower and not stru-2ftsidegargopp and stru-2ftrear',
          floor: 1,
          ruleType: 'multi',
          groupToReplace: 'multiAndNot',
          multiGroup: {
            multiAnd: ['stru-2ftrear'],
            multiAndNot: ['bath-mstrshower', 'stru-2ftsidegargopp']
          },
          layerPriority: 3
        },
        {
          altHref:
            'bath-mstrshower and stru-2ftsidegargopp and not stru-2ftrear',
          floor: 1,
          ruleType: 'multi',
          groupToReplace: 'multiAnd',
          multiGroup: {
            multiAnd: ['bath-mstrshower', 'stru-2ftsidegargopp'],
            multiAndNot: ['stru-2ftrear']
          },
          layerPriority: 3
        },
        {
          altHref: 'bath-mstrshower and stru-2ftsidegargopp and stru-2ftrear',
          floor: 1,
          ruleType: 'multi',
          groupToReplace: 'multiAnd',
          multiGroup: {
            multiAnd: [
              'bath-mstrshower',
              'stru-2ftsidegargopp',
              'stru-2ftrear'
            ]
          },
          layerPriority: 3
        },
        {
          altHref: 'dvgscabcorn or dvgslstcorn and not stru-2ftrear',
          floor: 1,
          ruleType: 'multi',
          groupToReplace: 'multiOr',
          multiGroup: {
            multiOr: ['dvgscabcorn', 'dvgslstcorn'],
            multiAndNot: ['stru-2ftrear']
          },
          layerPriority: 3
        },
        {
          altHref: 'dvgscabcorn or dvgslstcorn and stru-2ftrear',
          floor: 1,
          ruleType: 'multi',
          groupToReplace: 'multiOr',
          multiGroup: {
            multiOr: ['dvgscabcorn', 'dvgslstcorn'],
            multiAnd: ['stru-2ftrear']
          },
          layerPriority: 3
        },
        {
          altHref: 'gar_2extgargside and not stru-2ftsidegargside',
          floor: 1,
          ruleType: 'multi',
          groupToReplace: 'multiAndNot',
          multiGroup: {
            multiAndNot: ['gar_2extgargside', 'stru-2ftsidegargside']
          },
          layerPriority: 2
        },
        {
          altHref: 'gar_2extgargside and stru-2ftsidegargside',
          floor: 1,
          ruleType: 'multi',
          groupToReplace: 'multiAnd',
          multiGroup: {
            multiAnd: ['gar_2extgargside', 'stru-2ftsidegargside']
          },
          layerPriority: 4
        },
        {
          altHref: 'gar_4extgargside and not stru-2ftsidegargside',
          floor: 1,
          ruleType: 'multi',
          groupToReplace: 'multiAndNot',
          multiGroup: {
            multiAndNot: ['gar_4extgargside', 'stru-2ftsidegargside']
          },
          layerPriority: 2
        },
        {
          altHref: 'gar_4extgargside and stru-2ftsidegargside',
          floor: 1,
          ruleType: 'multi',
          groupToReplace: 'multiAnd',
          multiGroup: {
            multiAnd: ['gar_4extgargside', 'stru-2ftsidegargside']
          },
          layerPriority: 4
        },
        {
          altHref: 'gar_6extgargside and not stru-2ftsidegargside',
          floor: 1,
          ruleType: 'multi',
          groupToReplace: 'multiAndNot',
          multiGroup: {
            multiAndNot: ['gar_6extgargside', 'stru-2ftsidegargside']
          },
          layerPriority: 2
        },
        {
          altHref: 'gar_6extgargside and stru-2ftsidegargside',
          floor: 1,
          ruleType: 'multi',
          groupToReplace: 'multiAnd',
          multiGroup: {
            multiAnd: ['gar_6extgargside', 'stru-2ftsidegargside']
          },
          layerPriority: 4
        },
        {
          altHref: 'stru-2ftsidegargopp join stru-2ftrear',
          floor: 1,
          ruleType: 'multi',
          groupToReplace: 'multiJoin',
          multiGroup: { multiJoin: ['stru-2ftsidegargopp', 'stru-2ftrear'] },
          layerPriority: 4
        },
        {
          altHref: 'stru-2ftsidegargside join stru-2ftrear',
          floor: 1,
          ruleType: 'multi',
          groupToReplace: 'multiJoin',
          multiGroup: { multiJoin: ['stru-2ftsidegargside', 'stru-2ftrear'] },
          layerPriority: 4
        },
        {
          altHref:
            'stru-4ftrearktnk and not stru-2ftrear and not stru-2ftsidegargside',
          floor: 1,
          ruleType: 'multi',
          groupToReplace: 'multiAndNot',
          multiGroup: {
            multiAndNot: [
              'stru-4ftrearktnk',
              'stru-2ftrear',
              'stru-2ftsidegargside'
            ]
          },
          layerPriority: 3
        },
        {
          altHref:
            'stru-4ftrearktnk and not stru-2ftrear and stru-2ftsidegargside',
          floor: 1,
          ruleType: 'multi',
          groupToReplace: 'multiAndNot',
          multiGroup: {
            multiAnd: ['stru-2ftsidegargside'],
            multiAndNot: ['stru-4ftrearktnk', 'stru-2ftrear']
          },
          layerPriority: 5
        },
        {
          altHref:
            'stru-4ftrearktnk and stru-2ftrear and not stru-2ftsidegargside',
          floor: 1,
          ruleType: 'multi',
          groupToReplace: 'multiAnd',
          multiGroup: {
            multiAnd: ['stru-4ftrearktnk', 'stru-2ftrear'],
            multiAndNot: ['stru-2ftsidegargside']
          },
          layerPriority: 5
        },
        {
          altHref:
            'stru-4ftrearktnk and stru-2ftrear and stru-2ftsidegargside',
          floor: 1,
          ruleType: 'multi',
          groupToReplace: 'multiAnd',
          multiGroup: {
            multiAnd: [
              'stru-4ftrearktnk',
              'stru-2ftrear',
              'stru-2ftsidegargside'
            ]
          },
          layerPriority: 5
        },
        {
          altHref:
            'stru-sunroom and stru-2ftrear and not stru-2ftsidegargside',
          floor: 1,
          ruleType: 'multi',
          groupToReplace: 'multiAnd',
          multiGroup: {
            multiAnd: ['stru-sunroom', 'stru-2ftrear'],
            multiAndNot: ['stru-2ftsidegargside']
          },
          layerPriority: 5
        },
        {
          altHref:
            'stru-sunroom and stru-2ftsidegargside and not stru-2ftrear',
          floor: 1,
          ruleType: 'multi',
          groupToReplace: 'multiAnd',
          multiGroup: {
            multiAnd: ['stru-sunroom', 'stru-2ftsidegargside'],
            multiAndNot: ['stru-2ftrear']
          },
          layerPriority: 5
        },
        {
          altHref: 'stru-sunroom and stru-2ftrear and stru-2ftsidegargside',
          floor: 1,
          ruleType: 'multi',
          groupToReplace: 'multiAnd',
          multiGroup: {
            multiAnd: ['stru-sunroom', 'stru-2ftrear', 'stru-2ftsidegargside']
          },
          layerPriority: 5
        },
        {
          altHref: 'woodlstcorn and not stru-2ftrear',
          floor: 1,
          ruleType: 'multi',
          groupToReplace: 'multiAndNot',
          multiGroup: { multiAndNot: ['woodlstcorn', 'stru-2ftrear'] },
          layerPriority: 3
        },
        {
          altHref: 'woodlstcorn and stru-2ftrear',
          floor: 1,
          ruleType: 'multi',
          groupToReplace: 'multiAnd',
          multiGroup: { multiAnd: ['woodlstcorn', 'stru-2ftrear'] },
          layerPriority: 3
        }
      ]
    },
    // Aspen
    872: {
      floorOnAppLoad: 1,
      optionsWhitelist: {
        0: [],
        1: [
          'dvgslstcorn',
          'eleclstcorn',
          'woodlstcorn',
          'dvgscabcorn',
          'stru-2ftrear',
          'gar_2extgargside',
          'stru-2ftsidegargside',
          'gar_4extgargside',
          'gar_6extgargside',
          'stru-sunroom',
          'elev_craftsman',
          'elev_traditional',
          'elev_tudor',
          'cabs-kitc-island-level-000'
        ],
        2: [
          'elev_b',
          'stru-2ftsidegargside',
          'elev_craftsman',
          'elev_traditional',
          'stru-2ftrear',
          'eleccabcorn',
          'dvgslstcorn',
          'eleclstcorn',
          'woodlstcorn',
          'dvgscabcorn',
          'bath-dblbowlmsbt',
          'bath-mstrdelmastbath',
          'bath-mstrgardentub',
          'bath-mstrshower',
          'elev_tudor',
          'stru_bed5iloloft'
        ]
      },
      garageBlacklist: [],
      selectionsBlacklist: {
        0: [],
        1: [
          'stru_bed5iloloft',
          'fdtn-foundation',
          'msbt-msbtlayout',
          'eleccabcorn||'
        ],
        2: [
          // 'msbt-msbtlayout',
          'bath-mstrshower|chrome frame|obscure glass',
          'bath-mstrshower|chrome frame|clear glass',
          'bath-mstrdelmastbath|chrome frame|obscure glass',
          'bath-mstrdelmastbath|chrome frame|clear glass',
          'fire_fireplace'
        ]
      },
      elevationBlacklist: [],
      customRules: [
        {
          altHref: 'woodlstcorn or dvgslstcorn or eleclstcorn or dvgscabcorn and not stru-2ftrear',
          floor: 1,
          ruleType: 'multi',
          groupToReplace: 'multiOr',
          multiGroup: {
            multiOr: ['woodlstcorn', 'dvgslstcorn', 'eleclstcorn', 'dvgscabcorn'],
            multiAndNot: ['stru-2ftrear']
          },
          layerPriority: 4
        },
        {
          altHref: 'woodlstcorn or dvgslstcorn or eleclstcorn or dvgscabcorn and stru-2ftrear',
          floor: 1,
          ruleType: 'multi',
          groupToReplace: 'multiOr',
          multiGroup: {
            multiOr: ['woodlstcorn', 'dvgslstcorn', 'eleclstcorn', 'dvgscabcorn'],
            multiAnd: ['stru-2ftrear']
          },
          layerPriority: 4
        },
        {
          altHref: 'gar_2extgargside and not stru-2ftsidegargside',
          floor: 1,
          ruleType: 'multi',
          groupToReplace: 'multiAndNot',
          multiGroup: {
            multiAndNot: ['gar_2extgargside', 'stru-2ftsidegargside']
          },
          layerPriority: 2
        },
        {
          altHref: 'gar_2extgargside and stru-2ftsidegargside',
          floor: 1,
          ruleType: 'multi',
          groupToReplace: 'multiAnd',
          multiGroup: {
            multiAnd: ['gar_2extgargside', 'stru-2ftsidegargside']
          },
          layerPriority: 4
        },
        {
          altHref: 'gar_4extgargside and not stru-2ftsidegargside',
          floor: 1,
          ruleType: 'multi',
          groupToReplace: 'multiAndNot',
          multiGroup: {
            multiAndNot: ['gar_4extgargside', 'stru-2ftsidegargside']
          },
          layerPriority: 2
        },
        {
          altHref: 'gar_4extgargside and stru-2ftsidegargside',
          floor: 1,
          ruleType: 'multi',
          groupToReplace: 'multiAnd',
          multiGroup: {
            multiAnd: ['gar_4extgargside', 'stru-2ftsidegargside']
          },
          layerPriority: 4
        },
        {
          altHref: 'gar_6extgargside and not stru-2ftsidegargside',
          floor: 1,
          ruleType: 'multi',
          groupToReplace: 'multiAndNot',
          multiGroup: {
            multiAndNot: ['gar_6extgargside', 'stru-2ftsidegargside']
          },
          layerPriority: 2
        },
        {
          altHref: 'gar_6extgargside and stru-2ftsidegargside',
          floor: 1,
          ruleType: 'multi',
          groupToReplace: 'multiAnd',
          multiGroup: {
            multiAnd: ['gar_6extgargside', 'stru-2ftsidegargside']
          },
          layerPriority: 4
        },
        {
          altHref: 'stru-2ftsidegargside join stru-2ftrear',
          floor: 1,
          ruleType: 'multi',
          groupToReplace: 'multiJoin',
          multiGroup: { multiJoin: ['stru-2ftsidegargside', 'stru-2ftrear'] },
          layerPriority: 4
        },
        {
          altHref: 'stru-sunroom and not stru-2ftrear',
          floor: 1,
          ruleType: 'multi',
          groupToReplace: 'multiAndNot',
          multiGroup: { multiAndNot: ['stru-sunroom', 'stru-2ftrear'] },
          layerPriority: 2
        },
        {
          altHref: 'stru-sunroom and stru-2ftrear',
          floor: 1,
          ruleType: 'multi',
          groupToReplace: 'multiAnd',
          multiGroup: { multiAnd: ['stru-sunroom', 'stru-2ftrear'] },
          layerPriority: 5
        },
        {
          altHref: 'woodlstcorn or dvgslstcorn or eleclstcorn or dvgscabcorn or eleccabcorn and not stru-2ftrear',
          floor: 2,
          ruleType: 'multi',
          groupToReplace: 'multiOr',
          multiGroup: {
            multiOr: ['woodlstcorn', 'dvgslstcorn', 'eleclstcorn', 'dvgscabcorn', 'eleccabcorn'],
            multiAndNot: ['stru-2ftrear']
          },
          layerPriority: 4
        },
        {
          altHref: 'woodlstcorn or dvgslstcorn or eleclstcorn or dvgscabcorn or eleccabcorn and stru-2ftrear',
          floor: 2,
          ruleType: 'multi',
          groupToReplace: 'multiOr',
          multiGroup: {
            multiOr: ['woodlstcorn', 'dvgslstcorn', 'eleclstcorn', 'dvgscabcorn', 'eleccabcorn'],
            multiAnd: ['stru-2ftrear']
          },
          layerPriority: 4
        },
        {
          altHref: 'elev_b and not stru-2ftsidegargside',
          floor: 2,
          ruleType: 'multi',
          groupToReplace: 'multiAndNot',
          multiGroup: { multiAndNot: ['elev_b', 'stru-2ftsidegargside'] },
          layerPriority: 2
        },
        {
          altHref: 'elev_b and stru-2ftsidegargside',
          floor: 2,
          ruleType: 'multi',
          groupToReplace: 'multiAnd',
          multiGroup: { multiAnd: ['elev_b', 'stru-2ftsidegargside'] },
          layerPriority: 2
        },
        {
          altHref: 'elev_craftsman and not stru-2ftsidegargside',
          floor: 2,
          ruleType: 'multi',
          groupToReplace: 'multiAndNot',
          multiGroup: {
            multiAndNot: ['elev_craftsman', 'stru-2ftsidegargside']
          },
          layerPriority: 2
        },
        {
          altHref: 'elev_craftsman and stru-2ftsidegargside',
          floor: 2,
          ruleType: 'multi',
          groupToReplace: 'multiAnd',
          multiGroup: {
            multiAnd: ['elev_craftsman', 'stru-2ftsidegargside']
          },
          layerPriority: 2
        },
        {
          altHref: 'elev_traditional and not stru-2ftsidegargside',
          floor: 2,
          ruleType: 'multi',
          groupToReplace: 'multiAndNot',
          multiGroup: {
            multiAndNot: ['elev_traditional', 'stru-2ftsidegargside']
          },
          layerPriority: 2
        },
        {
          altHref: 'elev_traditional and stru-2ftsidegargside',
          floor: 2,
          ruleType: 'multi',
          groupToReplace: 'multiAnd',
          multiGroup: {
            multiAnd: ['elev_traditional', 'stru-2ftsidegargside']
          },
          layerPriority: 2
        },
        {
          altHref: 'stru-2ftsidegargside join stru-2ftrear',
          floor: 2,
          ruleType: 'multi',
          groupToReplace: 'multiJoin',
          multiGroup: { multiJoin: ['stru-2ftsidegargside', 'stru-2ftrear'] },
          layerPriority: 2
        },
      ]
    },
    // Spruce
    873: {
      floorOnAppLoad: 2,
      garageBlacklist: [],
      selectionsBlacklist: {
        0: [],
        1: [],
        2: [
          'cabs-island-level',
          'stru_enlargeloft',
          'cabs-desk',
          'eleccabcorn||'
        ],
        3: ['fire_fireplace', 'msbt-msbtlayout']
      },
      elevationBlacklist: [],
      optionsWhitelist: {
        0: [],
        1: [
          'fdtn_buf8',
          'fdtn_buf9',
          'fdtn_bsmtfin_part',
          'fdtn_bup8',
          'fdtn_bup9',
          'fdtn-bsbd',
          'fdtn-bsmtfinishedfullbath',
          'fdtn_bsmtfin_full'
        ],
        2: [
          'eleccabcorn',
          'eleclstcorn',
          'dvgscabcorn',
          'dvgslstcorn',
          'stru-2ftrear',
          'elev_craftsman',
          'stru-2ftsidegargside',
          'elev_traditional',
          'elev_tudor',
          'gar_2extgargside',
          'gar_4extgargside',
          'gar_6extgargside',
          'garg_3carfront',
          'stru-sunroom',
          'woodlstcorn',
          'cabs-kitc-island-level-000'
        ],
        3: [
          'elev_craftsman',
          'stru-2ftsidegargside',
          'stru-2ftrear',
          'elev_traditional',
          'elev_tudor',
          'bath-mstrtileshower',
          'bath-mstrshower',
          'bath-mstrgardentub',
          'bath-mstrdelmastbath',
          'stru_enlargeloft',
          'woodlstcorn',
          'eleccabcorn',
          'eleclstcorn',
          'dvgscabcorn',
          'dvgslstcorn',
        ]
      },
      customRules: [
        {
          altHref: 'fdtn_bsmtfin_full',
          floor: 1,
          layerPriority: 5
        },
        {
          altHref: 'fdtn-bsbd',
          floor: 1,
          layerPriority: 5
        },
        {
          altHref: 'fdtn-bsmtfinishedfullbath',
          floor: 1,
          layerPriority: 5
        },
        {
          altHref: 'fdtn_bsmtfin_part',
          floor: 1,
          layerPriority: 3
        },
        {
          altHref: 'fdtn_buf8 or fdtn_buf9 and fdtn_bsmtfin_part',
          floor: 1,
          ruleType: 'multi',
          groupToReplace: 'multiOr',
          multiGroup: {
            multiOr: ['fdtn_buf8', 'fdtn_buf9'],
            multiAnd: ['fdtn_bsmtfin_part']
          },
          layerPriority: 3
        },
        {
          altHref: 'fdtn_buf8 or fdtn_buf9',
          floor: 1,
          ruleType: 'multi',
          groupToReplace: 'multiOr',
          multiGroup: { multiOr: ['fdtn_buf8', 'fdtn_buf9'] },
          layerPriority: 1
        },
        {
          altHref: 'fdtn_bup8 or fdtn_bup9',
          floor: 1,
          ruleType: 'multi',
          groupToReplace: 'multiOr',
          multiGroup: { multiOr: ['fdtn_bup8', 'fdtn_bup9'] },
          layerPriority: 1
        },
        {
          altHref: 'dvgscabcorn or dvgslstcorn and stru-2ftrear',
          floor: 2,
          ruleType: 'multi',
          groupToReplace: 'multiOr',
          multiGroup: {
            multiOr: ['dvgscabcorn', 'dvgslstcorn'],
            multiAnd: ['stru-2ftrear']
          },
          layerPriority: 3
        },
        {
          altHref: 'dvgscabcorn or dvgslstcorn and not stru-2ftrear',
          floor: 2,
          ruleType: 'multi',
          groupToReplace: 'multiOr',
          multiGroup: {
            multiOr: ['dvgscabcorn', 'dvgslstcorn'],
            multiAndNot: ['stru-2ftrear']
          },
          layerPriority: 3
        },
        {
          altHref: 'elev_craftsman and not stru-2ftsidegargside',
          floor: 2,
          ruleType: 'multi',
          groupToReplace: 'multiAndNot',
          multiGroup: {
            multiAndNot: ['elev_craftsman', 'stru-2ftsidegargside']
          },
          layerPriority: 3
        },
        {
          altHref: 'elev_craftsman and stru-2ftsidegargside',
          floor: 2,
          ruleType: 'multi',
          groupToReplace: 'multiAnd',
          multiGroup: {
            multiAnd: ['elev_craftsman', 'stru-2ftsidegargside']
          },
          layerPriority: 3
        },
        {
          altHref: 'elev_traditional and not stru-2ftsidegargside',
          floor: 2,
          ruleType: 'multi',
          groupToReplace: 'multiAndNot',
          multiGroup: {
            multiAndNot: ['elev_traditional', 'stru-2ftsidegargside']
          },
          layerPriority: 3
        },
        {
          altHref: 'elev_traditional and stru-2ftsidegargside',
          floor: 2,
          ruleType: 'multi',
          groupToReplace: 'multiAnd',
          multiGroup: {
            multiAnd: ['elev_traditional', 'stru-2ftsidegargside']
          },
          layerPriority: 3
        },
        {
          altHref: 'elev_tudor and not stru-2ftsidegargside',
          floor: 2,
          ruleType: 'multi',
          groupToReplace: 'multiAndNot',
          multiGroup: { multiAndNot: ['elev_tudor', 'stru-2ftsidegargside'] },
          layerPriority: 3
        },
        {
          altHref: 'elev_tudor and stru-2ftsidegargside',
          floor: 2,
          ruleType: 'multi',
          groupToReplace: 'multiAnd',
          multiGroup: { multiAnd: ['elev_tudor', 'stru-2ftsidegargside'] },
          layerPriority: 3
        },
        {
          altHref: 'gar_2extgargside and not stru-2ftsidegargside',
          floor: 2,
          ruleType: 'multi',
          groupToReplace: 'multiAndNot',
          multiGroup: {
            multiAndNot: ['gar_2extgargside', 'stru-2ftsidegargside']
          },
          layerPriority: 3
        },
        {
          altHref: 'gar_2extgargside and stru-2ftsidegargside',
          floor: 2,
          ruleType: 'multi',
          groupToReplace: 'multiAnd',
          multiGroup: {
            multiAnd: ['gar_2extgargside', 'stru-2ftsidegargside']
          },
          layerPriority: 3
        },
        {
          altHref: 'gar_4extgargside and not stru-2ftsidegargside',
          floor: 2,
          ruleType: 'multi',
          groupToReplace: 'multiAndNot',
          multiGroup: {
            multiAndNot: ['gar_4extgargside', 'stru-2ftsidegargside']
          },
          layerPriority: 3
        },
        {
          altHref: 'gar_4extgargside and stru-2ftsidegargside',
          floor: 2,
          ruleType: 'multi',
          groupToReplace: 'multiAnd',
          multiGroup: {
            multiAnd: ['gar_4extgargside', 'stru-2ftsidegargside']
          },
          layerPriority: 3
        },
        {
          altHref: 'gar_6extgargside and not stru-2ftsidegargside',
          floor: 2,
          ruleType: 'multi',
          groupToReplace: 'multiAndNot',
          multiGroup: {
            multiAndNot: ['gar_6extgargside', 'stru-2ftsidegargside']
          },
          layerPriority: 3
        },
        {
          altHref: 'gar_6extgargside and stru-2ftsidegargside',
          floor: 2,
          ruleType: 'multi',
          groupToReplace: 'multiAnd',
          multiGroup: {
            multiAnd: ['gar_6extgargside', 'stru-2ftsidegargside']
          },
          layerPriority: 3
        },
        {
          altHref: 'garg_3carfront and not stru-2ftsidegargside',
          floor: 2,
          ruleType: 'multi',
          groupToReplace: 'multiAndNot',
          multiGroup: {
            multiAndNot: ['garg_3carfront', 'stru-2ftsidegargside']
          },
          layerPriority: 3
        },
        {
          altHref: 'garg_3carfront and stru-2ftsidegargside',
          floor: 2,
          ruleType: 'multi',
          groupToReplace: 'multiAnd',
          multiGroup: {
            multiAnd: ['garg_3carfront', 'stru-2ftsidegargside']
          },
          layerPriority: 3
        },
        {
          altHref: 'stru-2ftsidegargside join stru-2ftrear',
          floor: 2,
          ruleType: 'multi',
          groupToReplace: 'multiJoin',
          multiGroup: { multiJoin: ['stru-2ftsidegargside', 'stru-2ftrear'] },
          layerPriority: 3
        },
        {
          altHref: 'stru-sunroom and not stru-2ftrear',
          floor: 2,
          ruleType: 'multi',
          groupToReplace: 'multiAndNot',
          multiGroup: { multiAndNot: ['stru-sunroom', 'stru-2ftrear'] },
          layerPriority: 3
        },
        {
          altHref: 'stru-sunroom and stru-2ftrear',
          floor: 2,
          ruleType: 'multi',
          groupToReplace: 'multiAnd',
          multiGroup: { multiAnd: ['stru-sunroom', 'stru-2ftrear'] },
          layerPriority: 3
        },
        {
          altHref: 'eleccabcorn and not stru-2ftrear',
          floor: 2,
          ruleType: 'multi',
          groupToReplace: 'multiAndNot',
          multiGroup: { multiAndNot: ['eleccabcorn', 'stru-2ftrear'] },
          layerPriority: 3
        },
        {
          altHref: 'eleccabcorn and stru-2ftrear',
          floor: 2,
          ruleType: 'multi',
          groupToReplace: 'multiAnd',
          multiGroup: { multiAnd: ['eleccabcorn', 'stru-2ftrear'] },
          layerPriority: 3
        },
        {
          altHref: 'eleclstcorn and not stru-2ftrear',
          floor: 2,
          ruleType: 'multi',
          groupToReplace: 'multiAndNot',
          multiGroup: { multiAndNot: ['eleclstcorn', 'stru-2ftrear'] },
          layerPriority: 3
        },
        {
          altHref: 'eleclstcorn and stru-2ftrear',
          floor: 2,
          ruleType: 'multi',
          groupToReplace: 'multiAnd',
          multiGroup: { multiAnd: ['eleclstcorn', 'stru-2ftrear'] },
          layerPriority: 3
        },
        {
          altHref: 'woodlstcorn and stru-2ftrear',
          floor: 2,
          ruleType: 'multi',
          groupToReplace: 'multiAnd',
          multiGroup: { multiAnd: ['woodlstcorn', 'stru-2ftrear'] },
          layerPriority: 4
        },
        {
          altHref: 'woodlstcorn and not stru-2ftrear',
          floor: 2,
          ruleType: 'multi',
          groupToReplace: 'multiAndNot',
          multiGroup: { multiAndNot: ['woodlstcorn', 'stru-2ftrear'] },
          layerPriority: 3
        },
        // {
        //   altHref: 'stru_enlargeloft and elev_b',
        //   floor: 2,
        //   ruleType: 'multi',
        //   groupToReplace: 'multiAnd',
        //   multiGroup: { multiAnd: ['stru_enlargeloft', 'elev_b'] },
        //   layerPriority: 4
        // },
        {
          altHref: 'bath-mstrtileshower and stru-2ftrear',
          floor: 3,
          ruleType: 'multi',
          groupToReplace: 'multiAnd',
          multiGroup: {
            multiAnd: ['bath-mstrtileshower', 'stru-2ftrear']
          },
          layerPriority: 3
        },
        {
          altHref: 'bath-mstrdelmastbath and stru-2ftrear',
          floor: 3,
          ruleType: 'multi',
          groupToReplace: 'multiAnd',
          multiGroup: {
            multiAnd: ['bath-mstrdelmastbath', 'stru-2ftrear']
          },
          layerPriority: 3
        },
        {
          altHref: 'bath-mstrgardentub and stru-2ftrear',
          floor: 3,
          ruleType: 'multi',
          groupToReplace: 'multiAnd',
          multiGroup: {
            multiAnd: ['bath-mstrgardentub', 'stru-2ftrear']
          },
          layerPriority: 3
        },
        {
          altHref: 'bath-mstrshower and stru-2ftrear',
          floor: 3,
          ruleType: 'multi',
          groupToReplace: 'multiAnd',
          multiGroup: {
            multiAnd: ['bath-mstrshower', 'stru-2ftrear']
          },
          layerPriority: 3
        },
        {
          altHref: 'stru_enlargeloft and elev_craftsman',
          floor: 3,
          ruleType: 'multi',
          groupToReplace: 'multiAnd',
          multiGroup: { multiAnd: ['stru_enlargeloft', 'elev_craftsman'] },
          layerPriority: 4
        },
        {
          altHref: 'stru_enlargeloft and elev_traditional',
          floor: 3,
          ruleType: 'multi',
          groupToReplace: 'multiAnd',
          multiGroup: { multiAnd: ['stru_enlargeloft', 'elev_traditional'] },
          layerPriority: 4
        },
        {
          altHref: 'stru_enlargeloft and elev_tudor',
          floor: 3,
          ruleType: 'multi',
          groupToReplace: 'multiAnd',
          multiGroup: { multiAnd: ['stru_enlargeloft', 'elev_tudor'] },
          layerPriority: 4
        },
        {
          altHref: 'elev_tudor and not stru-2ftsidegargside',
          floor: 3,
          ruleType: 'multi',
          groupToReplace: 'multiAndNot',
          multiGroup: {
            multiAndNot: ['elev_tudor', 'stru-2ftsidegargside']
          },
          layerPriority: 3
        },
        {
          altHref: 'elev_traditional and not stru-2ftsidegargside',
          floor: 3,
          ruleType: 'multi',
          groupToReplace: 'multiAndNot',
          multiGroup: {
            multiAndNot: ['elev_traditional', 'stru-2ftsidegargside']
          },
          layerPriority: 3
        },
        {
          altHref: 'elev_craftsman and not stru-2ftsidegargside',
          floor: 3,
          ruleType: 'multi',
          groupToReplace: 'multiAndNot',
          multiGroup: {
            multiAndNot: ['elev_craftsman', 'stru-2ftsidegargside']
          },
          layerPriority: 3
        },
        {
          altHref: 'stru-2ftsidegargside join stru-2ftrear',
          floor: 3,
          ruleType: 'multi',
          groupToReplace: 'multiJoin',
          multiGroup: { multiJoin: ['stru-2ftsidegargside', 'stru-2ftrear'] },
          layerPriority: 3
        },
        {
          altHref: 'woodlstcorn or dvgslstcorn or eleclstcorn or dvgscabcorn or eleccabcorn and not stru-2ftrear',
          floor: 3,
          ruleType: 'multi',
          groupToReplace: 'multiOr',
          multiGroup: {
            multiOr: ['woodlstcorn', 'dvgslstcorn', 'eleclstcorn', 'dvgscabcorn', 'eleccabcorn'],
            multiAndNot: ['stru-2ftrear']
          },
          layerPriority: 4
        },
        {
          altHref: 'woodlstcorn or dvgslstcorn or eleclstcorn or dvgscabcorn or eleccabcorn and stru-2ftrear',
          floor: 3,
          ruleType: 'multi',
          groupToReplace: 'multiOr',
          multiGroup: {
            multiOr: ['woodlstcorn', 'dvgslstcorn', 'eleclstcorn', 'dvgscabcorn', 'eleccabcorn'],
            multiAnd: ['stru-2ftrear']
          },
          layerPriority: 4
        },
      ]
    },
    // Mulberry
    874: {
      floorOnAppLoad: 2,
      garageBlacklist: [],
      selectionsBlacklist: {
        0: [],
        1: ['elev_traditional||'],
        2: ['fdtn-foundation', 'elev_traditional||'],
        3: [
          'fire_fireplace',
          'msbt-msbtlayout'
        ]
      },
      elevationBlacklist: ['elev_traditional||'],
      optionsWhitelist: {
        0: [],
        1: [],
        2: [
          'dvgscabcorn',
          'eleccabcorn',
          'stru-2ftsidegargopp',
          'stru-2ftrear',
          'stru-4ftrear',
          'dvgslstcorn',
          'woodlstcorn',
          'eleclstcorn',
          'elev_b',
          'elev_c',
          'gar_2extgargside',
          'stru-2ftsidegargside',
          'gar_4extgargside',
          'gar_6extgargside',
          'stru-2ftrear',
          'stru-sunroom',
          'cabs-kitc-island-level-000'
        ],
        3: [
          'stru-2ftsidegargopp',
          'stru-2ftrear',
          'stru-4ftrear',
          'stru-2ftsidegargside',
          'stru_enlargeloft'
        ]
      },
      customRules: [
        {
          altHref:
            'dvgscabcorn or eleccabcorn and not stru-2ftsidegargside and not stru-2ftrear and not stru-4ftrear',
          floor: 2,
          ruleType: 'multi',
          groupToReplace: 'multiOr',
          multiGroup: {
            multiOr: ['dvgscabcorn', 'eleccabcorn'],
            multiAndNot: [
              'stru-2ftsidegargside',
              'stru-2ftrear',
              'stru-4ftrear'
            ]
          },
          layerPriority: 4
        },
        {
          altHref:
            'dvgscabcorn or eleccabcorn and not stru-2ftsidegargside and stru-4ftrear and not stru-2ftrear',
          floor: 2,
          ruleType: 'multi',
          groupToReplace: 'multiOr',
          multiGroup: {
            multiOr: ['dvgscabcorn', 'eleccabcorn'],
            multiAnd: ['stru-4ftrear'],
            multiAndNot: ['stru-2ftsidegargside', 'stru-2ftrear']
          },
          layerPriority: 4
        },
        {
          altHref:
            'dvgscabcorn or eleccabcorn and stru-2ftsidegargside and not stru-2ftrear and not stru-4ftrear',
          floor: 2,
          ruleType: 'multi',
          groupToReplace: 'multiOr',
          multiGroup: {
            multiOr: ['dvgscabcorn', 'eleccabcorn'],
            multiAnd: ['stru-2ftsidegargside'],
            multiAndNot: ['stru-2ftrear', 'stru-4ftrear']
          },
          layerPriority: 4
        },
        {
          altHref:
            'dvgscabcorn or eleccabcorn and stru-2ftsidegargside and stru-2ftrear and not stru-4ftrear',
          floor: 2,
          ruleType: 'multi',
          groupToReplace: 'multiOr',
          multiGroup: {
            multiOr: ['dvgscabcorn', 'eleccabcorn'],
            multiAnd: ['stru-2ftsidegargside', 'stru-2ftrear'],
            multiAndNot: ['stru-4ftrear']
          },
          layerPriority: 4
        },
        {
          altHref:
            'dvgscabcorn or eleccabcorn and stru-2ftsidegargside and stru-4ftrear 4 and not stru-2ftrear',
          floor: 2,
          ruleType: 'multi',
          groupToReplace: 'multiOr',
          multiGroup: {
            multiOr: ['dvgscabcorn', 'eleccabcorn'],
            multiAnd: ['stru-2ftsidegargside', 'stru-4ftrear'],
            multiAndNot: ['stru-2ftrear']
          },
          layerPriority: 4
        },
        {
          altHref:
            'dvgslstcorn or woodlstcorn or eleclstcorn and not stru-2ftsidegargside and not stru-2ftrear and not stru-4ftrear',
          floor: 2,
          ruleType: 'multi',
          groupToReplace: 'multiOr',
          multiGroup: {
            multiOr: ['dvgslstcorn', 'woodlstcorn', 'eleclstcorn'],
            multiAndNot: [
              'stru-2ftsidegargside',
              'stru-2ftrear',
              'stru-4ftrear'
            ]
          },
          layerPriority: 5
        },
        {
          altHref:
            'dvgslstcorn or woodlstcorn or eleclstcorn and not stru-2ftsidegargside and not stru-2ftrear and stru-4ftrear',
          floor: 2,
          ruleType: 'multi',
          groupToReplace: 'multiOr',
          multiGroup: {
            multiOr: ['dvgslstcorn', 'woodlstcorn', 'eleclstcorn'],
            multiAnd: ['stru-4ftrear'],
            multiAndNot: ['stru-2ftsidegargside', 'stru-2ftrear']
          },
          layerPriority: 4
        },
        {
          altHref:
            'dvgslstcorn or woodlstcorn or eleclstcorn and not stru-2ftsidegargside and stru-2ftrear and not stru-4ftrear',
          floor: 2,
          ruleType: 'multi',
          groupToReplace: 'multiOr',
          multiGroup: {
            multiOr: ['dvgslstcorn', 'woodlstcorn', 'eleclstcorn'],
            multiAnd: ['stru-2ftrear'],
            multiAndNot: ['stru-2ftsidegargside', 'stru-4ftrear']
          },
          layerPriority: 4
        },
        {
          altHref:
            'dvgscabcorn or eleccabcorn and not stru-2ftsidegargside and stru-2ftrear and not stru-4ftrear',
          floor: 2,
          ruleType: 'multi',
          groupToReplace: 'multiOr',
          multiGroup: {
            multiOr: ['dvgscabcorn', 'eleccabcorn'],
            multiAnd: ['stru-2ftrear'],
            multiAndNot: ['stru-2ftsidegargside', 'stru-4ftrear']
          },
          layerPriority: 4
        },
        {
          altHref:
            'dvgslstcorn or woodlstcorn or eleclstcorn and stru-2ftsidegargside and not stru-2ftrear and not stru-4ftrear',
          floor: 2,
          ruleType: 'multi',
          groupToReplace: 'multiOr',
          multiGroup: {
            multiOr: ['dvgslstcorn', 'woodlstcorn', 'eleclstcorn'],
            multiAnd: ['stru-2ftsidegargside'],
            multiAndNot: ['stru-2ftrear', 'stru-4ftrear']
          },
          layerPriority: 4
        },
        {
          altHref:
            'dvgslstcorn or woodlstcorn or eleclstcorn and stru-2ftsidegargside and not stru-2ftrear and stru-4ftrear',
          floor: 2,
          ruleType: 'multi',
          groupToReplace: 'multiOr',
          multiGroup: {
            multiOr: ['dvgslstcorn', 'woodlstcorn', 'eleclstcorn'],
            multiAnd: ['stru-2ftsidegargside', 'stru-4ftrear'],
            multiAndNot: ['stru-2ftrear']
          },
          layerPriority: 4
        },
        {
          altHref:
            'dvgslstcorn or woodlstcorn or eleclstcorn and stru-2ftsidegargside and stru-2ftrear and not stru-4ftrear',
          floor: 2,
          ruleType: 'multi',
          groupToReplace: 'multiOr',
          multiGroup: {
            multiOr: ['dvgslstcorn', 'woodlstcorn', 'eleclstcorn'],
            multiAnd: ['stru-2ftsidegargside', 'stru-2ftrear'],
            multiAndNot: ['stru-4ftrear']
          },
          layerPriority: 4
        },
        {
          altHref: 'elev_b and not stru-2ftsidegargopp',
          floor: 2,
          ruleType: 'multi',
          groupToReplace: 'multiAndNot',
          multiGroup: { multiAndNot: ['elev_b', 'stru-2ftsidegargopp'] },
          layerPriority: 3
        },
        {
          altHref: 'elev_b and stru-2ftsidegargopp',
          floor: 2,
          ruleType: 'multi',
          groupToReplace: 'multiAnd',
          multiGroup: { multiAnd: ['elev_b', 'stru-2ftsidegargopp'] },
          layerPriority: 3
        },
        {
          altHref: 'elev_c and not stru-2ftsidegargopp',
          floor: 2,
          ruleType: 'multi',
          groupToReplace: 'multiAndNot',
          multiGroup: { multiAndNot: ['elev_c', 'stru-2ftsidegargopp'] },
          layerPriority: 3
        },
        {
          altHref: 'elev_c and stru-2ftsidegargopp',
          floor: 2,
          ruleType: 'multi',
          groupToReplace: 'multiAnd',
          multiGroup: { multiAnd: ['elev_c', 'stru-2ftsidegargopp'] },
          layerPriority: 3
        },
        {
          altHref: 'gar_2extgargside and not stru-2ftsidegargside',
          floor: 2,
          ruleType: 'multi',
          groupToReplace: 'multiAndNot',
          multiGroup: {
            multiAndNot: ['gar_2extgargside', 'stru-2ftsidegargside']
          },
          layerPriority: 3
        },
        {
          altHref: 'gar_2extgargside and stru-2ftsidegargside',
          floor: 2,
          ruleType: 'multi',
          groupToReplace: 'multiAnd',
          multiGroup: {
            multiAnd: ['gar_2extgargside', 'stru-2ftsidegargside']
          },
          layerPriority: 3
        },
        {
          altHref: 'gar_4extgargside and not stru-2ftsidegargside',
          floor: 2,
          ruleType: 'multi',
          groupToReplace: 'multiAndNot',
          multiGroup: {
            multiAndNot: ['gar_4extgargside', 'stru-2ftsidegargside']
          },
          layerPriority: 3
        },
        {
          altHref: 'gar_4extgargside and stru-2ftsidegargside',
          floor: 2,
          ruleType: 'multi',
          groupToReplace: 'multiAnd',
          multiGroup: {
            multiAnd: ['gar_4extgargside', 'stru-2ftsidegargside']
          },
          layerPriority: 3
        },
        {
          altHref: 'gar_6extgargside and not stru-2ftsidegargside',
          floor: 2,
          ruleType: 'multi',
          groupToReplace: 'multiAndNot',
          multiGroup: {
            multiAndNot: ['gar_6extgargside', 'stru-2ftsidegargside']
          },
          layerPriority: 3
        },
        {
          altHref: 'gar_6extgargside and stru-2ftsidegargside',
          floor: 2,
          ruleType: 'multi',
          groupToReplace: 'multiAnd',
          multiGroup: {
            multiAnd: ['gar_6extgargside', 'stru-2ftsidegargside']
          },
          layerPriority: 3
        },
        {
          altHref: 'cabs-kitc-island-level-000 and not stru-2ftrear',
          floor: 2,
          ruleType: 'multi',
          groupToReplace: 'multiAndNot',
          multiGroup: { multiAndNot: ['cabs-kitc-island-level-000', 'stru-2ftrear'] },
          layerPriority: 3
        },
        {
          altHref: 'cabs-kitc-island-level-000 and stru-2ftrear',
          floor: 2,
          ruleType: 'multi',
          groupToReplace: 'multiAnd',
          multiGroup: { multiAnd: ['cabs-kitc-island-level-000', 'stru-2ftrear'] },
          layerPriority: 3
        },
        {
          altHref: 'stru-2ftsidegargopp join stru-2ftrear',
          floor: 2,
          ruleType: 'multi',
          groupToReplace: 'multiJoin',
          multiGroup: { multiJoin: ['stru-2ftsidegargopp', 'stru-2ftrear'] },
          layerPriority: 3
        },
        {
          altHref: 'stru-2ftsidegargopp join stru-4ftrear',
          floor: 2,
          ruleType: 'multi',
          groupToReplace: 'multiJoin',
          multiGroup: { multiJoin: ['stru-2ftsidegargopp', 'stru-4ftrear'] },
          layerPriority: 3
        },
        {
          altHref: 'stru-2ftsidegargside join stru-2ftrear',
          floor: 2,
          ruleType: 'multi',
          groupToReplace: 'multiJoin',
          multiGroup: { multiJoin: ['stru-2ftsidegargside', 'stru-2ftrear'] },
          layerPriority: 3
        },
        {
          altHref: 'stru-2ftsidegargside join stru-4ftrear',
          floor: 2,
          ruleType: 'multi',
          groupToReplace: 'multiJoin',
          multiGroup: { multiJoin: ['stru-2ftsidegargside', 'stru-4ftrear'] },
          layerPriority: 3
        },
        {
          altHref: 'stru-sunroom and not stru-2ftrear and not stru-4ftrear',
          floor: 2,
          ruleType: 'multi',
          groupToReplace: 'multiAndNot',
          multiGroup: {
            multiAndNot: ['stru-sunroom', 'stru-2ftrear', 'stru-4ftrear']
          },
          layerPriority: 3
        },
        {
          altHref: 'stru-sunroom and not stru-2ftrear and stru-4ftrear',
          floor: 2,
          ruleType: 'multi',
          groupToReplace: 'multiAndNot',
          multiGroup: {
            multiAnd: ['stru-4ftrear'],
            multiAndNot: ['stru-sunroom', 'stru-2ftrear']
          },
          layerPriority: 3
        },
        {
          altHref: 'stru-sunroom and stru-2ftrear and not stru-4ftrear',
          floor: 2,
          ruleType: 'multi',
          groupToReplace: 'multiAnd',
          multiGroup: {
            multiAnd: ['stru-sunroom', 'stru-2ftrear'],
            multiAndNot: ['stru-4ftrear']
          },
          layerPriority: 3
        },
        {
          altHref: 'stru-2ftsidegargopp join stru-2ftrear',
          floor: 3,
          ruleType: 'multi',
          groupToReplace: 'multiJoin',
          multiGroup: { multiJoin: ['stru-2ftsidegargopp', 'stru-2ftrear'] },
          layerPriority: 3
        },
        {
          altHref: 'stru-2ftsidegargopp join stru-4ftrear',
          floor: 3,
          ruleType: 'multi',
          groupToReplace: 'multiJoin',
          multiGroup: { multiJoin: ['stru-2ftsidegargopp', 'stru-4ftrear'] },
          layerPriority: 3
        },
        {
          altHref: 'stru-2ftsidegargside join stru-2ftrear',
          floor: 3,
          ruleType: 'multi',
          groupToReplace: 'multiJoin',
          multiGroup: { multiJoin: ['stru-2ftsidegargside', 'stru-2ftrear'] },
          layerPriority: 3
        },
        {
          altHref: 'stru-2ftsidegargside join stru-4ftrear',
          floor: 3,
          ruleType: 'multi',
          groupToReplace: 'multiJoin',
          multiGroup: { multiJoin: ['stru-2ftsidegargside', 'stru-4ftrear'] },
          layerPriority: 3
        },
        {
          altHref:
            'stru_enlargeloft and not stru-2ftrear and not stru-4ftrear',
          floor: 3,
          ruleType: 'multi',
          groupToReplace: 'multiAndNot',
          multiGroup: {
            multiAndNot: ['stru_enlargeloft', 'stru-2ftrear', 'stru-4ftrear']
          },
          layerPriority: 3
        },
        {
          altHref: 'stru_enlargeloft and not stru-2ftrear and stru-4ftrear',
          floor: 3,
          ruleType: 'multi',
          groupToReplace: 'multiAndNot',
          multiGroup: {
            multiAnd: ['stru-4ftrear'],
            multiAndNot: ['stru_enlargeloft', 'stru-2ftrear']
          },
          layerPriority: 3
        },
        {
          altHref: 'stru_enlargeloft and stru-2ftrear and not stru-4ftrear',
          floor: 3,
          ruleType: 'multi',
          groupToReplace: 'multiAnd',
          multiGroup: {
            multiAnd: ['stru_enlargeloft', 'stru-2ftrear'],
            multiAndNot: ['stru-4ftrear']
          },
          layerPriority: 3
        }
      ]
    },
    // Norway
    875: {
      floorOnAppLoad: 2,
      optionsWhitelist: {
        0: [],
        1: [
          'fdtn-bsmtfinishedfullbath',
          'fdtn_bsmtfin_full',
          'fdtn_bsmtfin_part',
          'fdtn_buf8',
          'fdtn_buf9',
          'fdtn_bup8',
          'fdtn_bup9'
        ],
        2: [
          'dvgslstcorn',
          'dvgscabcorn',
          'stru-2ftrear',
          'stru-sunroom',
          'elev_b',
          'elev_craftsman',
          'elev_traditional',
          'elev_tudor',
          'gar_2extgargside',
          'gar_4extgargside',
          'gar_6extgargside',
          'woodlstnoncorn',
          'garg_2extgargfront',
          'plmb-watersoftrough',
          'cab-config-layout1',
          'cab-config-layout2'
        ],
        3: [
          'bath-mstrdelmastbath',
          'bath-mstrgardentub',
          'bath-mstrshower',
          'bath-mstrtileshower',
          'stru-2ftrear',
          'woodlstnoncorn'
        ]
      },
      garageBlacklist: [],
      selectionsBlacklist: {
        0: [],
        1: [],
        2: ['fdtn-foundation', 'eleccabcorn||', 'eleclstcorn||'],
        3: [
          // 'msbt-msbtlayout'
          'bath-mstrshower|chrome frame|obscure glass',
          'bath-mstrshower|chrome frame|clear glass',
          'bath-mstrdelmastbath|chrome frame|obscure glass',
          'bath-mstrdelmastbath|chrome frame|clear glass',
          'bath-mstrtileshower|chrome frame|obscure glass',
          'bath-mstrtileshower|chrome frame|clear glass'
        ]
      },
      elevationBlacklist: [],
      customRules: [
        {
          altHref: 'fdtn_buf8 or fdtn_buf9',
          floor: 1,
          ruleType: 'multi',
          groupToReplace: 'multiOr',
          multiGroup: { multiOr: ['fdtn_buf8', 'fdtn_buf9'] },
          layerPriority: 2
        },
        {
          altHref: 'fdtn_bup8 or fdtn_bup9',
          floor: 1,
          ruleType: 'multi',
          groupToReplace: 'multiOr',
          multiGroup: { multiOr: ['fdtn_bup8', 'fdtn_bup9'] },
          layerPriority: 2
        },
        {
          altHref: 'fdtn_buf8 or fdtn_buf9 and fdtn_bsmtfin_part',
          floor: 1,
          ruleType: 'multi',
          groupToReplace: 'multiOr',
          multiGroup: {
            multiOr: ['fdtn_buf8', 'fdtn_buf9'],
            multiAnd: ['fdtn_bsmtfin_part']
          },
          layerPriority: 4
        },
        {
          altHref: 'dvgslstcorn or dvgscabcorn and not stru-2ftrear',
          floor: 2,
          ruleType: 'multi',
          groupToReplace: 'multiOr',
          multiGroup: {
            multiOr: ['dvgslstcorn', 'dvgscabcorn'],
            multiAndNot: ['stru-2ftrear']
          },
          layerPriority: 4
        },
        {
          altHref: 'dvgslstcorn or dvgscabcorn and stru-2ftrear',
          floor: 2,
          ruleType: 'multi',
          groupToReplace: 'multiOr',
          multiGroup: {
            multiOr: ['dvgslstcorn', 'dvgscabcorn'],
            multiAnd: ['stru-2ftrear']
          },
          layerPriority: 4
        },
        {
          altHref: 'stru-sunroom and not stru-2ftrear',
          floor: 2,
          ruleType: 'multi',
          groupToReplace: 'multiAndNot',
          multiGroup: { multiAndNot: ['stru-sunroom', 'stru-2ftrear'] },
          layerPriority: 3
        },
        {
          altHref: 'stru-sunroom and stru-2ftrear',
          floor: 2,
          ruleType: 'multi',
          groupToReplace: 'multiAnd',
          multiGroup: { multiAnd: ['stru-sunroom', 'stru-2ftrear'] },
          layerPriority: 3
        }
      ]
    },
    // Empress
    877: {
      floorOnAppLoad: 2,
      garageBlacklist: [],
      selectionsBlacklist: {
        0: [],
        1: [],
        2: [
          'fire_fireplace',
          'cabs-kitc-island-level-000|merillat - colony birch square|clove',
          'cabs-kitc-island-level-000|merillat - colony birch square|mirage'
        ],
        3: [
          'bath-mstrtubshower||',
          'bath-mstrgardentub||',
          'bath-mstrshower|chrome frame|obscure glass',
          'bath-mstrshower|chrome frame|clear glass',
          'bath-mstrdelmastbath|chrome frame|clear glass',
          'bath-mstrdelmastbath|chrome frame|obscure glass',
          'bath-mstrtileshower|chrome frame|obscure glass',
          'bath-mstrtileshower|chrome frame|clear glass'
        ]
      },
      elevationBlacklist: ['elev_traditional||'],
      optionsWhitelist: {
        0: [],
        1: [
          'fdtn_bup8',
          'fdtn_bup9',
          'fdtn_buf8',
          'fdtn_buf9',
          'fdtn-bsbd',
          'fdtn-bsmtfinishedfullbath',
          'fdtn_bsmtfin_full',
          'fdtn_bsmtfin_part'
        ],
        2: [
          'dvgscabnoncorn',
          'dvgslstnoncorn',
          'eleccabcorn',
          'eleclstcorn',
          'fdtn_bup8',
          'fdtn_bup9',
          'fdtn_buf8',
          'fdtn_buf9',
          'cabs-kitc-island-level-000',
          'elev_c',
          'elev_craftsman',
          'elev_traditional',
          'elev_tudor',
          'gar_2extgargside',
          'gar_4extgargside',
          'gar_6extgargside',
          'stru-sunroom',
          'woodlstcorn',
          'garg_2extgargfront'
        ],
        3: [
          'bath-mstrdelmastbath',
          'bath-mstrgardentub',
          'bath-mstrshower',
          'bath-mstrtileshower',
          'elev_craftsman',
          'elev_traditional',
          'elev_tudor',
          'stru_enlargeloft'
        ]
      },
      customRules: [
        {
          altHref: 'fdtn_bup8 or fdtn_bup9',
          floor: 1,
          ruleType: 'multi',
          groupToReplace: 'multiOr',
          multiGroup: { multiOr: ['fdtn_bup8', 'fdtn_bup9'] },
          layerPriority: 2
        },
        {
          altHref: 'fdtn_buf8 or fdtn_buf9',
          floor: 1,
          ruleType: 'multi',
          groupToReplace: 'multiOr',
          multiGroup: { multiOr: ['fdtn_buf8', 'fdtn_buf9'] },
          layerPriority: 2
        },
        {
          altHref: 'dvgscabnoncorn or dvgslstnoncorn',
          floor: 2,
          ruleType: 'multi',
          groupToReplace: 'multiOr',
          multiGroup: { multiOr: ['dvgscabnoncorn', 'dvgslstnoncorn'] },
          layerPriority: 3
        },
        {
          altHref: 'fdtn_bup8 or fdtn_bup9 or fdtn_buf8 or fdtn_buf9',
          floor: 2,
          ruleType: 'multi',
          groupToReplace: 'multiOr',
          multiGroup: {
            multiOr: ['fdtn_bup8', 'fdtn_bup9', 'fdtn_buf8', 'fdtn_buf9']
          },
          layerPriority: 1
        },
        {
          altHref: 'elev_traditional and stru_enlargeloft',
          floor: 3,
          ruleType: 'multi',
          groupToReplace: 'multiAnd',
          multiGroup: {
            multiAnd: ['elev_traditional', 'stru_enlargeloft']
          },
          layerPriority: 4
        },
        {
          altHref: 'elev_tudor and stru_enlargeloft',
          floor: 3,
          ruleType: 'multi',
          groupToReplace: 'multiAnd',
          multiGroup: {
            multiAnd: ['elev_tudor', 'stru_enlargeloft']
          },
          layerPriority: 4
        },
      ]
    },
    // Magnolia
    880: {
      floorOnAppLoad: 2,
      garageBlacklist: [],
      selectionsBlacklist: {
        0: [],
        1: [],
        2: [
          'bath-mstrtileshower|chrome frame|obscure glass',
          'bath-mstrtileshower|chrome frame|clear glass',
          'bath-mstrshower|chrome frame|obscure glass',
          'bath-mstrshower|chrome frame|clear glass',
          'eleclstcorn||'
        ],
        3: []
      },
      elevationBlacklist: [],
      optionsWhitelist: {
        0: [],
        1: [
          'fdtn_bup8',
          'fdtn_bup9',
          'fdtn-bsmtfin_part',
          'fdtn_buf8',
          'fdtn_buf9',
          'fdtn-bsbd',
          'fdtn-bsmtfinishedfullbath',
          'fdtn_bsmtfin_full',
          'fdtn_bsmtfin_part'
        ],
        2: [
          'bath-mstrgardentub',
          'stru-2ftsidegargside',
          'bath-mstrshower',
          'bath-mstrtileshower',
          'eleccabcorn',
          'eleclstcorn',
          'woodlstcorn',
          'dvgscabcorn',
          'dvgslstcorn',
          'fdtn_buf8',
          'fdtn_buf9',
          'fdtn_bup8',
          'fdtn_bup9',
          'stru-bnrm',
          'gar_2extgargside',
          'gar_4extgargside',
          'gar_6extgargside',
          'stru-2ftrear',
          'stru-sunroom',
          'elev_b',
          'elev_c',
          'plmb-watersoftrough',
          'garg_2extgargfront'
        ],
        3: [
          'stru-2ftsidegargside',
          'stru-2ftrear',
          'stru_bed4iloloft'
        ]
      },
      customRules: [
        {
          altHref: 'fdtn-bsbd',
          floor: 1,
          layerPriority: 4
        },
        {
          altHref: 'fdtn-bsmtfinishedfullbath',
          floor: 1,
          layerPriority: 3
        },
        {
          altHref: 'fdtn_bsmtfin_full',
          floor: 1,
          layerPriority: 3
        },
        {
          altHref: 'fdtn-bsmtfin_part',
          floor: 1,
          layerPriority: 3
        },
        {
          altHref: 'fdtn_bup8 or fdtn_bup9 or fdtn-bsmtfin_part',
          floor: 1,
          ruleType: 'multi',
          groupToReplace: 'multiOr',
          multiGroup: { multiOr: ['fdtn_bup8', 'fdtn_bup9'] },
          layerPriority: 3
        },
        {
          altHref: 'fdtn_bup8 or fdtn_bup9 or fdtn_buf8 or fdtn_buf9',
          floor: 1,
          ruleType: 'multi',
          groupToReplace: 'multiOr',
          multiGroup: {
            multiOr: ['fdtn_bup8', 'fdtn_bup9', 'fdtn_buf8', 'fdtn_buf9']
          },
          layerPriority: 3
        },
        {
          altHref: 'bath-mstrgardentub and not stru-2ftsidegargside',
          floor: 2,
          ruleType: 'multi',
          groupToReplace: 'multiAndNot',
          multiGroup: {
            multiAndNot: ['bath-mstrgardentub', 'stru-2ftsidegargside']
          },
          layerPriority: 3
        },
        {
          altHref: 'bath-mstrgardentub and stru-2ftsidegargside',
          floor: 2,
          ruleType: 'multi',
          groupToReplace: 'multiAnd',
          multiGroup: {
            multiAnd: ['bath-mstrgardentub', 'stru-2ftsidegargside']
          },
          layerPriority: 3
        },
        {
          altHref: 'bath-mstrshower and not stru-2ftsidegargside',
          floor: 2,
          ruleType: 'multi',
          groupToReplace: 'multiAndNot',
          multiGroup: {
            multiAndNot: ['bath-mstrshower', 'stru-2ftsidegargside']
          },
          layerPriority: 3
        },
        {
          altHref: 'bath-mstrshower and stru-2ftsidegargside',
          floor: 2,
          ruleType: 'multi',
          groupToReplace: 'multiAnd',
          multiGroup: {
            multiAnd: ['bath-mstrshower', 'stru-2ftsidegargside']
          },
          layerPriority: 3
        },
        {
          altHref: 'bath-mstrtileshower and not stru-2ftsidegargside',
          floor: 2,
          ruleType: 'multi',
          groupToReplace: 'multiAndNot',
          multiGroup: {
            multiAndNot: ['bath-mstrtileshower', 'stru-2ftsidegargside']
          },
          layerPriority: 3
        },
        {
          altHref: 'bath-mstrtileshower and stru-2ftsidegargside',
          floor: 2,
          ruleType: 'multi',
          groupToReplace: 'multiAnd',
          multiGroup: {
            multiAnd: ['bath-mstrtileshower', 'stru-2ftsidegargside']
          },
          layerPriority: 3
        },
        {
          altHref: 'eleccabcorn or eleclstcorn',
          floor: 2,
          ruleType: 'multi',
          groupToReplace: 'multiOr',
          multiGroup: { multiOr: ['eleccabcorn', 'eleclstcorn'] },
          layerPriority: 3
        },
        {
          altHref:
            'fdtn_buf8 or fdtn_buf9 or fdtn_bup8 or fdtn_bup9 and stru-bnrm',
          floor: 2,
          ruleType: 'multi',
          groupToReplace: 'multiOr',
          multiGroup: {
            multiOr: ['fdtn_buf8', 'fdtn_buf9', 'fdtn_bup8', 'fdtn_bup9'],
            multiAnd: ['stru-bnrm']
          },
          layerPriority: 1
        },
        {
          altHref: 'gar_2extgargside and not stru-2ftsidegargside',
          floor: 2,
          ruleType: 'multi',
          groupToReplace: 'multiAndNot',
          multiGroup: {
            multiAndNot: ['gar_2extgargside', 'stru-2ftsidegargside']
          },
          layerPriority: 3
        },
        {
          altHref: 'gar_2extgargside and stru-2ftsidegargside',
          floor: 2,
          ruleType: 'multi',
          groupToReplace: 'multiAnd',
          multiGroup: {
            multiAnd: ['gar_2extgargside', 'stru-2ftsidegargside']
          },
          layerPriority: 3
        },
        {
          altHref: 'gar_4extgargside and not stru-2ftsidegargside',
          floor: 2,
          ruleType: 'multi',
          groupToReplace: 'multiAndNot',
          multiGroup: {
            multiAndNot: ['gar_4extgargside', 'stru-2ftsidegargside']
          },
          layerPriority: 3
        },
        {
          altHref: 'gar_4extgargside and stru-2ftsidegargside',
          floor: 2,
          ruleType: 'multi',
          groupToReplace: 'multiAnd',
          multiGroup: {
            multiAnd: ['gar_4extgargside', 'stru-2ftsidegargside']
          },
          layerPriority: 3
        },
        {
          altHref: 'gar_6extgargside and not stru-2ftsidegargside',
          floor: 2,
          ruleType: 'multi',
          groupToReplace: 'multiAndNot',
          multiGroup: {
            multiAndNot: ['gar_6extgargside', 'stru-2ftsidegargside']
          },
          layerPriority: 3
        },
        {
          altHref: 'gar_6extgargside and stru-2ftsidegargside',
          floor: 2,
          ruleType: 'multi',
          groupToReplace: 'multiAnd',
          multiGroup: {
            multiAnd: ['gar_6extgargside', 'stru-2ftsidegargside']
          },
          layerPriority: 3
        },
        {
          altHref: 'stru-2ftsidegargside join stru-2ftrear',
          floor: 2,
          ruleType: 'multi',
          groupToReplace: 'multiJoin',
          multiGroup: { multiJoin: ['stru-2ftsidegargside', 'stru-2ftrear'] },
          layerPriority: 3
        },
        {
          altHref: 'stru-sunroom and not stru-2ftrear',
          floor: 2,
          ruleType: 'multi',
          groupToReplace: 'multiAndNot',
          multiGroup: { multiAndNot: ['stru-sunroom', 'stru-2ftrear'] },
          layerPriority: 3
        },
        {
          altHref: 'stru-sunroom and stru-2ftrear',
          floor: 2,
          ruleType: 'multi',
          groupToReplace: 'multiAnd',
          multiGroup: { multiAnd: ['stru-sunroom', 'stru-2ftrear'] },
          layerPriority: 3
        },
        {
          altHref: 'stru-2ftsidegargside join stru-2ftrear',
          floor: 3,
          ruleType: 'multi',
          groupToReplace: 'multiJoin',
          multiGroup: { multiJoin: ['stru-2ftsidegargside', 'stru-2ftrear'] },
          layerPriority: 3
        },
        {
          altHref: 'stru_bed4iloloft and stru-2ftrear',
          floor: 3,
          ruleType: 'multi',
          groupToReplace: 'multiAnd',
          multiGroup: { multiAnd: ['stru_bed4iloloft', 'stru-2ftrear'] },
          layerPriority: 3
        }
      ]
    },
    // Reagan
    929: {
      floorOnAppLoad: 2,
      categoriesToConvert: {
        'fdtn-foundation': [
          'fdtn_buf8',
          'fdtn_buf9',
          'fdtn_bup8',
          'fdtn_bup9'
        ],
        'stru-bnrm-layout': [
          'stru_bnrm_layout_bnrm',
          'stru_bnrm_layout_bnrmbnbt',
          'stru_bnrm_layout_bnrmbnbt1bed'
        ]
      },
      garageBlacklist: [],
      selectionsBlacklist: {
        0: [],
        1: [],
        2: [],
        3: []
      },
      elevationBlacklist: [],
      optionsWhitelist: {
        0: [],
        1: ['garg_2carfronttandem',
          'elev_a',
          'elev_b',
          'elev_tudor',
          'stru-4ftreargtrm',
          'stru_bnrm_layout_bnrm',
          'stru_gtrm_clgs_coffer',
          'stru_gtrm_clgs_step1',
          'and',
          'bath-dblbowlhlbt',
          'bath-dblbowlmsbt',
          'bath-mstrdelmastbath',
          'bath-mstrshower',
          'bath-mstrtileshower',
          'bath_jjbt',
          'cabs-kitc-layout-000-3036',
          'cabs-kitc-layout-000-3642',
          'cabs-kitc-layout-000-42',
          'cabs-kitc-layout-100-3642',
          'dkpt-patio-covered-1210',
          'dkpt-patio-screened-1210',
          'dvgslstcorn',
          'elev-prch-layout-incl',
          'elev_frel_brick_inc',
          'gar_2extgargside',
          'gar_4extgargside',
          'gar_6extgargside',
          'garg_2carfront',
          'garg_2carside',
          'garg_3carfront',
          'garg_3carside',
          'stru-cathsunroom',
          'stru-sunroom',
          'stru_baywindowmstr',
          'stru_flex_layout_bedroom',
          'stru_flex_layout_densoliddoors',
          'stru_flex_layout_living',
          'stru_mstr_clgs_step1',
          'stru_mstr_clgs_tray',
          'stru_mstr_clgs_vault',
          'trim-20-sng-6panel-nonlocking',
          'trim-40-dbl-6panel-nonlocking',
          'trim-40-dbl-glassandgrids-nonlocking',
          'trim-dwo',
          'trim-dwo6',
          'trim-dwo8',
          'trim_mscldoorlaun',
          'woodlstcorn'],
        2: ['trim-rail-square-paint',
          'stru_bnrm_layout_bnrm',
          'stru-4ftreargtrm',
          'stru_bnrm_layout_bnrmbnbt',
          'stru_bnrm_layout_bnrmbnbt1bed',
          'stru_bnrm_layout_bnrmbnbt2bed'],
        3: []
      },
      customRules: [{
        altHref: 'garg_2carfronttandem and elev_a',
        floor: 0,
        ruleType: 'multi',
        groupToReplace: 'multiAnd',
        multiGroup: { multiAnd: ['garg_2carfronttandem', 'elev_a'] },
        layerPriority: 3
      },
      {
        altHref: 'garg_2carfronttandem and elev_b',
        floor: 0,
        ruleType: 'multi',
        groupToReplace: 'multiAnd',
        multiGroup: { multiAnd: ['garg_2carfronttandem', 'elev_b'] },
        layerPriority: 3
      },
      {
        altHref: 'garg_2carfronttandem and elev_tudor',
        floor: 0,
        ruleType: 'multi',
        groupToReplace: 'multiAnd',
        multiGroup: { multiAnd: ['garg_2carfronttandem', 'elev_tudor'] },
        layerPriority: 3
      },
      {
        altHref: 'stru-4ftreargtrm and stru_bnrm_layout_bnrm',
        floor: 0,
        ruleType: 'multi',
        groupToReplace: 'multiAnd',
        multiGroup: { multiAnd: ['stru-4ftreargtrm', 'stru_bnrm_layout_bnrm'] },
        layerPriority: 3
      },
      {
        altHref: 'stru_gtrm_clgs_coffer and stru-4ftreargtrm and stru_bnrm_layout_bnrm',
        floor: 0,
        ruleType: 'multi',
        groupToReplace: 'multiAnd',
        multiGroup:
        {
          multiAnd:
            ['stru_gtrm_clgs_coffer',
              'stru-4ftreargtrm',
              'stru_bnrm_layout_bnrm']
        },
        layerPriority: 3
      },
      {
        altHref: 'stru_gtrm_clgs_coffer and stru-4ftreargtrm',
        floor: 0,
        ruleType: 'multi',
        groupToReplace: 'multiAnd',
        multiGroup: { multiAnd: ['stru_gtrm_clgs_coffer', 'stru-4ftreargtrm'] },
        layerPriority: 3
      },
      {
        altHref: 'stru_gtrm_clgs_coffer and stru_bnrm_layout_bnrm',
        floor: 0,
        ruleType: 'multi',
        groupToReplace: 'multiAnd',
        multiGroup:
          { multiAnd: ['stru_gtrm_clgs_coffer', 'stru_bnrm_layout_bnrm'] },
        layerPriority: 3
      },
      {
        altHref: 'stru_gtrm_clgs_step1 and and stru-4ftreargtrm',
        floor: 0,
        ruleType: 'multi',
        groupToReplace: 'multiAnd',
        multiGroup:
          { multiAnd: ['stru_gtrm_clgs_step1', 'and', 'stru-4ftreargtrm'] },
        layerPriority: 3
      },
      {
        altHref: 'trim-rail-square-paint and stru_bnrm_layout_bnrm',
        floor: 1,
        ruleType: 'multi',
        groupToReplace: 'multiAnd',
        multiGroup:
          { multiAnd: ['trim-rail-square-paint', 'stru_bnrm_layout_bnrm'] },
        layerPriority: 3
      }]
    },
    // Jefferson
    940: {
      floorOnAppLoad: 2,
      optionsWhitelist: {
        0: [],
        1: [
          'fdtn_buf8',
          'fdtn_buf9',
          'fdtn_bup8',
          'fdtn_bup9',
          'fdtn-bsbd',
          'fdtn-bsmtfinishedfullbath',
          'fdtn_bsmtfin_full',
          'fdtn_bsmtfin_part',
          'fdtn-foundation'
        ],
        2: [
          'bath_jjbt',
          'stru-2ftsidegargopp',
          'elev_craftsman',
          'fdtn-foundation',
          'stru-bnrm-layout',
          'stru-gtrm-window-bay',
          'stru-sunroom',
          'stru_gtrm_layout_bedroom',
          'stru_gtrm_layout_den',
          'stru_gtrm_layout_dine',
          'bath-mstrdelmastbath',
          'bath-mstrshower',
          'bath-mstrtileshower',
          'elev_b',
          'elev_c',
          'elev_traditional',
          'elev_tudor',
          'gar_2extgargside',
          'gar_4extgargside',
          'gar_6extgargside',
          'fdtn_buf8',
          'fdtn_buf9',
          'fdtn_bup8',
          'fdtn_bup9',
          'stru_bnrm_layout_bnrm',
          'stru_bnrm_layout_bnrmbnbt',
          'stru_bnrm_layout_bnrmbnbt1bed',
          'plmb-watersoftrough'
        ],
        3: [
          'stru_bnrm_layout_bnrm',
          'stru_bnrm_layout_bnrmbnbt',
          'stru_bnrm_layout_bnrmbnbt1bed'
        ]
      },
      categoriesToConvert: {
        'fdtn-foundation': [
          'fdtn_buf8',
          'fdtn_buf9',
          'fdtn_bup8',
          'fdtn_bup9'
        ],
        'stru-bnrm-layout': [
          'stru_bnrm_layout_bnrm',
          'stru_bnrm_layout_bnrmbnbt',
          'stru_bnrm_layout_bnrmbnbt1bed'
        ]
      },
      garageBlacklist: [],
      selectionsBlacklist: {
        0: [],
        1: [
          'stru-bnrm-layout',
          'stru-sunroom',
          'fire_fireplace',
          'msbt-msbtlayout',
          'stru-gtrm-layout',
          'stru-gtrm-window',
          'cabs-island-level',
          'stru-4ftreargtrm',
          'stru-2ftsidegargopp'
        ],
        2: [],
        3: []
      },
      elevationBlacklist: [],
      customRules: [
        {
          altHref:
            'fdtn_buf8 or fdtn_buf9 and not fdtn_bsmtfin_full and not fdtn_bsmtfin_part',
          floor: 1,
          ruleType: 'multi',
          groupToReplace: 'multiOr',
          multiGroup: {
            multiOr: ['fdtn_buf8', 'fdtn_buf9'],
            multiAndNot: ['fdtn_bsmtfin_full', 'fdtn_bsmtfin_part']
          },
          layerPriority: 0
        },
        {
          altHref:
            'fdtn_bup8 or fdtn_bup9 and not fdtn_bsmtfin_full and not fdtn_bsmtfin_part',
          floor: 1,
          ruleType: 'multi',
          groupToReplace: 'multiOr',
          multiGroup: {
            multiOr: ['fdtn_bup8', 'fdtn_bup9'],
            multiAndNot: ['fdtn_bsmtfin_full', 'fdtn_bsmtfin_part']
          },
          layerPriority: 0
        },
        {
          altHref:
            'fdtn_buf8 or fdtn_buf9 or fdtn_bup8 or fdtn_bup9 and fdtn_bsmtfin_full',
          floor: 1,
          ruleType: 'multi',
          groupToReplace: 'multiOr',
          multiGroup: {
            multiOr: ['fdtn_buf8', 'fdtn_buf9', 'fdtn_bup8', 'fdtn_bup9'],
            multiAnd: ['fdtn_bsmtfin_full']
          },
          layerPriority: 0
        },
        {
          altHref:
            'fdtn_buf8 or fdtn_buf9 or fdtn_bup8 or fdtn_bup9 and fdtn_bsmtfin_part',
          floor: 1,
          ruleType: 'multi',
          groupToReplace: 'multiOr',
          multiGroup: {
            multiOr: ['fdtn_buf8', 'fdtn_buf9', 'fdtn_bup8', 'fdtn_bup9'],
            multiAnd: ['fdtn_bsmtfin_part']
          },
          layerPriority: 0
        },
        {
          altHref:
            'stru_bnrm_layout_bnrm or stru_bnrm_layout_bnrmbnbt or stru_bnrm_layout_bnrmbnbt1bed and plmb-watersoftrough',
          floor: 2,
          ruleType: 'multi',
          groupToReplace: 'multiOr',
          multiGroup: {
            multiOr: ['stru_bnrm_layout_bnrm', 'stru_bnrm_layout_bnrmbnbt', 'stru_bnrm_layout_bnrmbnbt1bed'],
            multiAnd: ['plmb-watersoftrough']
          },
          layerPriority: 3
        },
        {
          altHref: 'bath_jjbt and not stru-2ftsidegargopp',
          floor: 2,
          ruleType: 'multi',
          groupToReplace: 'multiAndNot',
          multiGroup: { multiAndNot: ['bath_jjbt', 'stru-2ftsidegargopp'] },
          layerPriority: 2
        },
        {
          altHref: 'bath_jjbt and stru-2ftsidegargopp',
          floor: 2,
          ruleType: 'multi',
          groupToReplace: 'multiAnd',
          multiGroup: { multiAnd: ['bath_jjbt', 'stru-2ftsidegargopp'] },
          layerPriority: 2
        },
        {
          altHref: 'elev_craftsman and not stru-2ftsidegargopp',
          floor: 2,
          ruleType: 'multi',
          groupToReplace: 'multiAndNot',
          multiGroup: {
            multiAndNot: ['elev_craftsman', 'stru-2ftsidegargopp']
          },
          layerPriority: 2
        },
        {
          altHref: 'elev_craftsman and stru-2ftsidegargopp',
          floor: 2,
          ruleType: 'multi',
          groupToReplace: 'multiAnd',
          multiGroup: { multiAnd: ['elev_craftsman', 'stru-2ftsidegargopp'] },
          layerPriority: 2
        },
        {
          altHref: 'fdtn-foundation and not stru-bnrm-layout',
          floor: 2,
          ruleType: 'multiCategory',
          groupToReplace: 'multiAndNot',
          multiGroup: {
            multiAndNot: ['fdtn-foundation', 'stru-bnrm-layout']
          },
          layerPriority: 2
        },
        {
          altHref: 'fdtn-foundation and stru-bnrm-layout',
          floor: 2,
          ruleType: 'multiCategory',
          groupToReplace: 'multiAnd',
          multiGroup: { multiAnd: ['fdtn-foundation', 'stru-bnrm-layout'] },
          layerPriority: 2
        },
        {
          altHref: 'stru-bnrm-layout and not fdtn-foundation',
          floor: 2,
          ruleType: 'multiCategory',
          groupToReplace: 'multiAndNot',
          multiGroup: {
            multiAndNot: ['stru-bnrm-layout', 'fdtn-foundation']
          },
          layerPriority: 2
        },
        {
          altHref: 'stru-gtrm-window-bay and not stru-2ftsidegargopp',
          floor: 2,
          ruleType: 'multi',
          groupToReplace: 'multiAndNot',
          multiGroup: {
            multiAndNot: ['stru-gtrm-window-bay', 'stru-2ftsidegargopp']
          },
          layerPriority: 2
        },
        {
          altHref: 'stru-gtrm-window-bay and stru-2ftsidegargopp',
          floor: 2,
          ruleType: 'multi',
          groupToReplace: 'multiAnd',
          multiGroup: {
            multiAnd: ['stru-gtrm-window-bay', 'stru-2ftsidegargopp']
          },
          layerPriority: 2
        },
        {
          altHref: 'stru-sunroom and not stru-2ftsidegargopp',
          floor: 2,
          ruleType: 'multi',
          groupToReplace: 'multiAndNot',
          multiGroup: {
            multiAndNot: ['stru-sunroom', 'stru-2ftsidegargopp']
          },
          layerPriority: 2
        },
        {
          altHref: 'stru-sunroom and stru-2ftsidegargopp',
          floor: 2,
          ruleType: 'multi',
          groupToReplace: 'multiAnd',
          multiGroup: { multiAnd: ['stru-sunroom', 'stru-2ftsidegargopp'] },
          layerPriority: 2
        },
        {
          altHref: 'stru_gtrm_layout_bedroom and not stru-2ftsidegargopp',
          floor: 2,
          ruleType: 'multi',
          groupToReplace: 'multiAndNot',
          multiGroup: {
            multiAndNot: ['stru_gtrm_layout_bedroom', 'stru-2ftsidegargopp']
          },
          layerPriority: 2
        },
        {
          altHref: 'stru_gtrm_layout_bedroom and stru-2ftsidegargopp',
          floor: 2,
          ruleType: 'multi',
          groupToReplace: 'multiAnd',
          multiGroup: {
            multiAnd: ['stru_gtrm_layout_bedroom', 'stru-2ftsidegargopp']
          },
          layerPriority: 2
        },
        {
          altHref: 'stru_gtrm_layout_den and not stru-2ftsidegargopp',
          floor: 2,
          ruleType: 'multi',
          groupToReplace: 'multiAndNot',
          multiGroup: {
            multiAndNot: ['stru_gtrm_layout_den', 'stru-2ftsidegargopp']
          },
          layerPriority: 2
        },
        {
          altHref: 'stru_gtrm_layout_den and stru-2ftsidegargopp',
          floor: 2,
          ruleType: 'multi',
          groupToReplace: 'multiAnd',
          multiGroup: {
            multiAnd: ['stru_gtrm_layout_den', 'stru-2ftsidegargopp']
          },
          layerPriority: 2
        },
        {
          altHref: 'stru_gtrm_layout_dine and not stru-2ftsidegargopp',
          floor: 2,
          ruleType: 'multi',
          groupToReplace: 'multiAndNot',
          multiGroup: {
            multiAndNot: ['stru_gtrm_layout_dine', 'stru-2ftsidegargopp']
          },
          layerPriority: 2
        },
        {
          altHref: 'stru_gtrm_layout_dine and stru-2ftsidegargopp',
          floor: 2,
          ruleType: 'multi',
          groupToReplace: 'multiAnd',
          multiGroup: {
            multiAnd: ['stru_gtrm_layout_dine', 'stru-2ftsidegargopp']
          },
          layerPriority: 2
        }
      ]
    },
    // Chestnut - kova test
    944: {
      floorOnAppLoad: 1,
      floorsBlacklist: [],
      categoryBlacklist: {
        0: ['REVERSEPLAN'],
        1: ['REVERSEPLAN'],
        2: ['REVERSEPLAN'],
        3: ['REVERSEPLAN']
      },
      optionsWhitelist: {
        0: [],
        1: [
          'fdtn_buf8',
          'fdtn_buf9',
          'fdtn_bup8',
          'fdtn_bup9',
          'fdtn-bsbd',
          'fdtn-bsmtfinishedfullbath',
          'fdtn_bsmtfin_full',
          'fdtn_bsmtfin_part',
          'fdtn-foundation'
        ],
        2: [
          'bath_jjbt',
          'stru-2ftsidegargopp',
          'elev_craftsman',
          'fdtn-foundation',
          'stru-bnrm-layout',
          'stru-gtrm-window-bay',
          'stru-sunroom',
          'stru_gtrm_layout_bedroom',
          'stru_gtrm_layout_den',
          'stru_gtrm_layout_dine',
          'bath-mstrdelmastbath',
          'bath-mstrshower',
          'bath-mstrtileshower',
          'elev_b',
          'elev_c',
          'elev_traditional',
          'elev_tudor',
          'gar_2extgargside',
          'gar_4extgargside',
          'gar_6extgargside',
          'fdtn_buf8',
          'fdtn_buf9',
          'fdtn_bup8',
          'fdtn_bup9',
          'stru_bnrm_layout_bnrm',
          'stru_bnrm_layout_bnrmbnbt',
          'stru_bnrm_layout_bnrmbnbt1bed',
          'plmb-watersoftrough'
        ],
        3: [
          'stru_bnrm_layout_bnrm',
          'stru_bnrm_layout_bnrmbnbt',
          'stru_bnrm_layout_bnrmbnbt1bed'
        ]
      },
      categoriesToConvert: {
        'fdtn-foundation': [
          'fdtn_buf8',
          'fdtn_buf9',
          'fdtn_bup8',
          'fdtn_bup9'
        ],
        'stru-bnrm-layout': [
          'stru_bnrm_layout_bnrm',
          'stru_bnrm_layout_bnrmbnbt',
          'stru_bnrm_layout_bnrmbnbt1bed'
        ]
      },
      garageBlacklist: [],
      selectionsBlacklist: {
        0: [],
        1: [
          'stru-bnrm-layout',
          'stru-sunroom',
          'fire_fireplace',
          'msbt-msbtlayout',
          'stru-gtrm-layout',
          'stru-gtrm-window',
          'cabs-island-level',
          'stru-4ftreargtrm',
          'stru-2ftsidegargopp'
        ],
        2: [],
        3: []
      },
      elevationBlacklist: [],
      customRules: [
        {
          altHref:
            'fdtn_buf8 or fdtn_buf9 and not fdtn_bsmtfin_full and not fdtn_bsmtfin_part',
          floor: 1,
          ruleType: 'multi',
          groupToReplace: 'multiOr',
          multiGroup: {
            multiOr: ['fdtn_buf8', 'fdtn_buf9'],
            multiAndNot: ['fdtn_bsmtfin_full', 'fdtn_bsmtfin_part']
          },
          layerPriority: 0
        },
        {
          altHref:
            'fdtn_bup8 or fdtn_bup9 and not fdtn_bsmtfin_full and not fdtn_bsmtfin_part',
          floor: 1,
          ruleType: 'multi',
          groupToReplace: 'multiOr',
          multiGroup: {
            multiOr: ['fdtn_bup8', 'fdtn_bup9'],
            multiAndNot: ['fdtn_bsmtfin_full', 'fdtn_bsmtfin_part']
          },
          layerPriority: 0
        },
        {
          altHref:
            'fdtn_buf8 or fdtn_buf9 or fdtn_bup8 or fdtn_bup9 and fdtn_bsmtfin_full',
          floor: 1,
          ruleType: 'multi',
          groupToReplace: 'multiOr',
          multiGroup: {
            multiOr: ['fdtn_buf8', 'fdtn_buf9', 'fdtn_bup8', 'fdtn_bup9'],
            multiAnd: ['fdtn_bsmtfin_full']
          },
          layerPriority: 0
        },
        {
          altHref:
            'fdtn_buf8 or fdtn_buf9 or fdtn_bup8 or fdtn_bup9 and fdtn_bsmtfin_part',
          floor: 1,
          ruleType: 'multi',
          groupToReplace: 'multiOr',
          multiGroup: {
            multiOr: ['fdtn_buf8', 'fdtn_buf9', 'fdtn_bup8', 'fdtn_bup9'],
            multiAnd: ['fdtn_bsmtfin_part']
          },
          layerPriority: 0
        },
        {
          altHref:
            'stru_bnrm_layout_bnrm or stru_bnrm_layout_bnrmbnbt or stru_bnrm_layout_bnrmbnbt1bed and plmb-watersoftrough',
          floor: 2,
          ruleType: 'multi',
          groupToReplace: 'multiOr',
          multiGroup: {
            multiOr: ['stru_bnrm_layout_bnrm', 'stru_bnrm_layout_bnrmbnbt', 'stru_bnrm_layout_bnrmbnbt1bed'],
            multiAnd: ['plmb-watersoftrough']
          },
          layerPriority: 3
        },
        {
          altHref: 'bath_jjbt and not stru-2ftsidegargopp',
          floor: 2,
          ruleType: 'multi',
          groupToReplace: 'multiAndNot',
          multiGroup: { multiAndNot: ['bath_jjbt', 'stru-2ftsidegargopp'] },
          layerPriority: 2
        },
        {
          altHref: 'bath_jjbt and stru-2ftsidegargopp',
          floor: 2,
          ruleType: 'multi',
          groupToReplace: 'multiAnd',
          multiGroup: { multiAnd: ['bath_jjbt', 'stru-2ftsidegargopp'] },
          layerPriority: 2
        },
        {
          altHref: 'elev_craftsman and not stru-2ftsidegargopp',
          floor: 2,
          ruleType: 'multi',
          groupToReplace: 'multiAndNot',
          multiGroup: {
            multiAndNot: ['elev_craftsman', 'stru-2ftsidegargopp']
          },
          layerPriority: 2
        },
        {
          altHref: 'elev_craftsman and stru-2ftsidegargopp',
          floor: 2,
          ruleType: 'multi',
          groupToReplace: 'multiAnd',
          multiGroup: { multiAnd: ['elev_craftsman', 'stru-2ftsidegargopp'] },
          layerPriority: 2
        },
        {
          altHref: 'fdtn-foundation and not stru-bnrm-layout',
          floor: 2,
          ruleType: 'multiCategory',
          groupToReplace: 'multiAndNot',
          multiGroup: {
            multiAndNot: ['fdtn-foundation', 'stru-bnrm-layout']
          },
          layerPriority: 2
        },
        {
          altHref: 'fdtn-foundation and stru-bnrm-layout',
          floor: 2,
          ruleType: 'multiCategory',
          groupToReplace: 'multiAnd',
          multiGroup: { multiAnd: ['fdtn-foundation', 'stru-bnrm-layout'] },
          layerPriority: 2
        },
        {
          altHref: 'stru-bnrm-layout and not fdtn-foundation',
          floor: 2,
          ruleType: 'multiCategory',
          groupToReplace: 'multiAndNot',
          multiGroup: {
            multiAndNot: ['stru-bnrm-layout', 'fdtn-foundation']
          },
          layerPriority: 2
        },
        {
          altHref: 'stru-gtrm-window-bay and not stru-2ftsidegargopp',
          floor: 2,
          ruleType: 'multi',
          groupToReplace: 'multiAndNot',
          multiGroup: {
            multiAndNot: ['stru-gtrm-window-bay', 'stru-2ftsidegargopp']
          },
          layerPriority: 2
        },
        {
          altHref: 'stru-gtrm-window-bay and stru-2ftsidegargopp',
          floor: 2,
          ruleType: 'multi',
          groupToReplace: 'multiAnd',
          multiGroup: {
            multiAnd: ['stru-gtrm-window-bay', 'stru-2ftsidegargopp']
          },
          layerPriority: 2
        },
        {
          altHref: 'stru-sunroom and not stru-2ftsidegargopp',
          floor: 2,
          ruleType: 'multi',
          groupToReplace: 'multiAndNot',
          multiGroup: {
            multiAndNot: ['stru-sunroom', 'stru-2ftsidegargopp']
          },
          layerPriority: 2
        },
        {
          altHref: 'stru-sunroom and stru-2ftsidegargopp',
          floor: 2,
          ruleType: 'multi',
          groupToReplace: 'multiAnd',
          multiGroup: { multiAnd: ['stru-sunroom', 'stru-2ftsidegargopp'] },
          layerPriority: 2
        },
        {
          altHref: 'stru_gtrm_layout_bedroom and not stru-2ftsidegargopp',
          floor: 2,
          ruleType: 'multi',
          groupToReplace: 'multiAndNot',
          multiGroup: {
            multiAndNot: ['stru_gtrm_layout_bedroom', 'stru-2ftsidegargopp']
          },
          layerPriority: 2
        },
        {
          altHref: 'stru_gtrm_layout_bedroom and stru-2ftsidegargopp',
          floor: 2,
          ruleType: 'multi',
          groupToReplace: 'multiAnd',
          multiGroup: {
            multiAnd: ['stru_gtrm_layout_bedroom', 'stru-2ftsidegargopp']
          },
          layerPriority: 2
        },
        {
          altHref: 'stru_gtrm_layout_den and not stru-2ftsidegargopp',
          floor: 2,
          ruleType: 'multi',
          groupToReplace: 'multiAndNot',
          multiGroup: {
            multiAndNot: ['stru_gtrm_layout_den', 'stru-2ftsidegargopp']
          },
          layerPriority: 2
        },
        {
          altHref: 'stru_gtrm_layout_den and stru-2ftsidegargopp',
          floor: 2,
          ruleType: 'multi',
          groupToReplace: 'multiAnd',
          multiGroup: {
            multiAnd: ['stru_gtrm_layout_den', 'stru-2ftsidegargopp']
          },
          layerPriority: 2
        },
        {
          altHref: 'stru_gtrm_layout_dine and not stru-2ftsidegargopp',
          floor: 2,
          ruleType: 'multi',
          groupToReplace: 'multiAndNot',
          multiGroup: {
            multiAndNot: ['stru_gtrm_layout_dine', 'stru-2ftsidegargopp']
          },
          layerPriority: 2
        },
        {
          altHref: 'stru_gtrm_layout_dine and stru-2ftsidegargopp',
          floor: 2,
          ruleType: 'multi',
          groupToReplace: 'multiAnd',
          multiGroup: {
            multiAnd: ['stru_gtrm_layout_dine', 'stru-2ftsidegargopp']
          },
          layerPriority: 2
        }
      ]
    },
  },

  getRules(clientName, communityRID, modelRID) {
    return clientName === 'oakwood'
      ? this[clientName][communityRID][modelRID]
      : this[clientName][modelRID];
  }
};

export default modelRuleConfig;
