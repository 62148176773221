import React from 'react';
import PropTypes from 'prop-types';
import styles from './RightMenuTabs.module.css';
import MenuTab from './MenuTab/MenuTab';

const RightMenuTabs = ({
  clientFloorOnAppLoad,
  handleFloorToggle
}) => {
  const MENU_TABS = [
    {
      key: 1,
      type: 'interior',
      text: 'Structural Options'
    },
    {
      key: 2,
      type: 'exterior',
      text: 'Exterior Colors'
    }
  ];
  return (
    <div className={styles.rightMenuTabs}>
      {
        MENU_TABS.map(tab => (
          <MenuTab
            clientFloorOnAppLoad={clientFloorOnAppLoad}
            key={tab.key}
            type={tab.type}
            text={tab.text}
            handleFloorToggle={handleFloorToggle}
          />
        ))
      }
    </div>
  );
};

RightMenuTabs.defaultProps = {
  clientFloorOnAppLoad: null
};

RightMenuTabs.propTypes = {
  clientFloorOnAppLoad: PropTypes.number,
  handleFloorToggle: PropTypes.func.isRequired
};

export default RightMenuTabs;
