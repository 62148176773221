import { combineReducers } from 'redux';

import modelReducer from './model/model.reducer';
import communityReducer from './community/community.reducer';
import appReducer from './app/app.reducer';
import colorSelectorReducer from './colorSelector/colorSelector.reducer';

export default combineReducers({
  model: modelReducer,
  community: communityReducer,
  app: appReducer,
  colorSelector: colorSelectorReducer
});
