import Api from '../Api/Api';

export default class SaveWorksheet {
  static saveNewWorksheet = async (
    communityRID,
    data,
    modelRID,
    priceList,
    selectedOptions
  ) => {
    const {
      basePrice,
      customerRID,
      priceOptions
    } = data;

    const refWorksheet = await Api.fetchReferenceWorksheet(modelRID);
    await this.saveCustomerWorksheet(
      basePrice,
      customerRID,
      communityRID,
      modelRID,
      priceOptions,
      refWorksheet
    );
    const worksheet = await this.getCustomerWorksheet(customerRID, modelRID);

    const refWorksheetOpts = await Api.fetchRefWorksheetOpts(
      refWorksheet.SlsWshRID
    );

    const optVals = await this.getOptVals(refWorksheetOpts, selectedOptions);
    const modelOptVals = await this.getModelOptVals(modelRID, optVals);
    const multichoiceOptData = this.generateMutichoiceOptData(
      optVals,
      modelOptVals,
      priceList,
      refWorksheetOpts,
      selectedOptions,
      worksheet
    );
    const singleChoiceOptData = this.generateSinglechoiceOptData(
      refWorksheetOpts,
      selectedOptions,
      worksheet
    );
    const allOpts = [...multichoiceOptData, ...singleChoiceOptData];
    console.dir(allOpts);

    // eslint-disable-next-line no-restricted-syntax
    for (const opt of allOpts) {
      // eslint-disable-next-line no-await-in-loop
      await Api.saveCustomerWorksheetOpt(opt);
    }

    console.log('saved');
    return 'saved';
  }

  static saveCustomerWorksheet = async (
    basePrice,
    customerRID,
    communityRID,
    modelRID,
    priceOptions,
    refWorksheet
  ) => {
    const customerContacts = await Api.fetchCustomerContacts(customerRID);
    const customerContact = customerContacts.find(contact =>
      contact.CommunityRID === communityRID);
    const priceTotal = basePrice + priceOptions;
    const customerWorksheet = {
      CustContactRID: customerContact.CustContactRID,
      LotRID: refWorksheet.LotRID,
      CommunityRID: communityRID,
      ModelRID: modelRID,
      SpecLevelRID: refWorksheet.SpecLevelRID,
      CustomerRID: customerRID,
      Type: 'Sales Office',
      PriceTotal: priceTotal,
      PriceBaseModel: basePrice,
      PriceBaseActual: basePrice,
      PriceOptionsSO: priceOptions
    };
    await Api.saveCustomerWorksheet(customerWorksheet);

    return 'saved';
  }

  static getCustomerWorksheet = async (customerRID, modelRID) => {
    let customerWorksheets = await Api.fetchCustomerWorksheets(customerRID);
    customerWorksheets = customerWorksheets
      .filter(worksheet => worksheet.ModelRID === modelRID)
      .sort((a, b) => new Date(b.CreationDate) - new Date(a.CreationDate));
    const worksheet = customerWorksheets[0];

    return worksheet;
  }

  static getOptVals = async (refWorksheetOpts, selectedOptions) => {
    const promises = [];
    refWorksheetOpts.forEach((opt) => {
      if (this.isMultiChoice(opt)) {
        const matchingOpt = Object.keys(selectedOptions)
          .find(id => id === opt.OptSelID);
        if (matchingOpt) {
          const optValId = this.cleanOptVal(selectedOptions[matchingOpt]);
          promises.push(Api.fetchOptVal(optValId));
        }
      }
    });
    const optVals = await Promise.all(promises);

    return optVals;
  }

  static isMultiChoice = (option) => {
    const id = option.OptSelID;
    return id === id.toUpperCase();
  }

  static cleanOptVal = (optVal) => {
    if (optVal.slice(-2) === '||') {
      return optVal.slice(0, optVal.length - 2);
    }

    return optVal;
  }

  static getModelOptVals = async (modelRID, optVals) => {
    const promises = optVals.map(optVal =>
      Api.fetchModelOptVal(modelRID, optVal.OptValRID));
    const modelOptVals = await Promise.all(promises);

    return modelOptVals;
  }

  static generateMutichoiceOptData = (
    optVals,
    modelOptVals,
    priceList,
    refWorksheetOpts,
    selectedOptions,
    worksheet
  ) => {
    const refMultichocieOpts = refWorksheetOpts
      .filter(refOpt => this.isMultiChoice(refOpt));
    const multichoiceOpts = refMultichocieOpts.map((refOpt) => {
      let data = this.generateDefaultOptData(refOpt, worksheet);
      const selectedOpt = Object.keys(selectedOptions)
        .find(id => id === refOpt.OptSelID);

      if (selectedOpt) {
        const selectedOptValId = this.cleanOptVal(selectedOptions[selectedOpt]);

        // Selected option does not match refOpt default - fields need updated
        if (refOpt.OptValID !== selectedOptValId) {
          const optValRID = optVals
            .find(optVal => optVal.OptValID === selectedOptValId)
            .OptValRID;
          const modelOptValRID = modelOptVals
            .find(modelOptVal =>
              modelOptVal.OptValOptValID === selectedOptValId)
            .ModelOptValRID;
          const optPrice = priceList[selectedOpt]
            .find(choice =>
              this.cleanOptVal(choice.optionID) === selectedOptValId)
            .price;
          const updatedFields = {
            OptValRID: optValRID,
            ModelOptValRID: modelOptValRID,
            OptValID: selectedOptValId,
            SelectionCompl: true,
            UnitPrice: optPrice,
            CostPrice: optPrice,
            SellingPrice: optPrice
          };
          data = Object.assign(data, updatedFields);
        }
      }

      return data;
    });

    return multichoiceOpts;
  }

  static generateSinglechoiceOptData = (
    refWorksheetOpts,
    selectedOptions,
    worksheet
  ) => {
    const refSinglechocieOpts = refWorksheetOpts
      .filter(refOpt => !this.isMultiChoice(refOpt));
    const singlechoiceOpts = refSinglechocieOpts.map((refOpt) => {
      let data = this.generateDefaultOptData(refOpt, worksheet);
      const selectedOpt = Object.keys(selectedOptions)
        .find(id => id === refOpt.OptSelID);

      // Handle refWorksheet default conflicts
      if (refOpt.Selected && !selectedOpt) {
        // console.log('default conflict:', refOpt.OptSelID);
        const updatedFields = {
          IncludedInPricing: false,
          Selected: false,
          SelectionCompl: false,
          OptValID: ''
        };
        data = Object.assign(data, updatedFields);
      }

      // Handle selected opts
      if (!refOpt.Selected && selectedOpt) {
        // console.log('opt selected:', selectedOpt);
        const updatedFields = {
          IncludedInPricing: true,
          Selected: true,
          SelectionCompl: true,
          OptValID: selectedOpt
        };
        data = Object.assign(data, updatedFields);
      }

      return data;
    });

    return singlechoiceOpts;
  }

  static generateDefaultOptData = (refOpt, worksheet) => ({
    SlsWshRID: worksheet.SlsWshRID,
    OptSelRID: refOpt.OptSelRID,
    ModelOptSelRID: refOpt.ModelOptSelRID,
    OptSelID: refOpt.OptSelID,
    OptSelName: refOpt.OptSelName,
    OptSelDescr: refOpt.OptSelDescr,
    OptSelBaseRID: refOpt.OptSelBaseRID,
    BaseStyle: refOpt.BaseStyle,
    BaseColor: refOpt.BaseColor,
    ModelOptBaseRID: refOpt.ModelOptBaseRID,
    MasterOptSelRID: refOpt.MasterOptSelRID,
    Selected: refOpt.Selected,
    IncludedInPricing: refOpt.IncludedInPricing,
    OptValRID: refOpt.OptValRID,
    Style: refOpt.Style,
    Color: refOpt.Color,
    ModelOptValRID: refOpt.ModelOptValRID,
    OptValID: refOpt.OptValID,
    // "OptValName": "Building Type #2 - Elevation A",
    // "OptValDescr": "Building Type #2 - Elevation A",
    SelectionMode: refOpt.SelectionMode,
    SelectionCompl: refOpt.SelectionCompl,
    CostCategoryRID: refOpt.CostCategoryRID,
    UnitPrice: refOpt.UnitPrice,
    BaseUnitPrice: refOpt.BaseUnitPrice,
    BaseQty: refOpt.BaseQty,
    DefaultQty: refOpt.DefaultQty,
    CurrentQty: refOpt.CurrentQty,
    ManualQtyChange: refOpt.ManualQtyChange,
    BasePrice: refOpt.BasePrice,
    CostPrice: refOpt.CostPrice,
    SellingPrice: refOpt.SellingPrice,
    Name: refOpt.Name,
  });
}
