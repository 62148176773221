import React from 'react';
import PropTypes from 'prop-types';
import styles from './LeftSideMenu.module.css';

const LeftSideMenu = ({
  children
}) => (
  <div className={styles.leftSideMenu}>
    {children}
  </div>
);

LeftSideMenu.propTypes = {
  children: PropTypes.array.isRequired
};

export default LeftSideMenu;
