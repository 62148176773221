import React from 'react';
import PropTypes from 'prop-types';
import whiteArrow from '../../img/whiteArrow.svg';
import styles from './SelectionGroupHeader.module.css';

const SelectionGroupHeader = ({
  menuHeaderText,
  touchscreenEnabled,
  clickHandler,
  menuOpen
}) => (
  <div
    className={
      touchscreenEnabled
        ? styles.touchMenuHeader
        : styles.webMenuHeader
    }
    onClick={touchscreenEnabled ? undefined : clickHandler}
    role="button"
    tabIndex="0"
  >
    {menuHeaderText}
    {!touchscreenEnabled
      && (
        <img
          className={menuOpen ? styles.openArrow : styles.closedArrow}
          src={whiteArrow}
          alt="menu arrow"
        />
      )
    }
  </div>
);

SelectionGroupHeader.propTypes = {
  menuHeaderText: PropTypes.string.isRequired,
  choiceType: PropTypes.string.isRequired,
  touchscreenEnabled: PropTypes.bool.isRequired,
  clickHandler: PropTypes.func.isRequired,
  menuOpen: PropTypes.bool.isRequired
};

export default SelectionGroupHeader;
