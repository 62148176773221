import React from 'react';
import PropTypes from 'prop-types';
import { ThemeConsumer } from '../../context/ThemeContext';
import styles from './SectionHeader.module.css';

const SectionHeader = ({
  title
}) => (
  <div className={styles.sectionHeader}>
    <ThemeConsumer>
      {theme => (
        <>
          <div className={styles.title}>
            {title}
          </div>
          <hr
            style={{ borderTop: `1px solid ${theme.accentColor}` }}
          />
        </>
      )}
    </ThemeConsumer>
  </div>
);

SectionHeader.propTypes = {
  title: PropTypes.string.isRequired
};

export default SectionHeader;
