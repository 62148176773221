const foundationConfig = [
  {
    type: 'BS',
    sequenceID: 'foundation-option',
    text: {
      main: 'Brick',
      detail: '( Base )'
    }
  },
  {
    type: 'SS',
    sequenceID: 'foundation-option',
    text: {
      main: 'Stone',
      detail: '( Base )'
    }
  }  
];

export default foundationConfig;
