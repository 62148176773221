/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './KovaFloorplanGraphic.css';
import {
  calculateImageName,
  calcZIndex,
  calcLeftOffset,
  calcBottomOffset,
  determineLabelVis,
  createLabelList,
  removeOverlappedBaseLabels,
  handleOptionLabelOverlaps
} from '../../../utils/FloorplanBuilder/FloorplanBuilder';
import GlobalConfig from '../../../utils/GlobalConfig';

class KovaFloorplanGraphic extends Component {
  state = {
    globalLeftOffset: 0, // Globals
    globalBottomOffset: 0,
    kovaScaleResult: null,
    // imgsProcessed: false,
    // labelsProcessed: false,
    // baseImageWidth: null,
    // kovaScaleSet: false,
  };

  async componentDidMount() {
    const { incrementGraphicsReady, isElevation } = this.props;

    // Elevation img is set to full width so scale is not needed
    if (!isElevation) this.setKovaScale();

    setTimeout(() => {
      incrementGraphicsReady();
    }, 500);
  }

  setKovaScale = async () => {
    const { imgData } = this.props;
    const maxHeight = 800;

    const baseImgElement = document.getElementById(`_${imgData.baseImage.ObjectRID}`);
    const kovaScaleResult = Math.round(((maxHeight / baseImgElement.offsetHeight) + Number.EPSILON) * 100) / 100;
    const baseImageWidth = baseImgElement.offsetWidth * kovaScaleResult;
    // console.log(imgData.baseImage.ObjectRID, baseImageWidth);
    // console.dir(baseImgElement);

    this.setState({
      kovaScaleResult,
      globalLeftOffset: (1000 - baseImageWidth) / 2,
      // baseImageWidth,
      // kovaScaleSet: true,
    });
  }


  reduceImgSize = (e) => {
    const { kovaScaleResult } = this.state;
    const { isElevation } = this.props;


    // Apply the calculated scale to every image
    if (!isElevation) {
      e.target.style.height = `${e.target.height * kovaScaleResult}px`;
    } else {
      e.target.style.width = '100%';
    }
    e.target.style.visibility = 'visible';
  }

  generateAllImgs = () => {
    const {
      communityRID, modelRID, filteredOptImgs, selectedOpts, floorIndex
    } = this.props;

    const { globalBottomOffset, globalLeftOffset, kovaScaleResult } = this.state;

    const imgFolder = `https://s3-${GlobalConfig.get(GlobalConfig.Key.S3_REGION)}.amazonaws.com/${GlobalConfig.get(GlobalConfig.Key.S3_KOVA_IFP)}/OptCfgImgs/${modelRID}/OptCfgImgs/`;
    const images = filteredOptImgs[floorIndex]
      .filter(img => (img.Type === 'Option' || img.Type === 'Base'))
      .sort((a, b) => {
        if (a.Type === 'Base') return 1;
        if (b.Type === 'Base') return -1;

        return a.Width * a.Height - b.Width * b.Height;
      });

    const imgs = images.map((optImage) => {
      const srcImg = calculateImageName(
        optImage,
        imgFolder,
        selectedOpts
      );

      if (srcImg) {
        return (
          <img
            key={optImage.ObjectRID}
            id={optImage.ObjectRID}
            src={`${srcImg}?origin=http://dev.php.com`}
            style={{
              bottom: `${calcBottomOffset(optImage, globalBottomOffset, kovaScaleResult)}px`,
              left: `${calcLeftOffset(optImage, globalLeftOffset, kovaScaleResult)}px`,
              zIndex: srcImg.toLowerCase().includes('-base')
                ? -2147483646
                : calcZIndex(optImage) * -1,
              position: 'absolute',
              visibility: 'hidden'
            }}
            onLoad={e => this.reduceImgSize(e, kovaScaleResult)}
            alt=""
            crossOrigin="true"
          />
        );
      }
      return null;
    }).filter(Boolean);
    return imgs;
  }

  generateAllText = () => {
    const { selectedOpts, imgData } = this.props;
    const { kovaScaleResult } = this.state;

    const {
      baseLabels,
      options,
      optionLabels,
      parentOptionLookup,
      optionLabelOverlaps,
      optionLabelLookup
    } = imgData;

    const {
      globalBottomOffset,
      globalLeftOffset,
    } = this.state;

    let associatedOptLabelRIDs = [];

    const optionsToDisplay = createLabelList(options, selectedOpts);
    let optionLabelsToDisplay = createLabelList(optionLabels, selectedOpts);

    // Generate array of all option label RIDs to display based on selected options
    optionsToDisplay.forEach((option) => {
      associatedOptLabelRIDs = associatedOptLabelRIDs
        .concat(optionLabelLookup[option.ObjectRID].relatedOptLabels);
    });

    const baseOverlappedLabelsRemoved = removeOverlappedBaseLabels(
      baseLabels,
      associatedOptLabelRIDs,
      optionLabelOverlaps
    );

    const resolvedOptLabelRIDs = new Set(handleOptionLabelOverlaps(
      associatedOptLabelRIDs,
      optionLabelOverlaps,
      parentOptionLookup
    ));

    optionLabelsToDisplay = optionLabelsToDisplay.filter(
      label => resolvedOptLabelRIDs.has(label.ObjectRID)
    );

    const newAgg = baseOverlappedLabelsRemoved.concat(optionLabelsToDisplay);

    return (
      newAgg.map((optImage, index) => {
        const baseLength = newAgg[index].Description.length;
        let i = 0;
        const asciCheck = 10;
        const newDesc = newAgg[index].Description;
        let matchedVert = 0;
        let matchedHorizontal = 0;

        //  VAlign
        //  T = top - C = center - B = bottom

        //  HAlign
        //  L means left - R = right - C = center

        if (optImage.VAlign === 'T') {
          matchedVert = Number((optImage.Height) * kovaScaleResult);
        } else if (optImage.VAlign === 'C') {
          matchedVert = Number((optImage.Height / 2) * kovaScaleResult);
        } else if (optImage.VAlign === 'M') {
          matchedVert = Number((optImage.Height / 2) * kovaScaleResult);
        } else if (optImage.VAlign === 'B') {
          matchedVert = 0;
        }

        if (optImage.HAlign === 'L') {
          matchedHorizontal = 0;
        } else if (optImage.HAlign === 'C') {
          matchedHorizontal = Number((optImage.Width / 2) * kovaScaleResult);
        } else if (optImage.HAlign === 'R') {
          matchedHorizontal = Number((optImage.Width) * kovaScaleResult);
        }

        const replace = '\n';

        for (i = 0; i < baseLength; i += 1) {
          const asciiAtIndex = newAgg[index].Description.charCodeAt(i);
          if (asciiAtIndex === asciCheck) {
            newDesc.replace(/&#32/, replace);
          }
        }

        return (
          <p
            key={optImage.ObjectRID}
            id={optImage.ObjectRID}
            style={{
              bottom: `${calcBottomOffset(optImage, globalBottomOffset, kovaScaleResult) - matchedVert}px`,
              left: `${Number(calcLeftOffset(optImage, globalLeftOffset, kovaScaleResult)) - matchedHorizontal}px`,
              zIndex: (optImage.Type !== 'OptionLabel')
                ? calcZIndex(optImage)
                : calcZIndex(optImage) * -1,
              position: 'absolute',
              display: 'inline-block',
              height: `${optImage.Height * kovaScaleResult}px`,
              width: `${optImage.Width * kovaScaleResult}px`,
              visibility: `${determineLabelVis(optImage, selectedOpts)}`,
              fontSize: `${optImage.KY * kovaScaleResult}px`,
              whiteSpace: 'pre',
              textAlign: 'center',
              // outline: '2px solid red',
              transform: `rotate(-${optImage.Angle}deg)`,
              transformOrigin: 'top left'
            }}
            alt=""
          >
            {newDesc}
          </p>
        );
      }));
  }


  render() {
    const { selectedFloor } = this.props;
    return (
      <div className="KovaFloorplanGraphic" id={selectedFloor}>
        {this.generateAllText()}
        {this.generateAllImgs().filter(Boolean)}
      </div>
    );
  }
}

KovaFloorplanGraphic.propTypes = {
  selectedFloor: PropTypes.string.isRequired,
  selectedOpts: PropTypes.array.isRequired,
  filteredOptImgs: PropTypes.array.isRequired,
  communityRID: PropTypes.number.isRequired,
  modelRID: PropTypes.number.isRequired,
  imgData: PropTypes.object.isRequired
};

export default KovaFloorplanGraphic;
