import React from 'react';
import PropTypes from 'prop-types';
import { ClientSettingsConsumer } from '../../context/ClientSettingsContext';
import styles from './SideMenuButton.module.css';

const SideMenuButton = ({
  buttonText,
  clickHandler
}) => (
  <ClientSettingsConsumer>
    {({ touchscreenEnabled }) => (
      touchscreenEnabled
        ? null
        : (
          <div className={styles.sideMenuButton}>
            <span
              className={styles.menuButton}
              onClick={clickHandler}
              role="button"
              tabIndex="0"
            >
              {buttonText}
            </span>
          </div>
        )
    )}
    </ClientSettingsConsumer>
);

SideMenuButton.defaultProps = {
  buttonText: 'Placeholder',
  clickHandler: null
};

SideMenuButton.propTypes = {
  clickHandler: PropTypes.func,
  buttonText: PropTypes.string,
};

export default SideMenuButton;
