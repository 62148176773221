import React from 'react';
import PropTypes from 'prop-types';
import styles from './PanelList.module.css';

const PanelList = ({
  listPanelItems,
}) => (
  <div className={styles.panelList}>
    {listPanelItems()}
  </div>
);


PanelList.propTypes = {
  listPanelItems: PropTypes.string.isRequired,
};

export default PanelList;
