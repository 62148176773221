import React from 'react';
import PropTypes from 'prop-types';
import styles from './Button.module.css';

const Button = ({
  children,
  clickHandler,
  hasUrl,
  text
}) => (
  <button
    key={text}
    className={(hasUrl === false || hasUrl)
      ? styles.Button
      : styles.disabledButton
    }
    type="button"
    tabIndex="0"
    onClick={hasUrl === false || hasUrl ? clickHandler : null}
  >
    <div className={styles.buttonText}>
      {hasUrl === false || hasUrl ? text : 'tour not available'}
    </div>
    {React.Children.count(children) > 0 && React.Children.only(children)}
  </button>
);

Button.defaultProps = {
  children: [],
  clickHandler: null,
  hasUrl: false
};

Button.propTypes = {
  children: PropTypes.node,
  clickHandler: PropTypes.func,
  text: PropTypes.string.isRequired,
  hasUrl: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
  ]),
};

export default Button;
